import { lazy } from "react";

import type { ModalManagerComponent } from "./interface";

export enum ModalCommonTags {
    CONFIRMATION = "confirmation",
    ONBOARDING_CONFIGS = "onboarding/configs",
    ONBOARDING_STEPS = "onboarding/steps",
    ONBOARDING_TERMS_OF_USE = "onboarding/terms-of-use",
    OFFERS_CREATE = "offers/create",
    PICKUP_ADDRESS_INFO = "pickup-address-info",
    SKUS_GENERATE_DESCRIPTION = "skus/generate-description",
}

export const commonModals = {
    [ModalCommonTags.CONFIRMATION]: lazy(
        () => import("../../components/Modals/Confirmation"),
    ),
    [ModalCommonTags.ONBOARDING_CONFIGS]: lazy(
        () => import("../../components/Onboarding/Modals/Configs"),
    ),
    [ModalCommonTags.ONBOARDING_STEPS]: lazy(
        () => import("../../components/Onboarding/Modals/Steps"),
    ),
    [ModalCommonTags.ONBOARDING_TERMS_OF_USE]: lazy(
        () => import("../../components/Onboarding/Modals/TermsOfUse"),
    ),
    [ModalCommonTags.OFFERS_CREATE]: lazy(
        () => import("../../components/Modals/CreateOffer"),
    ),
    [ModalCommonTags.PICKUP_ADDRESS_INFO]: lazy(
        () => import("../../components/Modals/PickupAddressInfo"),
    ),
    [ModalCommonTags.SKUS_GENERATE_DESCRIPTION]: lazy(
        () => import("../../components/Modals/GenerateSkuDescription"),
    ),
} satisfies Record<string, ModalManagerComponent<any>>;
