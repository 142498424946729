"use client";

import type { Dispatch, SetStateAction } from "react";

import { forwardRef, useCallback, useEffect, useState } from "react";

import MonthLayout from "./MonthLayout";
import MonthSelector from "./MonthSelector";

import { formatDateLabel } from "./helpers";

import ButtonIcon from "../../ButtonIcon";
import { ChevronLeftIconInfo, ChevronRightIconInfo } from "../../Icons/Mocks";

import { parseDate } from "../../../../utils/date";

interface DatePickerProps {
    current?: string | number | Date;
    dateFrom?: string | number | Date;
    dateTo?: string | number | Date;
    isCompact?: boolean;
    isFuture?: boolean;
    isPast?: boolean;
    isRange?: boolean;
    locale?: string;
    maxDate?: string | number | Date;
    minDate?: string | number | Date;
    onChange?: (newValues: Date[]) => void;
}

const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
    function DatePicker(
        {
            current,
            dateFrom,
            dateTo,
            maxDate,
            minDate,
            isCompact,
            isFuture,
            isPast,
            isRange,
            locale = "pt-br",
            onChange,
        },
        ref,
    ) {
        const [date1, setDate1] = useState(parseDate(dateFrom || current));
        const [date2, setDate2] = useState(parseDate(dateTo));
        const [maxLimit, setMaxLimit] = useState(parseDate(maxDate));
        const [minLimit, setMinLimit] = useState(parseDate(minDate));
        const [tempDate, setTempDate] = useState<Date>();

        const [pivot1, setPivot1] = useState(
            parseDate(dateFrom || maxDate || current) ?? new Date(),
        );
        const [pivot2, setPivot2] = useState(() => {
            const temp = parseDate(dateTo) ?? new Date(pivot1);
            if (temp.getMonth() === pivot1.getMonth()) {
                temp.setDate(1);
                temp.setMonth(temp.getMonth() + 1);
            }
            return temp;
        });

        const [selectMonth, setSelectMonth] = useState<
            "pivot1" | "pivot2" | null
        >(null);

        useEffect(() => {
            setDate1(parseDate(dateFrom || current));
        }, [dateFrom, current]);

        useEffect(() => {
            setDate2(parseDate(dateTo));
        }, [dateTo]);

        useEffect(() => {
            setMaxLimit(parseDate(maxDate));
        }, [maxDate]);

        useEffect(() => {
            setMinLimit(parseDate(minDate));
        }, [minDate]);

        const handleChangeDates = useCallback(
            (newValue: Date) => {
                if (!isRange) {
                    setDate1(newValue);
                    onChange?.([newValue]);
                    return;
                }

                if (!date1 && !date2) {
                    setDate1(newValue);
                    onChange?.([newValue]);
                } else if (date1 && !date2) {
                    const t1 = date1.getTime();
                    const t2 = newValue.getTime();
                    if (t2 >= t1) {
                        setDate2(newValue);
                        setTempDate(undefined);
                        onChange?.([date1, newValue]);
                    } else {
                        setDate1(newValue);
                        onChange?.([newValue]);
                    }
                } else if (!date1 && date2) {
                    const t1 = newValue.getTime();
                    const t2 = date2.getTime();
                    if (t2 >= t1) {
                        setDate1(newValue);
                        setTempDate(undefined);
                        onChange?.([newValue, date2]);
                    } else {
                        setDate1(newValue);
                        setDate2(undefined);
                        onChange?.([newValue]);
                    }
                } else if (date1 && date2) {
                    setDate1(newValue);
                    setDate2(undefined);
                    onChange?.([newValue]);
                }
            },
            [isRange, date1, date2, onChange],
        );

        const handleHoverStart = useCallback(
            (value: Date) => {
                if (isRange && date1 && !date2) {
                    setTempDate(value);
                }
            },
            [isRange, date1, date2],
        );

        const handleHoverEnd = () => {
            setTempDate(undefined);
        };

        const handleSelectPivot =
            (setState: Dispatch<SetStateAction<Date>>) =>
            (year: number, month: number) => {
                const newPivot = new Date(year, month);
                setState(newPivot);
                setSelectMonth(null);
            };

        const goToNextMonth =
            (setState: Dispatch<SetStateAction<Date>>) => () => {
                setState((prev) => {
                    const temp = new Date(prev);
                    temp.setDate(1);
                    temp.setMonth(temp.getMonth() + 1);
                    return temp;
                });
            };

        const goToPreviousMonth =
            (setState: Dispatch<SetStateAction<Date>>) => () => {
                setState((prev) => {
                    const temp = new Date(prev);
                    temp.setDate(1);
                    temp.setMonth(temp.getMonth() - 1);
                    return temp;
                });
            };

        if (selectMonth) {
            return (
                <MonthSelector
                    ref={ref}
                    current={selectMonth === "pivot1" ? pivot1 : pivot2}
                    locale={locale}
                    onCancel={() => setSelectMonth(null)}
                    onConfirm={handleSelectPivot(
                        selectMonth === "pivot1" ? setPivot1 : setPivot2,
                    )}
                />
            );
        }

        if (isRange && !isCompact) {
            return (
                <div ref={ref} className="bg-white w-max shadow-md p-8">
                    <div className="inline-block">
                        <div className="flex justify-between items-center mb-16">
                            <ButtonIcon
                                type="button"
                                label="previous-month"
                                icon={ChevronLeftIconInfo}
                                variant="ghost"
                                size="sm"
                                className="text-doladoBlue"
                                onClick={goToPreviousMonth(setPivot1)}
                            />
                            <p
                                className="cursor-pointer mb-0 rounded-4 px-2 py-1 hover:bg-black/10"
                                onClick={() => setSelectMonth("pivot1")}>
                                {formatDateLabel(locale, pivot1)}
                            </p>
                            <ButtonIcon
                                type="button"
                                label="next-month"
                                icon={ChevronRightIconInfo}
                                variant="ghost"
                                size="sm"
                                className="text-doladoBlue"
                                onClick={goToNextMonth(setPivot1)}
                            />
                        </div>
                        <MonthLayout
                            pivot={pivot1}
                            start={date1}
                            end={tempDate || date2}
                            maxDate={maxLimit}
                            minDate={minLimit}
                            isDraft={!!tempDate}
                            isFuture={isFuture}
                            isPast={isPast}
                            locale={locale}
                            onClick={handleChangeDates}
                            onMouseEnter={handleHoverStart}
                            onMouseLeave={handleHoverEnd}
                        />
                    </div>
                    <div className="inline-block ml-8">
                        <div className="flex justify-between items-center mb-16">
                            <ButtonIcon
                                type="button"
                                label="previous-month"
                                icon={ChevronLeftIconInfo}
                                variant="ghost"
                                size="sm"
                                className="text-doladoBlue"
                                onClick={goToPreviousMonth(setPivot2)}
                            />
                            <p
                                className="cursor-pointer mb-0 rounded-4 px-2 py-1 hover:bg-black/10"
                                onClick={() => setSelectMonth("pivot2")}>
                                {formatDateLabel(locale, pivot2)}
                            </p>
                            <ButtonIcon
                                type="button"
                                label="next-month"
                                icon={ChevronRightIconInfo}
                                variant="ghost"
                                size="sm"
                                className="text-doladoBlue"
                                onClick={goToNextMonth(setPivot2)}
                            />
                        </div>
                        <MonthLayout
                            pivot={pivot2}
                            start={date1}
                            end={tempDate || date2}
                            maxDate={maxLimit}
                            minDate={minLimit}
                            isDraft={!!tempDate}
                            isFuture={isFuture}
                            isPast={isPast}
                            locale={locale}
                            onClick={handleChangeDates}
                            onMouseEnter={handleHoverStart}
                            onMouseLeave={handleHoverEnd}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div ref={ref} className="bg-white w-max shadow-md p-8">
                <div className="flex justify-between items-center mb-16 pl-8">
                    <p
                        className="cursor-pointer mb-0 rounded-4 px-2 py-1 hover:bg-black/10"
                        onClick={() => setSelectMonth("pivot1")}>
                        {formatDateLabel(locale, pivot1)}
                    </p>
                    <div>
                        <ButtonIcon
                            type="button"
                            label="previous-month"
                            icon={ChevronLeftIconInfo}
                            variant="ghost"
                            size="sm"
                            className="inline-block text-doladoBlue"
                            onClick={goToPreviousMonth(setPivot1)}
                        />
                        <ButtonIcon
                            type="button"
                            label="next-month"
                            icon={ChevronRightIconInfo}
                            variant="ghost"
                            size="sm"
                            className="inline-block ml-8 text-doladoBlue"
                            onClick={goToNextMonth(setPivot1)}
                        />
                    </div>
                </div>
                <MonthLayout
                    pivot={pivot1}
                    start={date1}
                    end={tempDate || date2}
                    maxDate={maxLimit}
                    minDate={minLimit}
                    isDraft={!!tempDate}
                    isFuture={isFuture}
                    isPast={isPast}
                    locale={locale}
                    onClick={handleChangeDates}
                    onMouseEnter={handleHoverStart}
                    onMouseLeave={handleHoverEnd}
                />
            </div>
        );
    },
);

export default DatePicker;
