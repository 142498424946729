import type { FC } from "react";

import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import Button from "../../../components/@basic/Button";
import ButtonIcon from "../../../components/@basic/ButtonIcon";
import {
    CloseIconInfo,
    QuestionIconInfo,
} from "../../../components/@basic/Icons/Mocks";

const HelpButton: FC = () => {
    // const [isOpen, setIsOpen] = useState(false);

    // const blipClient = useRef<any>();

    // const initBlipChat = useCallback(() => {
    //     if (typeof BlipChat === "undefined") return;

    //     const customStyle = `
    //         #blip-send-message {
    //             display: block !important;
    //         }

    //         #message-input > div {
    //             display: flex;
    //             flex-direction: row;
    //             justify-content: space-between;
    //             align-items: center;
    //         }

    //         #msg-textarea {
    //             flex: 1;
    //         }

    //         #message-input .message-options {
    //             position: relative;
    //             flex-direction: row-reverse;
    //         }
    //     `;

    //     blipClient.current = new BlipChat();
    //     blipClient.current
    //         .withAppKey(
    //             "c3Vwb3J0ZWFvc2VsbGVyOmJjMzI4Y2Q2LWY3YTktNDZmNC05Yjk5LWJlNmVjYzU0NDg2Zg==",
    //         )
    //         .withTarget("blip-chat")
    //         .withCustomCommonUrl("https://compredolado.chat.blip.ai/")
    //         .withCustomStyle(customStyle)
    //         .build();
    // }, []);

    // useEffect(() => {
    //     if (isOpen && blipClient.current) {
    //         if (!blipClient.current.widget) blipClient.current.build();
    //     } else if (!isOpen && blipClient.current) {
    //         if (blipClient.current.widget) blipClient.current.destroy();
    //     }
    // }, [isOpen]);

    // useEffect(() => {
    //     if (!isOpen) return;

    //     if (document.getElementById("blip-chat-initializer")) return;

    //     const script = document.createElement("script");
    //     script.id = "blip-chat-initializer";
    //     script.src = "https://unpkg.com/blip-chat-widget";
    //     script.type = "text/javascript";
    //     script.onload = () => initBlipChat();

    //     document.body.appendChild(script);
    // }, [isOpen, initBlipChat]);

    return (
        <>
            <a
                href="https://web.whatsapp.com/send/?phone=11973079915&text=Preciso+de+assist%C3%AAncia%2C+pode+me+ajudar%3F"
                target="_blank"
                rel="external chat">
                <Button
                    size="unset"
                    leftIcon={QuestionIconInfo}
                    iconStyle="md:bg-white w-18 h-18 mr-0 md:mr-8 rounded-full p-3 text-white md:text-doladoBlue"
                    className="bg-royalBlue-500 w-24 md:w-max h-24 md:h-40 rounded-full md:rounded-l-24 md:rounded-tr-4 md:rounded-br-24 px-3 md:px-16 md:after:content-['Ajuda']"
                />
            </a>

            {/* {createPortal(
                <div
                    className={`bottom-0 md:bottom-56 right-0 md:right-24 z-overlay bg-white w-full md:w-360 h-screen md:h-600 rounded-8 overflow-hidden ${
                        isOpen
                            ? "fixed shadow-sm animate-slide-in-bt md:animate-grow origin-bottom-right"
                            : "hidden"
                    }`}>
                    {isOpen && (
                        <>
                            <ButtonIcon
                                variant="ghost"
                                icon={CloseIconInfo}
                                label="close-chat"
                                title="Fechar"
                                className="absolute top-8 right-8 w-28 h-28 p-4 text-black/20"
                                onClick={() => setIsOpen(false)}
                            />
                        </>
                    )}
                    <div id="blip-chat" className="h-full" />
                </div>,
                document.body,
            )} */}
        </>
    );
};

export default HelpButton;
