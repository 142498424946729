import type { AuditEvent } from "../../events/interface";

export enum AccountPaymentModels {
    SIMPLE = "Modelo Simples Nacional",
    REAL_PROFIT = "Modelo Real",
    PRESUMED_PROFIT = "Modelo Presumido",
}

export enum AccountPayloadStatus {
    APPROVED = "approved",
    PENDING = "pending",
    REJECTED = "rejected",
    CREATED = "created",
    CREATING = "creating",
    FAILED = "failed",
}

export enum AccountPayloadIntegration {
    ALEXMORO = "alexmoro",
    INTERNAL = "internal",
}

export enum AccountPayloadPaymentGateway {
    ASAAS = "asaas",
    HOTMART = "hotmart",
}

export enum AccountPayloadPaymentStatus {
    CREATED = "created",
    PAID = "paid",
    FAILED = "failed",
}

export interface AccountPayloadAddress {
    complement?: string;
    number: string;
    zipCode: string;
}

export interface AccountPayloadPayment {
    date: string;
    externalCustomerId: number;
    installments: number | null;
    paymentMethod?: string;
    qrCode?: {
        expirationDate?: string;
        payload?: string;
        url?: string;
    };
    status?: AccountPayloadPaymentStatus;
    value: number;
}

export interface AccountPayload {
    __v: number;
    _id: string;
    accountName: string;
    address?: AccountPayloadAddress;
    billingAddress?: AccountPayloadAddress;
    birthDate?: string;
    consultantId: string;
    consultantName: string;
    createdAt: string;
    document: string;
    gateway: AccountPayloadPaymentGateway;
    idPaymentModel?: number;
    integration: AccountPayloadIntegration;
    payment: AccountPayloadPayment;
    phone?: string;
    status: AccountPayloadStatus;
    tax: AccountPaymentModels;
    updatedAt: string;
    userEmail: string;
    userName: string;
    validateSefaz: boolean;
    validateSintegra?: boolean;
}

export type AccountPayloadAudit = Pick<
    AuditEvent,
    "_id" | "createdAt" | "event" | "eventId" | "status" | "updatedAt"
>;

export interface AccountPayloadWithAudits extends AccountPayload {
    audits: AccountPayloadAudit[];
}
