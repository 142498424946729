import { BaseSyntheticEvent } from "react";

export function chainEventHandlers<EventType extends BaseSyntheticEvent>(
    ...handlers: ((event: EventType) => EventType | void)[]
): (event: EventType) => void {
    const chained = (event: EventType) => {
        event.persist();
        let currentEvent = event;
        for (const handler of handlers) {
            const response = handler(currentEvent);
            if (response) currentEvent = response;
        }
    };
    return chained;
}

export function triggerChangeEvent(ref: HTMLElement, value?: string | number) {
    let prototype;
    if (ref instanceof HTMLInputElement) {
        prototype = HTMLInputElement.prototype;
    } else if (ref instanceof HTMLSelectElement) {
        prototype = HTMLSelectElement.prototype;
    } else if (ref instanceof HTMLTextAreaElement) {
        prototype = HTMLTextAreaElement.prototype;
    }
    if (!prototype) return;
    const descriptor = Object.getOwnPropertyDescriptor(prototype, "value");
    const setter = descriptor?.set;
    if (setter) {
        setter.call(ref, value);
        const event = new Event("change", { bubbles: true });
        ref.dispatchEvent(event);
    }
}
