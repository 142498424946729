"use client";

import type { FC } from "react";

import { useMemo } from "react";

interface DayElementProps {
    connectLeft?: boolean;
    connectRight?: boolean;
    isDisabled?: boolean;
    isCurrent?: boolean;
    isSelected?: boolean;
    isPreview?: boolean;
    value: Date;
    onClick?: (value: Date) => void;
    onMouseEnter?: (value: Date) => void;
    onMouseLeave?: (value: Date) => void;
}

const DayElement: FC<DayElementProps> = ({
    value,
    connectLeft,
    connectRight,
    isCurrent,
    isDisabled,
    isPreview,
    isSelected,
    onClick,
    onMouseEnter,
    onMouseLeave,
}) => {
    const [mainStyle, backgroundStyle] = useMemo(() => {
        const mainStyle = [];
        const backgroundStyle = [];

        if (isDisabled) {
            mainStyle.push("text-grey-100");
        } else if (isSelected) {
            mainStyle.push("text-white cursor-pointer hover:invert-15");
        } else {
            mainStyle.push("text-grey-500 cursor-pointer hover:invert-15");
        }

        if (isSelected && isDisabled) {
            mainStyle.push("bg-grey-200");
        } else if (isSelected && !isDisabled) {
            mainStyle.push("bg-doladoBlue-500");
        } else if (isPreview) {
            mainStyle.push("bg-grey-50");
        } else {
            mainStyle.push("bg-white");
        }

        if (isCurrent && !isSelected) {
            mainStyle.push(
                "before:absolute before:inset-0 before:border before:border-grey-500 before:rounded-4 before:pointer-events-none before:touch-none",
            );
        }

        if (connectLeft && connectRight) {
            backgroundStyle.push("border-x-10 border-x-grey-50");
        } else if (connectLeft) {
            mainStyle.push("rounded-r-4");
            backgroundStyle.push(
                "border-l-10 border-l-grey-50 border-r-10 border-r-white",
            );
        } else if (connectRight) {
            mainStyle.push("rounded-l-4");
            backgroundStyle.push(
                "border-l-10 border-l-white border-r-10 border-r-grey-50",
            );
        } else {
            mainStyle.push("rounded-4");
        }

        return [mainStyle.join(" "), backgroundStyle.join(" ")];
    }, [
        connectLeft,
        connectRight,
        isCurrent,
        isDisabled,
        isPreview,
        isSelected,
    ]);

    const handleClick = () => {
        if (isDisabled) return;
        onClick?.(value);
    };

    const handleMouseEnter = () => {
        if (isDisabled) return;
        onMouseEnter?.(value);
    };

    const handleMouseLeave = () => {
        if (isDisabled) return;
        onMouseLeave?.(value);
    };

    return (
        <div
            className={`relative w-36 min-w-36 h-28 ${backgroundStyle}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <p
                className={`absolute top-0 left-1/2 -translate-x-1/2 w-28 min-w-28 h-28 text-sm/7 text-center ${mainStyle}`}
                onClick={handleClick}>
                {value.getDate()}
            </p>
        </div>
    );
};

export default DayElement;
