export function formatDateForHtml(
    value?: string | number | Date,
): string | undefined {
    if (!value) return;
    const temp = new Date(value);
    if (isNaN(temp.getTime())) return;
    if (typeof value === "string" && value.match(/\d{4}-\d{2}-\d{2}/)) {
        const timeZone = temp.getTimezoneOffset() * 60000;
        temp.setTime(temp.getTime() + timeZone);
    }
    const year = `${temp.getFullYear()}`;
    const month = `${temp.getMonth() + 1}`;
    const day = `${temp.getDate()}`;
    return `${year.padStart(4, "0")}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0",
    )}`;
}

export function parseDate(value?: string | number | Date): Date | undefined {
    if (!value) return;
    const temp = new Date(value);
    if (isNaN(temp.getTime())) return;
    if (typeof value === "string" && value.match(/\d{4}-\d{2}-\d{2}/)) {
        const timeZone = temp.getTimezoneOffset() * 60000;
        temp.setTime(temp.getTime() + timeZone);
    }
    return temp;
}

export function getDaysInMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
}

export function getDaysOfWeekNames(
    locale: string,
    format: "narrow" | "long" | "short" = "narrow",
) {
    const names: string[] = new Array(7).fill("");
    const temp = new Date();
    for (let i = 0; i < 7; i++) {
        names[temp.getDay()] = temp.toLocaleString(locale, {
            weekday: format,
        });
        temp.setDate(temp.getDate() + 1);
    }
    return names;
}

export function getMonthNames(
    locale: string,
    format: "numeric" | "2-digit" | "long" | "short" | "narrow" = "short",
) {
    const names: string[] = new Array(12).fill("");
    const temp = new Date();
    for (let i = 0; i < 12; i++) {
        names[temp.getMonth()] = temp.toLocaleString(locale, {
            month: format,
        });
        temp.setMonth(temp.getMonth() + 1);
    }
    return names;
}

export function getCurrentMonthRange() {
    const currentDate = new Date();
    const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
    );
    const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
    );
    return {
        start: formatDateForHtml(startOfMonth),
        end: formatDateForHtml(endOfMonth),
    };
}

export function getCurrentDayRange() {
    const currentDate = new Date();
    const formattedCurrentDate = formatDateForHtml(currentDate);
    return { start: formattedCurrentDate, end: formattedCurrentDate };
}

export function isLeapYear(year: number) {
    return (!(year % 4) && year % 100) || !(year % 400);
}
