import type { FromToCategory } from "./interface";

import type { RequestOptions } from "../../interface";

import lambdas from "../../../configs/rest/lambdas";

interface GetFromToCategoriesParams {
    IDCategoryTo?: string | number;
    IDTypeCompanyIntegration?: string | number;
}

export default async function getFromToCategories(
    params: GetFromToCategoriesParams,
    { signal }: RequestOptions = {},
): Promise<FromToCategory[]> {
    try {
        const { data } = await lambdas.get<FromToCategory[]>("/hub/category", {
            signal,
            params,
        });
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
