import { DEFAULT_STATUS } from "../../utils/helpers";
import type { PersistentState } from "../../utils/interface";

import { GetAllCompanyWalletRecordsParams } from "../../../services/companies/wallet/getAllWalletRecords";
import {
    WalletAllRecordData,
    WalletStatusInfo,
} from "../../../services/companies/wallet/interface";

import type { FilterState } from "../../plugins/filter-manager/interface";
import type {
    FilterDrawerExtraData,
    FilterDrawerGroupExtraData,
} from "../../../components/FilterDrawer/interface";

export type State = PersistentState<{
    companyWallet: {
        data: WalletStatusInfo[];
        status: DEFAULT_STATUS;
    };
    wallet: {
        data: (WalletAllRecordData | null)[];
        pageInfo: {
            limit: number;
            page: number;
            total: number;
        };
        query: Omit<GetAllCompanyWalletRecordsParams, "limit" | "page">;
        // pagination: PaginationState;
        filters: FilterState<FilterDrawerExtraData, FilterDrawerGroupExtraData>;
        status: DEFAULT_STATUS;
    };
}>;

export const moduleId = "wallet" as const;
