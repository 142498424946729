import axios from "axios";

import { MountException, MountResponse } from "./helpers";
import type { RequestResponse } from "./interface";

interface ViacepResponse {
    cep: string;
    logradouro: string;
    complemento: string | null;
    bairro: string | null;
    localidade: string;
    uf: string;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
}

export default async function searchZip(
    zip: string,
): Promise<RequestResponse<ViacepResponse, void>> {
    try {
        const { data } = await axios.get<ViacepResponse>(
            `https://viacep.com.br/ws/${zip.replace(/[-]/g, "")}/json/`,
        );
        return MountResponse(data);
    } catch (error) {
        return MountException<void>(error);
    }
}
