import type { Action, Store } from "redux";
import type { Persistor } from "redux-persist";

import type { ModuleBuilderFunction, ModuleData } from "../modules/interface";

export interface ModuleFetcher {
    (id: string): Promise<{
        default: ModuleData;
        createModule?: ModuleBuilderFunction;
    } | null>;
}

export interface DynamicStoreComplement {
    modules: ModuleData[];
    persistor: Persistor;
    initPersistor: () => void;
    addModules: (mds: ModuleData[]) => void;
    removeModules: (mds: Pick<ModuleData, "hash" | "id">[]) => void;
    toggleModule: (md: ModuleData) => void;
}

export type DynamicStore<
    S = Record<string, never>,
    A extends Action<any> = Action,
> = Store<S, A> & DynamicStoreComplement;

export const internalId = "@@internal";
