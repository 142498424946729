import type { ComponentProps, FC, ReactNode } from "react";

import { useInputOverwrite } from "../utils";

import { classes } from "../../../../utils/styles/tailwind/v3";

interface FormHelperTextProps extends ComponentProps<"p"> {
    children?: ReactNode;
}

const FormHelperText: FC<FormHelperTextProps> = ({
    className = "",
    children,
    ...props
}) => {
    const { isInvalid } = useInputOverwrite({});
    if (isInvalid) return null;
    return (
        <p
            className={classes`text-grey-300 text-xs mt-8 ${className}`}
            {...props}>
            {children}
        </p>
    );
};

export default FormHelperText;
