import type { FC, MouseEventHandler, ReactNode } from "react";

import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";

import { DrawerContext } from "./helpers";
import {
    ariaDescription,
    ariaLabel,
    portalId,
    drawerAnimations,
    drawerCommonStyle,
    drawerPositions,
    drawerSizes,
    drawerVariants,
} from "./interface";
import type {
    AnimationRelation,
    DrawerPositions,
    DrawerSizes,
    DrawerVariants,
} from "./interface";

import { classes } from "../../../utils/styles/tailwind/v4";

export interface DrawerProps {
    variant?: DrawerVariants;
    size?: DrawerSizes;
    position?: DrawerPositions;
    isOpen?: boolean;
    hasOverlay?: boolean;
    animations?: Partial<AnimationRelation>;
    className?: string;
    children?: ReactNode;
    onClose?: () => void;
}

export const Drawer: FC<DrawerProps> = ({
    variant = "primary",
    size = "md",
    position = "left",
    isOpen = false,
    hasOverlay = true,
    animations,
    onClose,
    className,
    children,
}) => {
    const [expanded, setExpanded] = useState(isOpen);
    useEffect(() => {
        if (isOpen) setExpanded(true);
    }, [isOpen]);

    const [label, description] = useMemo(() => {
        const rand = Math.round(Math.random() * 10000);
        return [`${ariaLabel}-${rand}`, `${ariaDescription}-${rand}`];
    }, []);

    const handleClickOverlay: MouseEventHandler<HTMLDivElement> = (event) => {
        if (
            event.target instanceof HTMLDivElement &&
            event.target.id === portalId
        )
            onClose?.();
    };

    const currentStyle = useMemo(
        () =>
            classes`${drawerCommonStyle} ${drawerVariants[variant]} ${
                drawerPositions[position]
            } ${drawerSizes[size]} ${
                isOpen
                    ? animations?.in ?? drawerAnimations[position].in
                    : animations?.out ?? drawerAnimations[position].out
            } ${className}`,
        [
            variant,
            size,
            position,
            animations?.in,
            animations?.out,
            isOpen,
            className,
        ],
    );

    const state = useMemo<DrawerContext>(
        () => ({
            aria: { expanded, description, label },
            position,
            variant,
            onClose,
        }),
        [expanded, description, label, position, variant, onClose],
    );

    if (!expanded) return null;

    return (
        <>
            {createPortal(
                <div
                    id={portalId}
                    tabIndex={-1}
                    className={`fixed inset-0 z-drawer transition-colors duration-200 block ${
                        hasOverlay && isOpen ? "bg-black/20" : "bg-black/0"
                    } ${hasOverlay ? "" : "pointer-events-none touch-none"}`}
                    onClick={handleClickOverlay}>
                    <DrawerContext.Provider value={state}>
                        <div
                            role="dialog"
                            aria-modal
                            aria-orientation="vertical"
                            aria-labelledby={label}
                            aria-describedby={description}
                            className={currentStyle}
                            onAnimationEnd={() => setExpanded(isOpen)}>
                            {children}
                        </div>
                    </DrawerContext.Provider>
                </div>,
                document.body,
            )}
        </>
    );
};

export default Drawer;

export * from "./interface";
export { default as DrawerHeader } from "./Header";
export { default as DrawerFooter } from "./Footer";
export { default as DrawerBody } from "./Body";
