import axios from "axios";
import config from "../../util/configApp";

export default (async function GetRouteApi(
    routeApi,
    account,
    WithToken,
    ClearCache,
    abortSignal,
) {
    const token = config.ApiToken;

    var CacheControl = ClearCache
        ? {
              "Cache-Control": "max-age=0",
          }
        : {};

    var RESPONSE = [];
    var SANDBOX;
    const axiosConfig = {
        headers: {
            "Content-Type": config.ApiContentType,
            Authorization: `Bearer ${token}`,
            ...CacheControl,
        },
    };

    if (abortSignal) axiosConfig.signal = abortSignal;

    if (account && WithToken) {
        SANDBOX = `https://${account}.${config.Api + routeApi}?token=${token}`;
    } else if (account) {
        SANDBOX = `https://${account}.${config.Api + routeApi}`;
    } else {
        SANDBOX = config.ApiUrl + routeApi;
    }

    await axios
        .get(SANDBOX, axiosConfig)
        .then((res) => {
            RESPONSE = res.data;

            if (RESPONSE !== "") {
                if (RESPONSE.errorType) {
                    RESPONSE = [];
                }
            } else {
                RESPONSE = [];
            }
        })
        .catch((error) => {
            if (error.response) {
                var response = error.response.data;

                if (error.response.status === 403) {
                    RESPONSE = {
                        status: "warning",
                        message: "Sessão",
                        response: "",
                        code: error.response.status,
                    };
                } else if (error.response.status === 500) {
                    RESPONSE = {
                        status: "warning",
                        message: "Erro ao realizar ação - status 500",
                        response: "",
                        code: error.response.status,
                    };
                } else {
                    if (typeof response === "object") {
                        var errorMessage = response.errorMessage;

                        var result = errorMessage
                            ?.replaceAll("[BadRequest] - ", "")
                            .replaceAll("[NotFound] - ", "")
                            .replaceAll("[", "")
                            .replaceAll("]", "")
                            .replaceAll("[BadRequest]- ", "")
                            .replaceAll("[BadRequest] -", "");

                        RESPONSE = {
                            status: "warning",
                            message: result,
                            response: "",
                            code: error.response.status,
                        };
                    } else {
                        RESPONSE = {
                            status: "warning",
                            message: "Erro ao realizar ação",
                            response: "",
                            code: error.response.status,
                        };
                    }
                }

                RESPONSE = [];
            }
        });

    return RESPONSE;
});
