import type { DEFAULT_STATUS } from "../../../../utils/helpers";

import type { SelectOption } from "../../../../../components/@basic/Forms/Select/Options/interface";

import type { RequestOptions } from "../../../../../services/interface";
import type { SkuPlainSpecification } from "../../../../../services/skus/sku/specifications/interface";

import type { CompanyBranch } from "../../../../../../services/companies/interface";
import type { CompanyIntegration } from "../../../../../../services/companies/integrations/interface";
import type { FromToCategory } from "../../../../../../services/hub/from-to/interface";
import type { HubResource } from "../../../../../../services/hub/resources/interface";
import type { HubQuestionAnswerTemplate } from "../../../../../../services/hub/miscellaneous/interface";
import type { PostOfferPayload } from "../../../../../../services/hub/offers/post";
import type {
    Kit,
    Sku,
    SkuImage,
} from "../../../../../../services/skus/interface";
import type { SkuSpecificationDefinition } from "../../../../../../services/skus/specifications/interface";

export interface IntegrationInstanceData {
    offerQuestionAnswerTemplates?: Pick<
        HubQuestionAnswerTemplate,
        "AnswerTemplate" | "AnswerTitle" | "IDHubProductQuestionAnswerTemplate"
    >[];
    offerType?: SelectOption[];
    officialStore?: SelectOption[];
    productCondition?: SelectOption[];
    shippingFree?: SelectOption[];
    shippingLocalPickUp?: SelectOption[];
    shippingMode?: SelectOption[];
    warrantyType?: SelectOption[];
    status: DEFAULT_STATUS;
}

export interface IntegrationTypeData {
    category: FromToCategory | null;
    resource: HubResource | null;
    specifications: {
        definitions: SkuSpecificationDefinition[];
        records: SkuPlainSpecification[];
    };
    status: DEFAULT_STATUS;
}

export type UserErrors =
    | {
          type: "payload";
          fieldName: string;
          messages: string[];
      }
    | {
          type: "create" | "sync";
          status: number;
          message: string;
      }
    | {
          type: "canceled";
      };

export interface State {
    accounts: {
        list: CompanyBranch[];
        status: DEFAULT_STATUS;
    };
    integrations: {
        instanceData: Record<string | number, IntegrationInstanceData>;
        list: CompanyIntegration[];
        status: DEFAULT_STATUS;
        typeData: Record<string | number, IntegrationTypeData>;
    };
    sku: {
        data: Sku | null;
        images: SkuImage[];
        lastUpdate: number | null;
        relatedKits: Kit[];
        status: DEFAULT_STATUS;
    };
    offerInfo: { accountName: string; id: number } | null;
    userErrors: UserErrors[];
    status: DEFAULT_STATUS;
}

export const moduleId = "orders/create" as const;

export interface FetchIntegrationInstanceDataFunction {
    (
        data: {
            sku: Sku;
            integration: CompanyIntegration;
            instanceData: Omit<IntegrationInstanceData, "status">;
            typeData: Pick<IntegrationTypeData, "category" | "resource">;
        },
        options: RequestOptions,
    ): Promise<Omit<IntegrationInstanceData, "status">>;
}

export type CreateOfferPayload = Pick<
    PostOfferPayload,
    "HubEcommerceDescription" | "HubProductName" | "PricingSet"
> &
    Partial<
        Pick<
            PostOfferPayload,
            | "Condition"
            | "IDProductHub"
            | "LeadTimeDays"
            | "ListingTypeId"
            | "OfficialStore"
            | "ShippingFree"
            | "ShippingLocalPickUp"
            | "ShippingMode"
            | "WarrantyTime"
            | "WarrantyTimeType"
            | "WarrantyType"
        >
    > & {
        HubProductSpecification?: { id: string | number; value: string }[];
    };
