import type { DEFAULT_STATUS } from "../../../utils/helpers";
import type { FilterState } from "../../../plugins/filter-manager/interface";
import type { PaginationState } from "../../../plugins/pagination-manager/interface";

import type {
    GetHubOffersParams,
    HubSkuItem,
    HubOfferItem,
} from "../../../../../services/hub/offers/getAll";
import type {
    HubOffer,
    HubSku,
} from "../../../../../services/hub/offers/interface";

export type PaginationParams = Partial<Omit<GetHubOffersParams, "Page">>;

export interface OfferItem
    extends Pick<
        HubOfferItem,
        | "AccountName"
        | "AdvertisementUrl"
        | "CatalogEligible" // extra para sincronia de anúncios
        | "CatalogListing" // extra para sincronia de anúncios
        | "CatalogStatus" // extra para sincronia de anúncios
        | "Health"
        | "IDCompanyIntegration"
        | "IDHubProduct"
        | "IDProduct"
        | "IDProductHub"
        | "IDSkuCompany"
        | "IDTypeCompanyIntegration" // extra para duplicar anúncio
        | "IntegrationName"
        | "ListingTypeDescription"
        | "LogoUrl"
        | "MainImageThumbnailURL"
        | "SkuName"
        | "TypeProduct"
    > {
    HubSku: Pick<
        HubSkuItem,
        | "IDHubAdvertisement" // extra para atualização de preço de variação
        | "IDSku" // extra para atualização de preço de variação
        | "MainImageURL"
        | "SkuName"
    >[]; // extra para sincronia de anúncios
}

export interface OfferItemComplement
    extends Pick<
        HubOffer,
        | "Catalog"
        | "CatalogEligible"
        | "CatalogListing"
        | "ShippingLogisticValue"
        | "ShippingLogisticType"
        | "ShippingTags"
        | "ShippingTagsValue"
    > {
    HubSku: Pick<
        HubSku,
        | "BarCode"
        | "IDSku"
        | "IDSkuHub"
        | "IDSkuCompany"
        | "MainImageURL"
        | "PricingCheck"
        | "PricingSell"
        | "PricingSet"
        | "ProductVariation"
        | "QtyAvailable"
        | "SkuName"
        | "TypeProductVariationValue"
    >[];
}

export interface FilterExtraData {
    adminOnly?: boolean;
}

export interface FilterLoaderParams {
    isAdmin?: boolean;
}

export interface State {
    complements: Record<
        string | number,
        {
            status: DEFAULT_STATUS;
            data: OfferItemComplement | null;
        }
    >;
    filters: FilterState;
    offers: PaginationState<OfferItem, PaginationParams>;
}

export const moduleId = "hubOffers" as const;
