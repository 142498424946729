import type { HubOfficialStore } from "./interface";

import type { RequestOptions } from "../../interface";

import lambdas from "../../../configs/rest/lambdas";

interface GetHubOfficialStoresParams {
    IDCompanyIntegration: string | number;
    IDTypeSalesChannelResource: string | number;
}

export default async function getHubOfficialStores(
    typeCompanyIntegrationId: string | number,
    params: GetHubOfficialStoresParams,
    { signal }: RequestOptions = {},
): Promise<HubOfficialStore[]> {
    try {
        const { data } = await lambdas.post<HubOfficialStore[]>(
            `hub/resource/${typeCompanyIntegrationId}`,
            undefined,
            { signal, params },
        );
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
