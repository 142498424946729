import type { ChangeEventHandler, FC } from "react";

import {
    Fragment,
    startTransition,
    useContext,
    useEffect,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";

import HelpButton from "./HelpButton";
import NotificationButton from "./NotificationButton";
import MainPageSideBar from "./Sidebar";

import { parseMenuItemAction, groupFilter, itemFilter } from "../helpers";

import ButtonIcon from "../../../components/@basic/ButtonIcon";
import Drawer from "../../../components/@basic/Drawer";
import Input from "../../../components/@basic/Forms/Input";
import InputGroup, {
    InputElement,
} from "../../../components/@basic/Forms/Input/Group";
import {
    LogoutIconInfo,
    MenuIconInfo,
    SearchNormalIcon,
} from "../../../components/@basic/Icons/Mocks";
import Popover, {
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
} from "../../../components/@basic/Popover";

import { useMenu } from "../../../contexts/menu/utils";
import type { MenuRelationItem } from "../../../contexts/menu/interface";
import { useSession } from "../../../contexts/session";

import useIsTablet from "../../../hooks/breakpoints/useIsTablet";

import { MainContext } from "../../../../contexts/MainContext";

const MainPageNavbar: FC = () => {
    const [search, setSearch] = useState("");

    const [showDrawer, setShowDrawer] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const onOpenDrawer = () => setShowDrawer(true);
    const onCloseDrawer = () => setShowDrawer(false);
    const onOpenSearch = () => setShowSearch(true);
    const onCloseSearch = () => setShowSearch(false);

    const { AppendPrependTabs, OnChangeTabActive } = useContext(MainContext);
    const {
        data: { isAdmin },
        logout,
    } = useSession();
    const { groups } = useMenu();

    const isTablet = useIsTablet();
    const navigate = useNavigate();

    useEffect(() => {
        if (isTablet) onCloseSearch();
        else onCloseDrawer();
    }, [isTablet]);

    const handleClickMenu = ({ icon, label, link }: MenuRelationItem) => {
        const component = link.replace("/app/", "");
        const URLSearchParams = `/app/#/${component}?component=${component}&title=${label}`;

        const data = {
            key: label,
            URLSearchParams: URLSearchParams,
            title: label,
            icon: icon,
            component: component,
        };

        AppendPrependTabs(data, "append");
    };

    const handleChangeSearch: ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        startTransition(() => {
            setSearch(event.target.value);
        });
    };

    const handleLogout = () => {
        logout(() => {
            navigate("/");
        });
    };

    const renderMenu = () =>
        groups
            .filter(groupFilter(search))
            .map(({ key, label, icon, children }) => (
                <Fragment key={key}>
                    <p className="flex items-center mb-0 text-sm text-grey">
                        <i
                            className={`mr-8 ${icon ?? "icon icon-menu-right"}`}
                        />
                        {label}
                    </p>
                    <div className="grid grid-cols-3">
                        {children.filter(itemFilter(search)).map((item) => (
                            <p
                                key={item.key}
                                className="mb-0 cursor-pointer text-xs/5 text-doladoBlue hover:invert-50 transition-colors"
                                onClick={parseMenuItemAction(item, {
                                    append: handleClickMenu,
                                    switch: OnChangeTabActive,
                                    callback: onCloseSearch,
                                })}>
                                {item.label}
                            </p>
                        ))}
                    </div>
                </Fragment>
            ));

    return (
        <nav className="col-span-2 flex justify-between items-center bg-white h-full border-b border-b-stone-200 px-16 md:px-32">
            <div className="md:flex-1 flex items-center gap-8">
                <ButtonIcon
                    variant="ghost"
                    size="sm"
                    icon={MenuIconInfo}
                    label="toggle-menu"
                    className="block lg:hidden p-2 text-lightGrey-700"
                    onClick={onOpenDrawer}
                />
                <Drawer
                    size="unset"
                    className="max-w-240"
                    isOpen={showDrawer}
                    onClose={onCloseDrawer}>
                    <MainPageSideBar onClick={onCloseDrawer} />
                </Drawer>

                <img
                    src="/images/logotipo-dolado-blue.svg"
                    alt="dolado"
                    width={100}
                    height={24}
                    className="w-80 md:w-100 object-contain object-center"
                />
            </div>

            <div className="flex-2 flex justify-end items-center gap-8">
                {/* <Popover
                    position="bottom-end"
                    size="unset"
                    className="hidden lg:block flex-1 max-w-320"
                    isOpen={showSearch}
                    onClose={onCloseSearch}>
                    <PopoverTrigger>
                        <InputGroup size="sm" className="w-full">
                            <InputElement>
                                <SearchNormalIcon className="w-16 h-16 ml-4 text-black/20" />
                            </InputElement>
                            <Input
                                type="text"
                                onFocus={onOpenSearch}
                                onChange={handleChangeSearch}
                            />
                        </InputGroup>
                    </PopoverTrigger>
                    <PopoverContent className="w-512 md:w-600">
                        <PopoverBody>
                            <div className="flex flex-col gap-16 w-full max-h-600 border border-stone-300 rounded-4 p-16 overflow-y-auto scrollbar-floating">
                                {renderMenu()}
                            </div>
                        </PopoverBody>
                    </PopoverContent>
                </Popover> */}

                {!isAdmin && <NotificationButton />}

                <ButtonIcon
                    variant="ghost"
                    size="sm"
                    label="logout"
                    icon={LogoutIconInfo}
                    title="Sair"
                    className="text-royalBlue-900"
                    onClick={handleLogout}
                />

                <HelpButton />
            </div>
        </nav>
    );
};

export default MainPageNavbar;
