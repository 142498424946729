import type { PrivilegeGroup } from "./interface";

import type { RequestOptions } from "../interface";

import lambdas from "../../configs/rest/lambdas";

export default async function getPrivilegesGroups({
    signal,
}: RequestOptions = {}): Promise<PrivilegeGroup[]> {
    try {
        const { data } = await lambdas.get<PrivilegeGroup[]>(
            "/user/privilege",
            { signal },
        );
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
