import type { HubShippingMode } from "./interface";

import type { RequestOptions } from "../../interface";

import lambdas from "../../../configs/rest/lambdas";

interface GetHubShippingModesParams {
    IDCompanyIntegration: string | number;
    IDTypeSalesChannelResource: string | number;
}

export default async function getHubShippingModes(
    typeCompanyIntegrationId: string | number,
    params: GetHubShippingModesParams,
    { signal }: RequestOptions = {},
): Promise<HubShippingMode[]> {
    try {
        const { data } = await lambdas.post<HubShippingMode[]>(
            `hub/resource/${typeCompanyIntegrationId}`,
            undefined,
            { signal, params },
        );
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
