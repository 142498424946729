import { mapErrorData } from "../../helpers";
import type { ErrorData, RequestOptions } from "../../interface";

import lambdas, { mountBaseUrl } from "../../../configs/rest/lambdas";

import { MountException, MountResponse } from "../../../new/services/helpers";
import type { RequestResponse } from "../../../new/services/interface";

export default async function deleteHubNotification(
    hubNotificationId: string | number,
    { accountName, signal }: RequestOptions = {},
): Promise<RequestResponse> {
    try {
        await lambdas.delete(`/hub/notification/${hubNotificationId}`, {
            baseURL: mountBaseUrl(accountName),
            signal,
        });
        return MountResponse(null);
    } catch (error) {
        console.log(error);
        return MountException<ErrorData>(error, mapErrorData);
    }
}
