import type { ComponentType, MutableRefObject } from "react";
import { createContext, useContext } from "react";

import { SignUpSteps } from "./interface";
import type { SignUpFormData } from "./interface";

import {
    CreditCardIcon,
    TickCircleIcon,
    UserIcon,
} from "../../components/@basic/Icons/Mocks";

import type {
    AccountPayload,
    AccountPayloadPayment,
} from "../../services/accounts/payloads/interface";
import type { PaymentMethod } from "../../services/miscellaneous/getPaymentMethods";
import type { ConsultantData } from "../../services/supabase/consultants";

export const mapSteps: Record<
    SignUpSteps,
    [SignUpSteps | null, SignUpSteps | null]
> = {
    [SignUpSteps.ACCOUNT]: [null, SignUpSteps.PAYMENT],
    [SignUpSteps.PAYMENT]: [SignUpSteps.ACCOUNT, SignUpSteps.SUCCESS],
    [SignUpSteps.SUCCESS]: [null, null],
};

export const mapBreadcrumbs: [
    SignUpSteps,
    string,
    ComponentType<{ className?: string }>,
][] = [
    [SignUpSteps.ACCOUNT, "Dados da conta", UserIcon],
    [SignUpSteps.PAYMENT, "Pagamento", CreditCardIcon],
    [SignUpSteps.SUCCESS, "Confirmação", TickCircleIcon],
];

export interface SignUpContext {
    data: MutableRefObject<Partial<SignUpFormData>>;
    consultantData: ConsultantData | null;
    paymentMethods: PaymentMethod[];
    currentStep: SignUpSteps | null;
    processing: boolean;
    loading: boolean;
    payloadInfo:
        | (Pick<AccountPayload, "_id" | "accountName"> & {
              payment: Pick<AccountPayloadPayment, "qrCode" | "value">;
          })
        | null;
    updateData: <S extends keyof SignUpFormData>(
        step: S,
        data: SignUpFormData[S],
    ) => void;
    goToNextStep: () => void;
    goToPrevStep: () => void;
    submit: () => Promise<void>;
}

export const SignUpContext = createContext<SignUpContext>({
    data: { current: {} },
    consultantData: null,
    paymentMethods: [],
    currentStep: SignUpSteps.ACCOUNT,
    processing: false,
    loading: false,
    payloadInfo: null,
    goToNextStep: () => null,
    goToPrevStep: () => null,
    submit: () => Promise.resolve(),
    updateData: () => null,
});

export function useSignUpContext(): SignUpContext {
    return useContext(SignUpContext);
}
