import type { FC } from "react";

import { useMemo } from "react";

import { SignUpFormNames, SignUpSteps } from "../../interface";
import type { SignUpFormData } from "../../interface";

import FormControl, {
    FormLabel,
    FormErrorMessage,
} from "../../../../components/@basic/Forms/FormControl";
import Input from "../../../../components/@basic/Forms/Input";
import Select from "../../../../components/@basic/Forms/Select";

import type { PaymentMethod } from "../../../../services/miscellaneous/getPaymentMethods";

import type { ErrorRelation } from "../../../../utils/forms/validation";

import formatMoney from "../../../../utils/formatters/formatMoney";
import {
    creditCardMask,
    creditCardMaskHandler,
} from "../../../../utils/inputs/masks/creditCard";
import { dateMask, dateMaskHandler } from "../../../../utils/inputs/masks/date";
import {
    onlyNumbersMask,
    onlyNumbersMaskHandler,
} from "../../../../utils/inputs/masks/onlyNumbers";
import { creditCardPattern } from "../../../../utils/inputs/patterns/creditCard";
import { datePattern } from "../../../../utils/inputs/patterns/date";
import { numbersPattern } from "../../../../utils/inputs/patterns/intervals";

interface CreditCardFormProps {
    data?: SignUpFormData[SignUpSteps.PAYMENT];
    errors: ErrorRelation<SignUpFormData[SignUpSteps.PAYMENT]>;
    isDisabled?: boolean;
    method: PaymentMethod;
    totalPrice?: number;
}

const CreditCardForm: FC<CreditCardFormProps> = ({
    data,
    errors,
    isDisabled,
    method: { maxInstallments },
    totalPrice,
}) => {
    const options = useMemo(
        () =>
            maxInstallments && totalPrice
                ? new Array(maxInstallments || 1).fill(null).map((_, idx) => ({
                      value: idx + 1,
                      label: `${idx + 1}x de ${formatMoney(
                          totalPrice / (idx + 1),
                      )}`,
                  }))
                : [],
        [maxInstallments, totalPrice],
    );

    const {
        cardExpirationDate,
        cardName,
        cardNumber,
        cardSecurityCode,
        installment_count,
    } = data || {};

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 md:gap-x-32 gap-y-16 md:gap-y-24 mt-24 md:mt-32">
            <FormControl
                isRequired
                isDisabled={isDisabled}
                isInvalid={!!errors.cardNumber}>
                <FormLabel>Número do cartão</FormLabel>
                <Input
                    type="text"
                    name={SignUpFormNames.CARD_NUMBER}
                    data-label="Número do cartão"
                    data-parser="onlyNumbers"
                    placeholder="Digite o número do cartão"
                    pattern={creditCardPattern}
                    defaultValue={creditCardMask(cardNumber || "")}
                    onChange={creditCardMaskHandler}
                />
                <FormErrorMessage>{errors.cardNumber?.[0]}</FormErrorMessage>
            </FormControl>
            <FormControl
                isRequired
                isDisabled={isDisabled}
                isInvalid={!!errors.cardName}>
                <FormLabel>Nome impresso no cartão</FormLabel>
                <Input
                    type="text"
                    name={SignUpFormNames.CARD_NAME}
                    data-label="Nome impresso no cartão"
                    data-parser="uppercase"
                    placeholder="Digite o nome"
                    defaultValue={cardName}
                />
                <FormErrorMessage>{errors.cardName?.[0]}</FormErrorMessage>
            </FormControl>
            <FormControl
                isRequired
                isDisabled={isDisabled}
                isInvalid={!!errors.cardExpirationDate}>
                <FormLabel>Validade</FormLabel>
                <Input
                    type="text"
                    name={SignUpFormNames.CARD_EXPIRATION_DATE}
                    data-label="Validade"
                    placeholder="mm/aaaa"
                    pattern={datePattern({ format: "MM/YYYY" })}
                    defaultValue={dateMask({ format: "MM/YYYY" })(
                        cardExpirationDate || "",
                    )}
                    onChange={dateMaskHandler({ format: "MM/YYYY" })}
                />
                <FormErrorMessage>
                    {errors.cardExpirationDate?.[0]}
                </FormErrorMessage>
            </FormControl>
            <FormControl
                isRequired
                isDisabled={isDisabled}
                isInvalid={!!errors.cardSecurityCode}>
                <FormLabel>CVV</FormLabel>
                <Input
                    type="text"
                    name={SignUpFormNames.CARD_SECURITY_CODE}
                    data-label="CVV"
                    placeholder="Digite o código"
                    pattern={`${numbersPattern}+`}
                    minLength={3}
                    maxLength={4}
                    defaultValue={onlyNumbersMask(cardSecurityCode || "")}
                    onChange={onlyNumbersMaskHandler}
                />
                <FormErrorMessage>
                    {errors.cardSecurityCode?.[0]}
                </FormErrorMessage>
            </FormControl>
            {maxInstallments && maxInstallments > 1 && (
                <FormControl
                    isRequired
                    isDisabled={isDisabled}
                    isInvalid={!!errors.installment_count}>
                    <FormLabel>Nº de parcelas</FormLabel>
                    <Select
                        name={SignUpFormNames.INSTALLMENT_COUNT}
                        data-label="Nº de parcelas"
                        data-parser="toNumber"
                        defaultValue={installment_count}>
                        {options.map(({ label, value }, idx) => (
                            <option key={idx} value={value}>
                                {label}
                            </option>
                        ))}
                    </Select>
                    <FormErrorMessage>
                        {errors.installment_count?.[0]}
                    </FormErrorMessage>
                </FormControl>
            )}
        </div>
    );
};

export default CreditCardForm;
