import type { FC, ReactNode } from "react";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { OnboardingContext, OnboardingChecks } from "./interface";
import { fetchChecks } from "./helpers";

import { useSession } from "../session";

interface OnboardingContextProvider {
    children?: ReactNode;
}

const OnboardingContextProvider: FC<OnboardingContextProvider> = ({
    children,
}) => {
    const {
        data: { accountId, company, isAdmin },
    } = useSession();
    const isPF = company?.documentType === "cpf";

    const [checks, setChecks] = useState<OnboardingContext["checks"]>(null);
    const [isProcessing, setIsProcessing] = useState(true);
    const requesting = useRef(false);
    const finished = useRef(false);

    const updateChecks: OnboardingContext["updateChecks"] = useCallback(
        async (mustLoad) => {
            if (!accountId || requesting.current || finished.current) return;
            requesting.current = true;
            if (mustLoad) setIsProcessing(true);

            const response = await fetchChecks(accountId);
            setChecks(response);

            if (
                Object.values(OnboardingChecks).every((ck) =>
                    response.includes(ck),
                )
            ) {
                finished.current = true;
            }

            if (mustLoad) setIsProcessing(false);
            setTimeout(() => {
                requesting.current = false;
            }, 2000);
        },
        [accountId],
    );

    useEffect(() => {
        if (typeof isAdmin !== "undefined" && !isAdmin) {
            finished.current = false;
            updateChecks(true);
        } else {
            finished.current = true;
        }
    }, [isAdmin, updateChecks]);

    const state = useMemo<OnboardingContext>(
        () => ({ checks, isPF, isProcessing, updateChecks }),
        [checks, isPF, isProcessing, updateChecks],
    );

    return (
        <OnboardingContext.Provider value={state}>
            {children}
        </OnboardingContext.Provider>
    );
};

export default OnboardingContextProvider;

export * from "./utils";
