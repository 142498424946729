import type { FC } from "react";

import { SignUpFormNames } from "../../interface";

import {
    CreditCardIconInfo,
    PixIconInfo,
} from "../../../../components/@basic/Icons/Mocks";
import Icon from "../../../../components/@basic/Icons";
import type { IconInfo } from "../../../../components/@basic/Icons/interface";

import { PaymentMethodTypes } from "../../../../services/miscellaneous/getPaymentMethods";
import type { PaymentMethod } from "../../../../services/miscellaneous/getPaymentMethods";

const mapPaymentMethods: Record<
    PaymentMethodTypes,
    { icon: IconInfo; label: string }
> = {
    [PaymentMethodTypes.CREDIT_CARD]: {
        icon: CreditCardIconInfo,
        label: "Cartão de crédito",
    },
    [PaymentMethodTypes.PIX]: {
        icon: PixIconInfo,
        label: "Pix",
    },
};

interface PaymentMethodItemProps {
    isDisabled?: boolean;
    method: PaymentMethod;
    onChange?: (value: string) => void;
    selected: boolean;
}

const PaymentMethodItem: FC<PaymentMethodItemProps> = ({
    isDisabled,
    method: { _id, asaasType },
    onChange,
    selected,
}) => {
    const { icon, label } = (asaasType && mapPaymentMethods[asaasType]) || {};

    const handleClick = () => {
        if (selected || isDisabled) return;
        onChange?.(_id);
    };

    if (!icon || !label) return null;

    return (
        <>
            <div
                className={`cursor-pointer flex flex-col justify-center items-center gap-4 w-76 h-72 rounded-12 text-[10px] leading-3 text-neutral-900 text-center ${
                    selected
                        ? "border border-doladoBlue-700 outline outline-4 outline-doladoBlue-100"
                        : "bg-neutral-50 border border-neutral-100"
                }`}
                onClick={handleClick}>
                {icon && (
                    <Icon
                        info={icon}
                        className={`w-24 h-24 ${
                            selected ? "text-doladoBlue-700" : "text-inherit"
                        }`}
                    />
                )}
                <span>{label}</span>
            </div>
            <input
                className="hidden"
                type="radio"
                name={SignUpFormNames.PAYMENT_METHOD}
                data-persist
                readOnly
                checked={selected}
                value={_id}
                disabled={isDisabled}
            />
        </>
    );
};

export default PaymentMethodItem;
