import type { FC, ReactNode } from "react";
import type { AnyAction } from "redux";
import type { ThunkDispatch } from "redux-thunk";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import { ConfigProvider } from "antd";
import { IntlProvider as Temp, MessageFormatElement } from "react-intl";

import { useSession } from "./session";

import { setInitUrl } from "../../appRedux/actions";
import {
    onLayoutTypeChange,
    onNavStyleChange,
    setThemeType,
} from "../../appRedux/actions/Setting";

import CircularProgress from "../../components/CircularProgress";

import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";

import AppLocale from "../../lngProvider";

const IntlProvider = Temp as unknown as FC<{
    locale: string;
    messages?:
        | Record<string, string>
        | Record<string, MessageFormatElement[]>
        | undefined;
    children?: ReactNode;
}>;

const setLayoutType = (layoutType?: string) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
        document.body.classList.remove("boxed-layout");
        document.body.classList.remove("framed-layout");
        document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
        document.body.classList.remove("full-layout");
        document.body.classList.remove("framed-layout");
        document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
        document.body.classList.remove("boxed-layout");
        document.body.classList.remove("full-layout");
        document.body.classList.add("framed-layout");
    }
};

const setNavStyle = (navStyle?: string) => {
    if (
        navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
        navStyle === NAV_STYLE_DARK_HORIZONTAL ||
        navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
        navStyle === NAV_STYLE_ABOVE_HEADER ||
        navStyle === NAV_STYLE_BELOW_HEADER
    ) {
        document.body.classList.add("full-scroll");
        document.body.classList.add("horizontal-layout");
    } else {
        document.body.classList.remove("full-scroll");
        document.body.classList.remove("horizontal-layout");
    }
};

const styleSheetLink = document.createElement("link");
styleSheetLink.type = "text/css";
styleSheetLink.rel = "stylesheet";
document.body.appendChild(styleSheetLink);

interface ReduxState {
    settings: {
        initURL?: string;
        isDirectionRTL?: string;
        layoutType?: string;
        locale?: { locale: string };
        navStyle?: string;
        themeColor?: string;
        themeType?: string;
    };
}

const ThemeProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const themeColor = useSelector<
        ReduxState,
        ReduxState["settings"]["themeColor"]
    >(({ settings }) => settings.themeColor);
    const locale = useSelector<ReduxState, ReduxState["settings"]["locale"]>(
        ({ settings }) => settings.locale,
    );
    const navStyle = useSelector<
        ReduxState,
        ReduxState["settings"]["navStyle"]
    >(({ settings }) => settings.navStyle);
    const layoutType = useSelector<
        ReduxState,
        ReduxState["settings"]["layoutType"]
    >(({ settings }) => settings.layoutType);
    const themeType = useSelector<
        ReduxState,
        ReduxState["settings"]["themeType"]
    >(({ settings }) => settings.themeType);
    const isDirectionRTL = useSelector<
        ReduxState,
        ReduxState["settings"]["isDirectionRTL"]
    >(({ settings }) => settings.isDirectionRTL);
    const initURL = useSelector<ReduxState, ReduxState["settings"]["initURL"]>(
        ({ settings }) => settings.initURL,
    );

    const { loading, logout } = useSession();

    const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();
    const location = useLocation();

    useEffect(() => {
        if (isDirectionRTL) {
            document.documentElement.classList.add("rtl");
            document.documentElement.setAttribute("data-direction", "rtl");
        } else {
            document.documentElement.classList.remove("rtl");
            document.documentElement.setAttribute("data-direction", "ltr");
        }

        //Aqui
        if (themeColor) {
            styleSheetLink.href = `/css/${themeColor}.css`;
        }
    }, [themeColor, isDirectionRTL]);

    useEffect(() => {
        if (locale) document.documentElement.lang = locale?.locale;
    }, [locale]);

    //aqui
    useEffect(() => {
        if (themeType === THEME_TYPE_DARK) {
            document.body.classList.add("dark-theme");
            styleSheetLink.href = "/css/dark_theme.css";
        } else if (document.body.classList.contains("dark-theme")) {
            document.body.classList.remove("dark-theme");
            styleSheetLink.href = "";
        }
    }, [themeType]);

    useEffect(() => {
        if (initURL === "") {
            dispatch(setInitUrl(location.pathname));
        }
    });

    useEffect(() => {
        if (initURL === "") {
            dispatch(setInitUrl(location.pathname));
        }
        const params = new URLSearchParams(location.search);

        if (params.has("theme")) {
            dispatch(setThemeType(params.get("theme")));
        }
        if (params.has("nav-style")) {
            dispatch(onNavStyleChange(params.get("nav-style")));
        }
        if (params.has("layout-type")) {
            dispatch(onLayoutTypeChange(params.get("layout-type")));
        }
    }, [location.search, dispatch, initURL, location.pathname]);

    useEffect(() => {
        setLayoutType(layoutType);
        setNavStyle(navStyle);
    }, [layoutType, navStyle]);

    useEffect(() => {
        axios.defaults.validateStatus = (status) => {
            if (status === 403) logout();
            return status >= 200 && status < 300;
        };
    }, [logout]);

    const currentAppLocale =
        AppLocale[(locale?.locale ?? "pt") as keyof typeof AppLocale];

    return loading ? (
        <CircularProgress className="" />
    ) : (
        <ConfigProvider
            locale={currentAppLocale.antd}
            direction={isDirectionRTL ? "rtl" : "ltr"}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages as Record<string, string>}>
                {children}
            </IntlProvider>
        </ConfigProvider>
    );
};

export default ThemeProvider;
