import type { ComponentProps, ChangeEventHandler } from "react";

import { forwardRef, useEffect, useMemo, useRef, useState } from "react";

import { useHTMLDate } from "./helpers";
import {
    dateInputCommonStyle,
    dateInputInvalidStyle,
    dateInputReadOnlyStyle,
    dateInputSizes,
    dateInputVariants,
} from "./interface";

import DatePicker from "../DatePicker";
import type { InputSizes, InputVariants } from "../utils";

import ButtonIcon from "../../ButtonIcon";
import { CalendarIconInfo, ChevronRightIcon } from "../../Icons/Mocks";

import useIsMobile from "../../../../hooks/breakpoints/useIsMobile";

import { formatDateForHtml, parseDate } from "../../../../utils/date";
import { triggerChangeEvent } from "../../../../utils/inputs/events";
import { classes } from "../../../../utils/styles/tailwind/v3";

interface DateInputProps
    extends Omit<
        ComponentProps<"input">,
        "size" | "name" | "id" | "value" | "defaultValue"
    > {
    defaultValue?:
        | string
        | number
        | Date
        | (string | number | Date | undefined)[];
    id?: string | string[];
    isRange?: boolean;
    isRequired?: boolean;
    isDisabled?: boolean;
    isFuture?: boolean;
    isInvalid?: boolean;
    isPast?: boolean;
    isReadOnly?: boolean;
    name?: string | string[];
    size?: InputSizes;
    value?: string | number | Date | (string | number | Date | undefined)[];
    variant?: InputVariants;
}

const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
    function DateInput(
        {
            variant = "primary",
            size = "md",
            id,
            name,
            isRequired,
            isDisabled,
            isInvalid,
            isReadOnly,
            isRange,
            isPast,
            isFuture,
            className = "",
            defaultValue,
            value,
            onChange,
            max: _max,
            min: _min,
            ...rest
        },
        forwarded,
    ) {
        const picker = useRef<HTMLDivElement>(null);
        const inputs = useRef<HTMLInputElement[]>([]);

        const [showDatePicker, setShowDatePicker] = useState(false);
        const [date1, setDate1] = useState<Date>();
        const [date2, setDate2] = useState<Date>();
        const internal = useRef(false);

        const isMobile = useIsMobile();

        useEffect(() => {
            if (!showDatePicker) return;

            const handle = (event: MouseEvent) => {
                if (!picker.current || !inputs.current.length) {
                    setShowDatePicker(false);
                    return;
                }

                const target = event.target as HTMLElement;

                if (
                    !inputs.current.some((ref) => ref.contains(target)) &&
                    !picker.current.contains(target)
                ) {
                    setShowDatePicker(false);
                }
            };

            window.addEventListener("mousedown", handle);
            return () => {
                window.removeEventListener("mousedown", handle);
            };
        }, [showDatePicker]);

        const handleFocus = () => {
            if (
                !inputs.current.length ||
                showDatePicker ||
                isDisabled ||
                isReadOnly
            )
                return;
            const fields = inputs.current;
            setDate1(parseDate(fields[0]?.value));
            setDate2(parseDate(fields[1]?.value));
            setShowDatePicker(true);
        };

        const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
            event.persist();
            const fields = inputs.current;
            if (!internal.current && fields.length) {
                const idx = fields.findIndex((ref) => ref === event.target);
                if (idx === 0) {
                    setDate1(parseDate(fields[idx].value));
                } else if (idx === 1) {
                    setDate2(parseDate(fields[idx].value));
                }
            }
            internal.current = false;
            onChange?.(event);
        };

        const handleSelectDate = (newValues: Date[]) => {
            const formatted = newValues.map(formatDateForHtml);
            const fields = inputs.current;
            if (fields[0]?.value !== formatted[0]) {
                internal.current = true;
                triggerChangeEvent(fields[0], formatted[0] ?? "");
            }
            if (fields[1]?.value !== formatted[1]) {
                internal.current = true;
                triggerChangeEvent(fields[1], formatted[1] ?? "");
            }
        };

        const processRef =
            (idx: number) => (current: HTMLInputElement | null) => {
                if (current) inputs.current[idx] = current;
                if (typeof forwarded === "function") {
                    forwarded(current);
                } else if (forwarded !== null) {
                    forwarded.current = current;
                }
            };

        const defaultValues = useHTMLDate(defaultValue);
        const values = useHTMLDate(value);
        const max = useHTMLDate(_max || (isPast ? Date.now() : "")) as
            | string
            | undefined;
        const min = useHTMLDate(_min || (isFuture ? Date.now() : "")) as
            | string
            | undefined;

        const currentStyle = useMemo(
            () =>
                classes`${variant !== "unstyled" ? dateInputCommonStyle : ""} ${
                    dateInputSizes[size]
                } ${dateInputVariants[variant]} ${className} ${
                    isReadOnly ? dateInputReadOnlyStyle : ""
                } ${isInvalid ? dateInputInvalidStyle : ""}`,
            [variant, size, className, isInvalid, isReadOnly],
        );

        return (
            <div
                className={`group relative flex items-stretch gap-8 ${currentStyle}`}>
                <input
                    ref={processRef(0)}
                    className="flex-1 min-w-0 hide-calendar"
                    id={typeof id === "object" ? id[0] : id}
                    name={typeof name === "object" ? name[0] : name}
                    type="date"
                    required={isRequired}
                    disabled={isDisabled}
                    readOnly={isReadOnly}
                    tabIndex={isReadOnly ? -1 : undefined}
                    aria-required={isRequired}
                    aria-disabled={isDisabled}
                    aria-invalid={isInvalid}
                    defaultValue={
                        typeof defaultValues === "object"
                            ? defaultValues[0]
                            : defaultValues
                    }
                    value={typeof values === "object" ? values[0] : values}
                    max={max}
                    min={min}
                    onChange={handleChange}
                    {...rest}
                />
                {isRange && (
                    <ChevronRightIcon className="w-16 min-w-16 h-auto text-grey-500" />
                )}
                {isRange && (
                    <input
                        ref={processRef(1)}
                        className="flex-1 min-w-0 hide-calendar"
                        id={typeof id === "object" ? id[1] : id}
                        name={typeof name === "object" ? name[1] : name}
                        type="date"
                        required={isRequired}
                        disabled={isDisabled}
                        readOnly={isReadOnly}
                        tabIndex={isReadOnly ? -1 : undefined}
                        aria-required={isRequired}
                        aria-disabled={isDisabled}
                        aria-invalid={isInvalid}
                        defaultValue={
                            typeof defaultValues === "object"
                                ? defaultValues[1]
                                : defaultValues
                        }
                        value={typeof values === "object" ? values[1] : values}
                        max={max}
                        min={min}
                        onChange={handleChange}
                        {...rest}
                    />
                )}
                <ButtonIcon
                    variant="unstyled"
                    size="unset"
                    icon={CalendarIconInfo}
                    label="open-calendar"
                    type="button"
                    className={`${
                        !isDisabled && !isReadOnly ? "cursor-pointer" : ""
                    } w-16 min-w-16 text-gray-500`}
                    onClick={handleFocus}
                />
                {showDatePicker && (
                    <div className="fixed md:absolute top-0 md:top-full left-0 z-10 translate-y-4 flex justify-center items-center max-md:bg-black/10 w-full md:w-max h-full md:h-max">
                        <div ref={picker}>
                            <DatePicker
                                dateFrom={date1}
                                dateTo={date2}
                                maxDate={_max}
                                minDate={_min}
                                isRange={isRange}
                                isFuture={isFuture}
                                isPast={isPast}
                                isCompact={isMobile}
                                onChange={handleSelectDate}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    },
);

export default DateInput;
