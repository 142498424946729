import type { FC, ReactNode } from "react";

import {
    buttonGroupCommonStyle,
    buttonGroupDirectionStyles,
} from "./interface";
import type { ButtonGroupDirections } from "./interface";

import { classes } from "../../../utils/styles/tailwind/v4";

interface ButtonGroupProps {
    children?: ReactNode;
    className?: string;
    direction?: ButtonGroupDirections;
}

const ButtonGroup: FC<ButtonGroupProps> = ({
    direction = "horizontal",
    className,
    children,
}) => {
    return (
        <div
            className={classes`${buttonGroupCommonStyle} ${buttonGroupDirectionStyles[direction]} ${className}`}>
            {children}
        </div>
    );
};

export default ButtonGroup;
