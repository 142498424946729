import type { FC } from "react";

import { useEffect, useState } from "react";

import { useSignUpContext } from "../helpers";
import { SignUpSteps } from "../interface";

import { PixIcon } from "../../../components/@basic/Icons/Mocks";

import formatMoney from "../../../utils/formatters/formatMoney";
import createInterval from "../../../utils/wrappers/interval";

const SuccessStep: FC = () => {
    const { currentStep, payloadInfo } = useSignUpContext();

    const [countdown, setCountdown] = useState("");

    useEffect(() => {
        if (!payloadInfo?.payment.qrCode?.expirationDate) return;
        const expirationDate = new Date(
            payloadInfo.payment.qrCode.expirationDate,
        ).getTime();
        const handle = createInterval(() => {
            const now = Date.now();
            if (now >= expirationDate) {
                setCountdown("00:00");
                return true;
            } else {
                const diff = (expirationDate - now) / 1000;
                const min = Math.floor(diff / 60);
                const sec = Math.floor(diff - min * 60);
                setCountdown(`${min}:${sec.toString().padStart(2, "0")}`);
            }
        }, 1000);
        return () => {
            if (handle.current) clearTimeout(handle.current);
        };
    }, [payloadInfo?.payment.qrCode?.expirationDate]);

    const renderPaymentInfo = () => {
        if (!payloadInfo?.payment.qrCode) return null;

        const { payload, url } = payloadInfo.payment.qrCode;

        const qrCodeSrc = `${process.env.REACT_APP_STATIC_FILES_ENDPOINT}/${url}`;

        return (
            <div className="flex-1 bg-neutral-100 border border-neutral-200 rounded-8 px-12 pt-16 pb-24 text-grey-700">
                <h4 className="mb-16 text-base/5 font-bold">
                    Forma de pagamento
                </h4>
                <div className="flex justify-between items-center mb-14 text-base">
                    <p className="mb-0">
                        <PixIcon className="inline-block mr-6 stroke-2" /> Pix
                    </p>
                    <p className="mb-0 font-bold">
                        {formatMoney(payloadInfo.payment.value)}
                    </p>
                </div>
                <div className="flex justify-between items-center mb-14 text-base font-bold">
                    <p className="mb-0">Tempo restante:</p>
                    <p className="mb-0">{countdown}</p>
                </div>
                <img
                    src={qrCodeSrc}
                    alt="QRCode Image"
                    width={128}
                    height={128}
                    className="mb-28"
                />
                <p className="mb-24 text-xs/5">
                    Aponte a câmera do seu celular para o QR Code e faça um PIX
                    no app do seu banco. Se preferir, copie e cole o código
                    abaixo:
                </p>
                <p className="mb-0 text-sm font-bold break-all">{payload}</p>
            </div>
        );
    };

    if (currentStep !== SignUpSteps.SUCCESS) return null;

    return (
        <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row gap-24 min-h-360">
            {renderPaymentInfo()}
            <div className="flex-1">
                <p className="mb-16 text-base/7">
                    <b>Seja Bem-vindo(a), parceiro(a) dolado!</b> Sua jornada de
                    sucesso está prestes a começar. Em breve, você receberá dois
                    e-mails importantes:
                </p>
                <ul className="list-disc text-sm/5 space-y-8">
                    <li>
                        <b>Acesso à Plataforma de Vendas:</b> Disponível para
                        você começar a vender e gerenciar seus produtos de
                        maneira eficiente.{" "}
                        {payloadInfo?.accountName && (
                            <a
                                href={`https://${payloadInfo.accountName}.dolado.com.br`}>
                                https://{payloadInfo.accountName}.dolado.com.br
                            </a>
                        )}
                    </li>
                    <li>
                        <b>Acesso à Plataforma de Conteúdo:</b> Repleto de
                        recursos exclusivos que ajudarão a impulsionar suas
                        vendas.{" "}
                        <a
                            href="https://www.universidadedolado.com.br"
                            target="_blank">
                            https://www.universidadedolado.com.br
                        </a>
                    </li>
                </ul>
                <p className="mb-16 text-base/7">
                    Se precisar de qualquer auxílio, estamos à disposição nos
                    seguintes canais:
                </p>
                <ul className="list-disc text-sm/5 space-y-8">
                    <li>
                        <b>WhatsApp:</b> (11) 97307-9915
                    </li>
                </ul>
                <p className="mb-16 text-base/7">
                    Obrigado por escolher a dolado. Estamos aqui para
                    apoiá-lo(a) em cada etapa do seu caminho rumo ao sucesso!
                </p>
            </div>
        </div>
    );
};

export default SuccessStep;
