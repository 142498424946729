import { MountException, MountResponse } from "../helpers";
import { RequestResponse } from "../interface";

import type { CompanyParameter } from "../companies/company/interface";

import franchise from "../../configs/rest/franchise";

import getSubdomain from "../../utils/navigation/getSubdomain";

export interface PostLoginPayload {
    email: string;
    password: string;
}

export interface PostLoginResponse {
    body: {
        companyAdminMaster: 0 | 1;
        companyCpfCnpj: string;
        companyLogoUrl: string;
        companyNameCorporateName: string;
        email: string;
        externalDashboard: 0 | 1;
        helpDeskToken: string;
        id: number;
        parameters: CompanyParameter[];
        restrictAccessSalesman: 0 | 1;
        salesman: 0 | 1;
        theme: string | null;
        userName: string;
    };
    token: string;
}

export default async function postLogin({
    email,
    password,
}: PostLoginPayload): Promise<RequestResponse<PostLoginResponse>> {
    try {
        const accountName = getSubdomain();

        const { data } = await franchise.post<PostLoginResponse>(
            `/v1/users/login`,
            { email, password, accountName },
        );

        return MountResponse(data);
    } catch (error) {
        console.log(error);
        return MountException(error);
    }
}
