import type { ComponentProps } from "react";

import { forwardRef, useMemo } from "react";

import {
    inputSizes,
    inputVariants,
    inputCommonStyle,
    inputInvalidStyle,
} from "./interface";

import { useInputOverwrite } from "../utils";
import type { InputSizes, InputVariants } from "../utils";

import { classes } from "../../../../utils/styles/tailwind/v3";

export interface InputProps extends Omit<ComponentProps<"input">, "size"> {
    variant?: InputVariants;
    size?: InputSizes;
    isDisabled?: boolean;
    isInvalid?: boolean;
    isReadOnly?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
    props,
    ref,
) {
    const {
        id,
        isDisabled,
        isInvalid,
        isReadOnly,
        isRequired,
        name,
        size = "md",
        variant = "primary",
        className = "",
        ...rest
    } = useInputOverwrite(props);

    const currentStyle = useMemo(
        () =>
            classes`${variant !== "unstyled" ? inputCommonStyle : ""} ${
                inputSizes[size]
            } ${inputVariants[variant]} ${className} ${
                isInvalid ? inputInvalidStyle : ""
            }`,
        [variant, size, className, isInvalid],
    );

    return (
        <input
            ref={ref}
            className={currentStyle}
            id={id}
            name={name}
            required={isRequired}
            disabled={isDisabled}
            readOnly={isReadOnly}
            tabIndex={isReadOnly ? -1 : undefined}
            aria-required={isRequired}
            aria-disabled={isDisabled}
            aria-invalid={isInvalid}
            {...rest}
        />
    );
});

export default Input;
