import type { AccountPayload } from "./interface";
import { MountException, MountResponse } from "../../helpers";
import type { RequestResponse } from "../../interface";

import franchise from "../../../configs/rest/franchise";

export interface CreateAccountPayloadPayload
    extends Required<
            Pick<
                AccountPayload,
                | "accountName"
                | "consultantId"
                | "document"
                | "phone"
                | "userEmail"
                | "userName"
            >
        >,
        Partial<
            Pick<
                AccountPayload,
                | "billingAddress"
                | "birthDate"
                | "idPaymentModel"
                | "tax"
                | "validateSefaz"
                | "validateSintegra"
            >
        > {
    payment: Required<
        Pick<AccountPayload["payment"], "paymentMethod" | "value">
    > & {
        cardToken: string;
        installments?: number;
    };
}

interface CreateAccountPayloadRequestResponse {
    accountPayload: AccountPayload;
}
export default async function createAccountPayload(
    payload: CreateAccountPayloadPayload,
): Promise<RequestResponse<AccountPayload>> {
    try {
        const { data } =
            await franchise.post<CreateAccountPayloadRequestResponse>(
                "/v1/accounts/payloads",
                payload,
            );
        return MountResponse(data.accountPayload);
    } catch (error) {
        return MountException(error);
    }
}
