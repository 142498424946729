import type { FC, ReactNode } from "react";

import { classes } from "../../../utils/styles/tailwind/v4";

interface PopoverBodyProps {
    className?: string;
    children?: ReactNode;
    hideDividers?: boolean;
}

const PopoverBody: FC<PopoverBodyProps> = ({
    className,
    hideDividers,
    children,
}) => {
    return (
        <div
            className={classes`p-16 ${
                !hideDividers &&
                "border-y border-y-gray-200 first:border-t-0 last:border-b-0"
            } ${className}`}>
            {children}
        </div>
    );
};

export default PopoverBody;
