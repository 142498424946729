import type { Sku } from "./interface";

import type { RequestOptions } from "../interface";

import lambdas, { mountBaseUrl } from "../../configs/rest/lambdas";

export default async function getSku(
    skuId: string | number,
    { accountName, signal }: RequestOptions = {},
): Promise<Sku | null> {
    try {
        const { data } = await lambdas.get<Sku[]>(`/sku/${skuId}`, {
            baseURL: mountBaseUrl(accountName),
            signal,
        });
        if (data[0]?.IDSku) return data[0];
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}
