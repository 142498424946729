import type { ChangeEventHandler, FC, FormEventHandler } from "react";

import { useState, useTransition } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { indicators } from "./helpers";
import { PasswordResetFormNames } from "./interface";
import type { PasswordResetFormData } from "./interface";

import Button from "../../../components/@basic/Button";
import FormControl, {
    FormErrorMessage,
} from "../../../components/@basic/Forms/FormControl";
import ToggleableInput from "../../../components/@basic/Forms/ToggleableInput";
import { useToast } from "../../../components/@basic/Toast";

import DoladoLoginLayout from "../../../layouts/DoladoLogin";

import { routeNames } from "../../../router";

import { parseFormData } from "../../../utils/forms/parsers";
import { validateForm } from "../../../utils/forms/validation";
import type { ErrorRelation } from "../../../utils/forms/validation";

import { completePasswordPattern } from "../../../utils/inputs/patterns/password";

import ControllerApi from "../../../../controllers/ControllerApi";

const PasswordResetPage: FC = () => {
    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState<ErrorRelation<PasswordResetFormData>>(
        {},
    );
    const [password, setPassword] = useState("");

    const [, startTransition] = useTransition();

    const [search] = useSearchParams();

    const navigate = useNavigate();
    const toast = useToast();

    const handleChange: ChangeEventHandler<HTMLFormElement> = (event) => {
        if (event.target.name === PasswordResetFormNames.PASSWORD) {
            startTransition(() => {
                setPassword(event.target.value);
            });
        }
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        setErrors({});

        const errorRelation = await validateForm<PasswordResetFormData>(event);
        if (errorRelation) {
            setErrors(errorRelation);
            return;
        }

        const data = parseFormData<PasswordResetFormData>(event);

        setProcessing(true);

        const response = await ControllerApi(
            "POST",
            `/user/password-reset?token=${search.get("token") || ""}`,
            data,
        );

        if (response?.status === "success") {
            toast.dispatch({
                title: "Email de recuperação enviado.",
                status: "success",
                duration: 2000,
                position: "top",
            });
            setTimeout(navigate, 2000, routeNames.LOGIN);
        } else {
            toast.dispatch({
                title: response?.message,
                status: "error",
                duration: 5000,
                position: "top",
            });
        }

        setProcessing(false);
    };

    return (
        <DoladoLoginLayout message="Por favor, preencha as informações necessárias">
            <form
                noValidate
                className="flex flex-col justify-between w-full h-full"
                onChange={handleChange}
                onSubmit={handleSubmit}>
                <div>
                    <FormControl
                        isDisabled={processing}
                        isInvalid={!!errors.password}>
                        <ToggleableInput
                            className="bg-lightGrey-500 min-h-36"
                            required
                            name={PasswordResetFormNames.PASSWORD}
                            data-label="Senha"
                            placeholder="Senha"
                            pattern={completePasswordPattern}
                            minLength={8}
                        />
                        <FormErrorMessage className="mb-0">
                            {errors.password?.[0]}
                        </FormErrorMessage>
                    </FormControl>
                    <p className="mt-8 mb-6 ml-4">A senha deve:</p>
                    <ul className="mb-0 pl-24 list-disc">
                        {indicators.map(({ content, pattern }, idx) => (
                            <li
                                key={idx}
                                className={`${
                                    password.match(pattern)
                                        ? "text-green-600"
                                        : password
                                        ? "text-red-500"
                                        : "text-grey-700"
                                }`}>
                                {content}
                            </li>
                        ))}
                    </ul>
                    <FormControl
                        isDisabled={processing}
                        isInvalid={!!errors.confirmPassword}
                        className="mt-20">
                        <ToggleableInput
                            className="bg-lightGrey-500 min-h-36"
                            required
                            name={PasswordResetFormNames.CONFIRM_PASSWORD}
                            data-label="Confirmar senha"
                            data-compare={PasswordResetFormNames.PASSWORD}
                            placeholder="Confirmar senha"
                        />
                        <FormErrorMessage className="mb-0">
                            {errors.confirmPassword?.[0]}
                        </FormErrorMessage>
                    </FormControl>
                </div>

                <div className="mt-64 md:mt-40">
                    <Button
                        size="md"
                        type="submit"
                        className="md:inline-block max-md:w-full md:min-w-136 md:mr-12 md:align-middle"
                        isDisabled={processing}
                        isLoading={processing}>
                        Redefinir Senha
                    </Button>
                    <p className="md:inline-block max-md:mt-12 mb-0 max-md:leading-8 max-md:text-center md:align-middle">
                        <span className="md:mr-12">ou</span>
                        <Link
                            to={routeNames.LOGIN}
                            className="max-md:block hover:underline hover:underline-offset-2">
                            Logar
                        </Link>
                    </p>
                </div>
            </form>
        </DoladoLoginLayout>
    );
};

export default PasswordResetPage;
