import type {
    FetchIntegrationInstanceDataFunction,
    IntegrationInstanceData,
} from "./interface";

import type { SelectOption } from "../../../../../components/@basic/Forms/Select/Options/interface";

import getCompanyIntegrationParams from "../../../../../../services/companies/integrations/integration/getParams";
import type { CompanyIntegrationParameter } from "../../../../../../services/companies/integrations/integration/interface";
import getHubResourcesInfo from "../../../../../../services/hub/resources/getInfo";
import getHubOfficialStores from "../../../../../../services/hub/miscellaneous/getOfficialStores";
import getHubQuestionAnswerTemplates from "../../../../../../services/hub/miscellaneous/getQuestionAnswerTemplates";
import getHubShippingFree from "../../../../../../services/hub/miscellaneous/getShippingFree";
import getHubShippingModes from "../../../../../../services/hub/miscellaneous/getShippingMode";
import getHubSkuConditions from "../../../../../../services/hub/miscellaneous/getSkuCondition";
import getHubWarrantyTypes from "../../../../../../services/hub/miscellaneous/getWarrantyTypes";

export const fetchOfficialStore: FetchIntegrationInstanceDataFunction = async (
    { integration: { IDCompanyIntegration, IDTypeCompanyIntegration } },
    { signal },
) => {
    const [resource] = await getHubResourcesInfo(
        IDTypeCompanyIntegration,
        { ResourceType: "OfficialStore" },
        { signal },
    );

    if (!resource?.IDTypeSalesChannelResource) return {};

    const stories = await getHubOfficialStores(
        IDTypeCompanyIntegration,
        {
            IDCompanyIntegration,
            IDTypeSalesChannelResource: resource.IDTypeSalesChannelResource,
        },
        { signal },
    );

    return {
        officialStore: stories.map(({ label, value }) => ({ value, label })),
    };
};

export const fetchSkuCondition: FetchIntegrationInstanceDataFunction = async (
    { integration: { IDTypeCompanyIntegration }, typeData: { category } },
    { signal },
) => {
    if (!category?.IDCategoryFrom) return {};

    const [resource] = await getHubResourcesInfo(
        IDTypeCompanyIntegration,
        { ResourceType: "SkuCondition" },
        { signal },
    );

    if (!resource?.IDTypeSalesChannelResource) return {};

    const conditions = await getHubSkuConditions(
        IDTypeCompanyIntegration,
        {
            CategoryId: category.IDCategoryFrom,
            IDTypeSalesChannelResource: resource.IDTypeSalesChannelResource,
        },
        { signal },
    );

    return {
        productCondition: conditions.map(({ label, value }) => ({
            value,
            label,
        })),
    };
};

export const fetchShippingModes: FetchIntegrationInstanceDataFunction = async (
    { integration: { IDCompanyIntegration, IDTypeCompanyIntegration } },
    { signal },
) => {
    const [resource] = await getHubResourcesInfo(
        IDTypeCompanyIntegration,
        { ResourceType: "ModeShipping" },
        { signal },
    );

    if (!resource?.IDTypeSalesChannelResource) return {};

    const conditions = await getHubShippingModes(
        IDTypeCompanyIntegration,
        {
            IDCompanyIntegration,
            IDTypeSalesChannelResource: resource.IDTypeSalesChannelResource,
        },
        { signal },
    );

    return {
        shippingMode: conditions.map(({ label, value }) => ({
            value,
            label,
        })),
    };
};

export const fetchShippingFree: FetchIntegrationInstanceDataFunction =
    async () => {
        const response = await getHubShippingFree();
        return {
            shippingFree: response.map(({ label, value }) => ({
                label,
                value,
            })),
        };
    };

export const fetchQuestionAnswerTemplates: FetchIntegrationInstanceDataFunction =
    async () => {
        const response = await getHubQuestionAnswerTemplates({ AnswerType: 2 });
        return {
            offerQuestionAnswerTemplates: response.map(
                ({
                    AnswerTemplate,
                    AnswerTitle,
                    IDHubProductQuestionAnswerTemplate,
                }) => ({
                    AnswerTemplate,
                    AnswerTitle,
                    IDHubProductQuestionAnswerTemplate,
                }),
            ),
        };
    };

export const fetchWarrantyTypes: FetchIntegrationInstanceDataFunction =
    async () => {
        const response = await getHubWarrantyTypes();
        return {
            warrantyType: response.map(({ label, value }) => ({
                label,
                value,
            })),
        };
    };

export const fetchIntegrationParams: FetchIntegrationInstanceDataFunction =
    async (
        { instanceData, integration: { AccountName, IDCompanyIntegration } },
        { signal },
    ) => {
        const parameters = await getCompanyIntegrationParams(
            IDCompanyIntegration,
            {
                accountName: AccountName,
                signal,
            },
        );

        const newData = { ...instanceData };

        const parseOptions = (list: CompanyIntegrationParameter["List"]) =>
            list.map<SelectOption>(({ Label, Value }) => ({
                value: Value,
                label: Label,
            }));

        const offerType = parameters.find(
            (param) => param.Key === "MeliDefaultListingTypeId",
        );
        if (offerType?.List.length) {
            newData.offerType = parseOptions(offerType.List).sort((a) =>
                a.value == 0 ? -1 : 1,
            );
        }

        const shippingFree = parameters.find(
            (param) => param.Key === "MeliDefaultShippingFree",
        );
        if (shippingFree?.List.length) {
            newData.shippingFree = parseOptions(shippingFree.List).reverse();
        }

        const shippingLocalPickUp = parameters.find(
            (param) => param.Key === "MeliDefaultShippingLocalPickUp",
        );
        if (shippingLocalPickUp?.List.length) {
            newData.shippingLocalPickUp = parseOptions(
                shippingLocalPickUp.List,
            ).sort((a) => (a.value == 0 ? -1 : 1));
        }

        const shippingMode = parameters.find(
            (param) => param.Key === "MeliDefaultShippingMode",
        );
        if (shippingMode?.List.length) {
            newData.shippingMode = parseOptions(shippingMode.List).sort((a) =>
                a.value === "me2" ? -1 : 1,
            );
        }

        const warrantyType = parameters.find(
            (param) => param.Key === "MeliDefaultWarrantyType",
        );
        if (warrantyType?.List.length) {
            newData.warrantyType = parseOptions(warrantyType.List).sort((a) =>
                a.value === "Garantia do Vendedor" ? -1 : 1,
            );
        }

        return newData;
    };

export function parseInstanceDefaultValues<T>(
    instance: IntegrationInstanceData | null,
    input: T | undefined,
    fallback: Exclude<
        keyof IntegrationInstanceData,
        "status" | "offerQuestionAnswerTemplates"
    >,
) {
    if (typeof input !== "undefined") return input;
    const data = instance?.[fallback];
    if (!data?.length) return null;
    const option = data[0];
    return option.value || "";
}
