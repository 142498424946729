import type { SkuVariant } from "./interface";

import type { RequestOptions } from "../interface";

import lambdas, { mountBaseUrl } from "../../configs/rest/lambdas";

export default async function getSkuVariants(
    skuId: string | number,
    { accountName, signal }: RequestOptions = {},
): Promise<SkuVariant[]> {
    try {
        const { data } = await lambdas.get<SkuVariant[]>(
            `/sku/variation/${skuId}`,
            {
                baseURL: mountBaseUrl(accountName),
                signal,
            },
        );
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
