import type { FC, FormEventHandler } from "react";

import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { SignInFormNames } from "./interface";
import type { SignInFormData } from "./interface";

import Button from "../../components/@basic/Button";
import FormControl, {
    FormErrorMessage,
} from "../../components/@basic/Forms/FormControl";
import Input from "../../components/@basic/Forms/Input";
import ToggleableInput from "../../components/@basic/Forms/ToggleableInput";
import { useToast } from "../../components/@basic/Toast";

import { useSession } from "../../contexts/session";

import DoladoLoginLayout from "../../layouts/DoladoLogin";

import { routeNames } from "../../router";

import { parseFormData } from "../../utils/forms/parsers";
import { validateForm } from "../../utils/forms/validation";
import type { ErrorRelation } from "../../utils/forms/validation";

const SignInPage: FC = () => {
    const [errors, setErrors] = useState<ErrorRelation<SignInFormData>>({});

    const { hydrated, processing, error, login } = useSession();
    const canThrow = useRef(false);

    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        if (canThrow.current && error) {
            canThrow.current = false;
            toast.dispatch({
                title: error,
                status: "error",
                duration: 5000,
                position: "top",
            });
        }
    }, [error, toast]);

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        setErrors({});

        const errorRelation = await validateForm<SignInFormData>(event);
        if (errorRelation) {
            setErrors(errorRelation);
            return;
        }

        const data = parseFormData<SignInFormData>(event);

        canThrow.current = true;

        login(data, () => {
            navigate(routeNames.APP_HOME);
        });
    };

    return (
        <DoladoLoginLayout message="Use seu e-mail e senha para Logar">
            <form
                noValidate
                className="flex flex-col justify-between w-full h-full"
                onSubmit={handleSubmit}>
                <div>
                    <FormControl
                        isDisabled={!hydrated || processing}
                        isInvalid={!!errors.email}>
                        <Input
                            className="bg-lightGrey-500 min-h-36"
                            required
                            type="email"
                            name={SignInFormNames.EMAIL}
                            data-label="E-mail"
                            placeholder="E-mail"
                        />
                        <FormErrorMessage>{errors.email?.[0]}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                        isDisabled={!hydrated || processing}
                        isInvalid={!!errors.password}
                        className="mt-20">
                        <ToggleableInput
                            className="bg-lightGrey-500 min-h-36"
                            required
                            name={SignInFormNames.PASSWORD}
                            data-label="Senha"
                            placeholder="Senha"
                        />
                        <FormErrorMessage>
                            {errors.password?.[0]}
                        </FormErrorMessage>
                    </FormControl>
                </div>

                <div className="mt-64 md:mt-40">
                    <Button
                        size="md"
                        type="submit"
                        className="max-md:w-full md:min-w-72"
                        isDisabled={!hydrated || processing}
                        isLoading={processing}>
                        Logar
                    </Button>
                    <p className="mt-12 max-md:leading-8 max-md:text-center">
                        <span>ou </span>
                        <Link
                            to={routeNames.PASSWORD_FORGOT}
                            className="max-md:block hover:underline hover:underline-offset-2">
                            Esqueci minha senha
                        </Link>
                    </p>
                </div>
            </form>
        </DoladoLoginLayout>
    );
};

export default SignInPage;
