import { createMask, createMaskHandler } from ".";
import type { MaskParams } from "./interface";

export const creditCardDefaultMaskParams: MaskParams = {
    pattern: /\d{1,4}/g,
    fillers: " ",
    maxLength: 19,
};

export const creditCardDefaultMask = createMask(creditCardDefaultMaskParams);
export const creditCardDefaultMaskHandler = createMaskHandler(
    creditCardDefaultMask,
);

export const creditCardAmexMaskParams: MaskParams = {
    pattern: /(\d{1,4}) ?(\d{0,6}) ?(\d{0,5})/,
    fillers: " ",
};

export const creditCardAmexMask = createMask(creditCardAmexMaskParams);
export const creditCardAmexMaskHandler = createMaskHandler(creditCardAmexMask);

export function creditCardMask(str: string) {
    const match = str.match(/\d/g);
    let newValue = "";
    if (match) {
        const temp = match.join("");
        newValue =
            temp.length === 15
                ? creditCardAmexMask(temp)
                : creditCardDefaultMask(temp);
    }
    return newValue;
}
export const creditCardMaskHandler = createMaskHandler(creditCardMask);
