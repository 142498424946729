import type { MenuGroup } from "./interface";

import type { RequestOptions } from "../interface";

import lambdas from "../../configs/rest/lambdas";

export default async function getMenu({ signal }: RequestOptions = {}): Promise<
    MenuGroup[]
> {
    try {
        const { data } = await lambdas.get<MenuGroup[]>("/user/menu", {
            signal,
        });
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
