import type { ComponentProps, FC, ReactNode } from "react";

import { useInputOverwrite } from "../utils";

import { classes } from "../../../../utils/styles/tailwind/v3";

interface FormLabelProps extends ComponentProps<"label"> {
    children?: ReactNode;
}

const FormLabel: FC<FormLabelProps> = ({
    className = "",
    children,
    ...props
}) => {
    const { id, isRequired } = useInputOverwrite({});
    return (
        <label
            className={classes`mb-8 text-sm text-grey ${
                isRequired
                    ? "after:content-['*'] after:ml-4 after:text-red"
                    : ""
            } ${className}`}
            htmlFor={id}
            {...props}>
            {children}
        </label>
    );
};

export default FormLabel;
