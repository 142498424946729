import franchise from "../../configs/rest/franchise";

interface CheckIfAccountExistsResponse {
    account: boolean;
    accountPayload: boolean;
}

export default async function checkIfAccountExists(
    accountName: string,
): Promise<CheckIfAccountExistsResponse> {
    try {
        const { data } = await franchise.get<CheckIfAccountExistsResponse>(
            `/v1/accounts/account-already-exists/${accountName}`,
        );
        return data;
    } catch {
        return { account: false, accountPayload: false };
    }
}
