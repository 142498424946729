import { useEffect, useState } from "react";

import { breakpointQueries } from "./interface";

export default function useIsMobile(): boolean | undefined {
    const [isMobile, setIsMobile] = useState<boolean>();

    useEffect(() => {
        const handle = (event: MediaQueryListEvent) =>
            setIsMobile(!event.matches);
        const mql = window.matchMedia(breakpointQueries.md);
        mql.addEventListener("change", handle);
        setIsMobile(!mql.matches);
        return () => {
            mql.removeEventListener("change", handle);
        };
    }, []);

    return isMobile;
}
