import type { ComponentProps, ReactNode } from "react";

import { forwardRef, useMemo } from "react";

import {
    buttonCommonStyle,
    buttonIconsSizes,
    buttonSizes,
    buttonVariants,
} from "./interface";
import type { ButtonSizes, ButtonVariants } from "./interface";

import Icon from "../Icons";
import type { IconInfo, IconNames } from "../Icons/interface";
import { SpinnerIcon } from "../Icons/Mocks/spinner";

import { classes } from "../../../utils/styles/tailwind/v4";

export interface ButtonProps extends ComponentProps<"button"> {
    variant?: ButtonVariants;
    size?: ButtonSizes;
    isFullWidth?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    leftIcon?: IconNames | IconInfo;
    rightIcon?: IconNames | IconInfo;
    iconStyle?: string;
    textStyle?: string;
    children?: ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    {
        variant = "solid",
        size = "lg",
        isFullWidth,
        isLoading,
        isDisabled,
        className,
        leftIcon,
        rightIcon,
        iconStyle,
        textStyle,
        children,
        ...rest
    },
    ref,
) {
    const renderIcon = (
        icon: IconNames | IconInfo | undefined,
        side: "left" | "right",
    ) => {
        if (!icon) return <div />;
        return (
            <Icon
                title={rest.title}
                icon={typeof icon === "string" ? icon : undefined}
                info={typeof icon === "object" ? icon : undefined}
                className={`${side === "left" ? "mr-8" : "ml-8"} ${
                    buttonIconsSizes[size]
                } ${iconStyle}`}
            />
        );
    };

    const currentStyle = useMemo(
        () =>
            classes`${variant !== "unstyled" && buttonCommonStyle} ${
                buttonSizes[size]
            } ${buttonVariants[variant]} ${
                isFullWidth && "w-full"
            } ${className}`,
        [variant, size, isFullWidth, className],
    );

    return (
        <button
            ref={ref}
            className={currentStyle}
            disabled={isDisabled}
            {...rest}>
            {isLoading ? (
                <SpinnerIcon
                    title={rest.title}
                    className={`${buttonIconsSizes[size]} mx-auto aspect-square animate-spin`}
                />
            ) : (
                <>
                    {renderIcon(leftIcon, "left")}
                    <div className={classes`flex-1 h-max ${textStyle}`}>
                        {children}
                    </div>
                    {renderIcon(rightIcon, "right")}
                </>
            )}
        </button>
    );
});

export default Button;
