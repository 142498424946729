import type { FilterState } from "../../../plugins/filter-manager/interface";
import type { PaginationState } from "../../../plugins/pagination-manager/interface";

import type { PersistentState } from "../../../utils/interface";

import type {
    FilterDrawerExtraData,
    FilterDrawerGroupExtraData,
} from "../../../../components/FilterDrawer/interface";

import { AccountPayloadWithAudits } from "../../../../services/accounts/payloads/interface";

export type State = PersistentState<{
    payloads: PaginationState<AccountPayloadWithAudits>;
    filters: FilterState<FilterDrawerExtraData, FilterDrawerGroupExtraData>;
}>;

export const moduleId = "accountPayloads" as const;
