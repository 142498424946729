import type { ComponentProps, ReactNode } from "react";

import { forwardRef, useMemo } from "react";

import { useInputOverwrite } from "../utils";
import {
    selectCommonStyle,
    selectInvalidStyle,
    selectReadOnlyStyle,
    selectSizes,
    selectVariants,
} from "./interface";

import type { InputSizes, InputVariants } from "../utils";

import { classes } from "../../../../utils/styles/tailwind/v4";

export interface SelectProps extends Omit<ComponentProps<"select">, "size"> {
    variant?: InputVariants;
    size?: InputSizes;
    isDisabled?: boolean;
    isInvalid?: boolean;
    isReadOnly?: boolean;
    children?: ReactNode;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(
    props,
    ref,
) {
    const {
        id,
        isRequired,
        isDisabled,
        isInvalid,
        isReadOnly,
        name,
        size = "md",
        variant = "primary",
        className = "",
        style,
        children,
        ...rest
    } = useInputOverwrite(props);

    const currentStyle = useMemo(
        () =>
            classes`${variant !== "unstyled" ? selectCommonStyle : ""} ${
                selectSizes[size]
            } ${selectVariants[variant]} ${className} ${
                isInvalid ? selectInvalidStyle : ""
            } ${isReadOnly ? selectReadOnlyStyle : ""}`,
        [variant, size, className, isInvalid, isReadOnly],
    );

    return (
        <select
            ref={ref}
            className={currentStyle}
            id={id}
            name={name}
            required={isRequired}
            disabled={isDisabled}
            tabIndex={isReadOnly ? -1 : undefined}
            aria-required={isRequired}
            aria-disabled={isDisabled}
            aria-invalid={isInvalid}
            style={{
                ...style,
                backgroundImage:
                    variant !== "unstyled"
                        ? "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjUiIHZpZXdCb3g9IjAgMCA4IDUiIGZpbGw9Im5vbmUiPjxwYXRoIGQ9Ik0wLjE2NzM2OCAwLjIxNzI0MUMwLjM5MDUyNCAtMC4wMTA1NjQ4IDAuNzUyMzMzIC0wLjAxMDU2NDggMC45NzU0OSAwLjIxNzI0MUw0IDMuMzA0NzZMNy4wMjQ1MSAwLjIxNzI0MUM3LjI0NzY3IC0wLjAxMDU2NDggNy42MDk0OCAtMC4wMTA1NjQ4IDcuODMyNjMgMC4yMTcyNDFDOC4wNTU3OSAwLjQ0NTA0NyA4LjA1NTc5IDAuODE0Mzk0IDcuODMyNjMgMS4wNDIyTDQuNDA0MDYgNC41NDIyQzQuMTgwOSA0Ljc3MDAxIDMuODE5MSA0Ljc3MDAxIDMuNTk1OTQgNC41NDIyTDAuMTY3MzY4IDEuMDQyMkMtMC4wNTU3ODkyIDAuODE0Mzk0IC0wLjA1NTc4OTIgMC40NDUwNDcgMC4xNjczNjggMC4yMTcyNDFaIiBmaWxsPSIjMkQzNzQ4Ii8+PC9zdmc+')"
                        : "",
            }}
            {...rest}>
            {children}
        </select>
    );
});

export default Select;
