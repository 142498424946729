import type { FC, FormEventHandler } from "react";

import { useState } from "react";

import BillingAddressForm from "./BillingAddressForm";
import CreditCardForm from "./CreditCardForm";
import PaymentMethodItem from "./PaymentMethodItem";

import { Section, SectionTitle } from "../../styles";
import { useSignUpContext } from "../../helpers";
import { SignUpSteps } from "../../interface";
import type { SignUpFormData } from "../../interface";

import Alert from "../../../../components/@basic/Alert";
import Button from "../../../../components/@basic/Button";
import {
    ArrowRightIcon,
    CreditCardsBoldIconInfo,
    LocationBoldIconInfo,
} from "../../../../components/@basic/Icons/Mocks";

import { PaymentMethodTypes } from "../../../../services/miscellaneous/getPaymentMethods";

import { parseFormData } from "../../../../utils/forms/parsers";
import { validateForm } from "../../../../utils/forms/validation";
import type { ErrorRelation } from "../../../../utils/forms/validation";

import formatMoney from "../../../../utils/formatters/formatMoney";

const PaymentInfoStep: FC = () => {
    const {
        currentStep,
        data,
        processing,
        loading,
        consultantData,
        paymentMethods,
        updateData,
        submit,
    } = useSignUpContext();

    const [errors, setErrors] = useState<
        ErrorRelation<SignUpFormData[SignUpSteps.PAYMENT]>
    >({});
    const [paymentMethod, setPaymentMethod] = useState<string | null>(
        data.current[SignUpSteps.PAYMENT]?.paymentMethod || null,
    );

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        setErrors({});

        const errorRelation = await validateForm<
            SignUpFormData[SignUpSteps.PAYMENT]
        >(event);
        if (errorRelation) {
            setErrors(errorRelation);
            return;
        }

        const data = parseFormData<SignUpFormData[SignUpSteps.PAYMENT]>(event);

        updateData(SignUpSteps.PAYMENT, data);

        submit();
    };

    if (currentStep !== SignUpSteps.PAYMENT) return null;

    const currentMethod = paymentMethods.find(
        ({ _id }) => _id === paymentMethod,
    );

    const subtotal = consultantData?.subscription_value || 0;
    const total = subtotal;

    return (
        <form
            noValidate
            className="flex flex-col gap-24 md:gap-32 md:min-h-360"
            onSubmit={handleSubmit}>
            <Section className="space-y-32">
                <SectionTitle
                    icon={CreditCardsBoldIconInfo}
                    title="Forma de pagamento"
                />
                <div className="flex gap-8 md:gap-12">
                    {loading && (
                        <>
                            <div className="bg-grey-200 w-76 h-72 rounded-12 animate-pulse" />
                            <div className="bg-grey-200 w-76 h-72 rounded-12 animate-pulse" />
                            <div className="bg-grey-200 w-76 h-72 rounded-12 animate-pulse" />
                        </>
                    )}
                    {!loading &&
                        paymentMethods.map((method, idx) => (
                            <PaymentMethodItem
                                key={idx}
                                method={method}
                                selected={method._id === paymentMethod}
                                isDisabled={processing}
                                onChange={setPaymentMethod}
                            />
                        ))}
                </div>
                {currentMethod?.asaasType ===
                    PaymentMethodTypes.CREDIT_CARD && (
                    <>
                        <CreditCardForm
                            data={data.current[SignUpSteps.PAYMENT]}
                            errors={errors}
                            isDisabled={processing}
                            method={currentMethod}
                            totalPrice={consultantData?.subscription_value}
                        />
                        <SectionTitle
                            icon={LocationBoldIconInfo}
                            title="Endereço de cobrança"
                        />
                        <BillingAddressForm
                            data={data.current[SignUpSteps.PAYMENT]}
                            errors={errors}
                            isDisabled={processing}
                        />
                    </>
                )}
            </Section>
            <Section className="flex-1 flex flex-col md:flex-row flex-wrap md:items-end gap-16 md:gap-32">
                <div className="flex-1">
                    {currentMethod?.asaasType === PaymentMethodTypes.PIX && (
                        <Alert
                            variant="info"
                            contentStyle="text-sm text-neutral-500">
                            <div className="flex gap-4">
                                <ArrowRightIcon className="w-16 min-w-16 h-20 text-inherit" />
                                <p>
                                    Forma de pagamento disponível apenas para
                                    pagamento à vista
                                </p>
                            </div>
                            <div className="flex gap-4">
                                <ArrowRightIcon className="w-16 min-w-16 h-20 text-inherit" />
                                <p>
                                    Após o pagamento, verifique seu email,
                                    inclusive a caixa anti-spam
                                </p>
                            </div>
                            <div className="flex gap-4">
                                <ArrowRightIcon className="w-16 min-w-16 h-20 text-inherit" />
                                <p className="mb-0">
                                    O Pix foi desenvolvido pelo Banco Central
                                    para facilitar suas compras, garantindo a
                                    proteção dos seus dados
                                </p>
                            </div>
                        </Alert>
                    )}
                </div>
                <div className="flex-1 flex flex-col justify-end">
                    <div className="flex justify-between text-base/6">
                        <h4 className="mb-0">Subtotal</h4>
                        <p className="mb-0">{formatMoney(subtotal)}</p>
                    </div>
                    <div className="flex justify-between mt-8 text-xl/6 font-bold">
                        <h3 className="mb-0 font-bold">Total</h3>
                        <p className="mb-0">{formatMoney(total)}</p>
                    </div>
                    <Button
                        type="submit"
                        isFullWidth
                        className="mt-24"
                        isDisabled={loading || processing || !paymentMethod}
                        isLoading={processing}>
                        {currentMethod?.asaasType === PaymentMethodTypes.PIX
                            ? "Gerar Pix"
                            : "Finalizar compra"}
                    </Button>
                </div>
            </Section>
        </form>
    );
};

export default PaymentInfoStep;
