import { useMemo } from "react";

import { formatDateForHtml } from "../../../../utils/date";

export function useHTMLDate(
    value?: string | number | Date | (string | number | Date | undefined)[],
): string | (string | undefined)[] | undefined {
    return useMemo(() => {
        if (Array.isArray(value)) {
            return value.map(formatDateForHtml);
        }
        return formatDateForHtml(value);
    }, [value]);
}
