import type { SetStateAction } from "react";

import { createContext } from "react";

import type { HubNotification } from "../../../services/hub/notifications/interface";

export interface NotificationManagerContext {
    deleteEntries: (ids?: (string | number)[]) => void;
    hydrated: boolean;
    loading: boolean;
    notifications: HubNotification[];
    processing: boolean;
    refresh: () => void;
    update: (newData: SetStateAction<HubNotification[]>) => void;
}

export const NotificationManagerContext =
    createContext<NotificationManagerContext>({
        deleteEntries: () => null,
        hydrated: false,
        loading: false,
        notifications: [],
        processing: false,
        refresh: () => null,
        update: () => null,
    });
