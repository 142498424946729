import type { FC, ReactNode } from "react";

import Icon from "../../components/@basic/Icons";
import type { IconInfo } from "../../components/@basic/Icons/interface";

interface SectionProps {
    children?: ReactNode;
    className?: string;
}

export const Section: FC<SectionProps> = ({ className = "", children }) => (
    <section className={`md:px-24 ${className}`}>{children}</section>
);

interface SectionTitleProps {
    icon: IconInfo;
    title: string;
}

export const SectionTitle: FC<SectionTitleProps> = ({ icon, title }) => (
    <div className="flex items-center gap-8 mb-24 md:mb-32">
        <Icon
            info={icon}
            className="bg-doladoBlue-50 w-28 md:w-32 h-28 md:h-32 rounded-full p-6 md:p-8 text-doladoBlue-700"
        />
        <h2 className="mb-0 text-base sm:text-lg md:text-xl font-bold text-grey-900">
            {title}
        </h2>
    </div>
);

interface FooterProps {
    children?: ReactNode;
}

export const Footer: FC<FooterProps> = ({ children }) => (
    <footer className="flex w-full md:max-w-max mt-24 ml-auto md:px-24">
        {children}
    </footer>
);
