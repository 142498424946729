import type { FC, ReactNode } from "react";

import { Suspense, useCallback, useMemo, useRef, useState } from "react";

import { commonModals } from "./relations";
import { ModalManagerContext } from "./interface";
import type {
    ModalManagerComponent,
    ModalManagerInjectedProps,
    StackItem,
} from "./interface";

import createUUID from "../../utils/cryptography/createUUID";

interface ModalManagerProviderProps {
    children?: ReactNode;
}

const ModalManagerProvider: FC<ModalManagerProviderProps> = ({ children }) => {
    const [pool, setPool] = useState<Map<string, ModalManagerComponent<any>>>(
        new Map(Object.entries(commonModals)),
    );
    const poolRef = useRef(pool);
    const [stack, setStack] = useState<StackItem[]>([]);
    const stackRef = useRef(stack);

    const appendPool = useCallback<ModalManagerContext["appendPool"]>(
        (tag, Component) => {
            if (poolRef.current.has(tag)) return;
            poolRef.current = new Map(poolRef.current);
            poolRef.current.set(tag, Component);
            setPool(poolRef.current);
        },
        [],
    );

    const removePool = useCallback<ModalManagerContext["removePool"]>((tag) => {
        if (!poolRef.current.has(tag)) return;

        poolRef.current = new Map(poolRef.current);
        poolRef.current.delete(tag);
        setPool(poolRef.current);

        stackRef.current = stackRef.current.filter((item) => item.tag !== tag);
        setStack(stackRef.current);
    }, []);

    const clear = useCallback<ModalManagerContext["clear"]>((keys) => {
        if (!keys) {
            stackRef.current = [];
        } else {
            stackRef.current = stackRef.current.filter(
                ({ key }) => !keys.includes(key),
            );
        }
        setStack(stackRef.current);
    }, []);

    const push = useCallback<ModalManagerContext["push"]>((tag, props) => {
        if (!poolRef.current.has(tag)) return ["", () => null];

        const key = createUUID();
        stackRef.current = [...stackRef.current, { key, tag, props }];
        setStack(stackRef.current);

        const clearItem = () => {
            stackRef.current = stackRef.current.filter(
                (item) => item.key !== key,
            );
            setStack(stackRef.current);
        };

        return [key, clearItem];
    }, []);

    const pop = useCallback<ModalManagerContext["pop"]>(() => {
        stackRef.current = stackRef.current.slice(0, -1);
        setStack(stackRef.current);
    }, []);

    const set = useCallback<ModalManagerContext["set"]>((entries) => {
        stackRef.current = entries
            .filter(({ tag }) => poolRef.current.has(tag))
            .map(({ tag, props }) => ({ key: createUUID(), tag, props }));
        setStack(stackRef.current);
    }, []);

    const state = useMemo<ModalManagerContext>(
        () => ({
            appendPool,
            clear,
            current: stack.slice(-1)[0]?.key || null,
            pop,
            push,
            removePool,
            set,
            stack,
        }),
        [appendPool, clear, removePool, pop, push, set, stack],
    );

    return (
        <ModalManagerContext.Provider value={state}>
            {children}
            {stack.map(({ key, tag, props }, idx) => {
                const Component = pool.get(tag);
                if (!Component) return null;

                const injected: ModalManagerInjectedProps["_modalManager"] = {
                    clear,
                    pop,
                    push,
                    set,
                    close: () => clear([key]),
                    itemKey: key,
                    zIndex: idx * 10,
                };
                return (
                    <Suspense key={key} fallback={null}>
                        <Component _modalManager={injected} {...props} />
                    </Suspense>
                );
            })}
        </ModalManagerContext.Provider>
    );
};

export default ModalManagerProvider;

export * from "./utils";
