import type { ChangeEventHandler } from "react";

import type { MaskParams } from "./interface";

import trimEnd from "../../arrays/trimEnd";

export function createMask(params: MaskParams): (str: string) => string {
    const {
        pattern,
        fillers = "",
        maxMatchCount = Infinity,
        maxLength = Infinity,
    } = params;

    let getFiller: (idx: number) => string;
    if (typeof fillers === "string") {
        getFiller = (idx) => (idx != 0 ? fillers : "");
    } else if (Array.isArray(fillers)) {
        getFiller = (idx) => fillers[(idx - 1) % fillers.length] || "";
    } else {
        getFiller = (idx) => fillers[idx % maxMatchCount] || "";
    }

    if (pattern.global) {
        return (str) => {
            const match = str.match(pattern);
            let newValue = "";
            if (match) {
                newValue = match
                    .slice(0, maxMatchCount)
                    .map((el, idx) => `${getFiller(idx)}${el}`)
                    .join("")
                    .slice(0, maxLength);
            }
            return newValue;
        };
    }

    return (str) => {
        const match = str.match(pattern);
        let newValue = "";
        if (match) {
            if (match.length === 1) {
                newValue = match[0].slice(0, maxLength);
            } else {
                newValue = trimEnd(match.slice(1))
                    .map((el, idx) => `${getFiller(idx)}${el}`)
                    .join("")
                    .slice(0, maxLength);
            }
        }
        return newValue;
    };
}

export function createMaskHandler(
    mask: (vl: string) => string,
): ChangeEventHandler<HTMLInputElement> {
    return (event) => {
        event.persist();
        const newValue = mask(event.target.value);
        const cursor = event.target.selectionStart ?? 0;
        const newCursor = mask(event.target.value.slice(0, cursor)).length;
        event.target.value = newValue;
        event.target.selectionStart = newCursor;
        event.target.selectionEnd = newCursor;
    };
}
