import type { FC } from "react";

import { useMemo } from "react";

import DayElement from "./DayElement";

import { getClearDate } from "./helpers";

import { getDaysInMonth, getDaysOfWeekNames } from "../../../../utils/date";

interface MonthLayoutProps {
    end?: Date;
    isDraft?: boolean;
    isFuture?: boolean;
    isPast?: boolean;
    locale?: string;
    maxDate?: Date;
    minDate?: Date;
    pivot: Date;
    start?: Date;
    onClick?: (date: Date) => void;
    onMouseEnter?: (value: Date) => void;
    onMouseLeave?: (value: Date) => void;
}

const MonthLayout: FC<MonthLayoutProps> = ({
    pivot,
    start,
    end,
    maxDate,
    minDate,
    isDraft,
    isFuture,
    isPast,
    locale = "pt-br",
    onClick,
    onMouseEnter,
    onMouseLeave,
}) => {
    const weekDays = useMemo(() => getDaysOfWeekNames(locale), [locale]);

    const [daysInMonth, days] = useMemo(() => {
        const ty = pivot.getFullYear();
        const tm = pivot.getMonth();

        const daysInMonth = getDaysInMonth(ty, tm);
        const offset = new Date(ty, tm, 1).getDay();

        const days: [index: number, value: Date][] = [];
        for (let i = 0; i < 42; i++) {
            const index = i - offset;
            days.push([index, new Date(ty, tm, index + 1)]);
        }

        return [daysInMonth, days];
    }, [pivot]);

    const [st, et] = useMemo(() => {
        return [
            start && getClearDate(start).getTime(),
            end && getClearDate(end).getTime(),
        ];
    }, [start, end]);

    const [min, max] = useMemo(() => {
        return [
            minDate && getClearDate(minDate).getTime(),
            maxDate && getClearDate(maxDate).getTime(),
        ];
    }, [minDate, maxDate]);

    const nt = getClearDate(new Date()).getTime();

    return (
        <div className="grid grid-cols-7 gap-y-8">
            {weekDays.map((day, idx) => (
                <div
                    key={`week-${idx}`}
                    className="relative w-36 min-w-36 h-28 bg-white text-grey-500">
                    <p className="absolute top-0 left-1/2 -translate-x-1/2 w-28 min-w-28 h-28 leading-7 text-center">
                        {day}
                    </p>
                </div>
            ))}
            {days.map(([index, date], idx) => {
                const t = date.getTime();
                return (
                    <DayElement
                        key={`day-${idx}`}
                        value={date}
                        connectLeft={!!st && !!et && t > st && t <= et}
                        connectRight={!!st && !!et && t >= st && t < et}
                        isDisabled={
                            index < 0 ||
                            index > daysInMonth - 1 ||
                            (isFuture && t < nt) ||
                            (isPast && t > nt) ||
                            (!!min && t < min) ||
                            (!!max && t > max)
                        }
                        isCurrent={t === nt}
                        isPreview={!!st && !!et && t >= st && t <= et}
                        isSelected={t === st || (!isDraft && t === et)}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    />
                );
            })}
        </div>
    );
};

export default MonthLayout;
