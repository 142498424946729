import type { ChangeEventHandler } from "react";

import { createMask, createMaskHandler } from ".";
import type { MaskParams } from "./interface";

type PhoneFormats = "brazil" | "brazilCompact";

export const phoneBrazilMaskParams: MaskParams = {
    pattern: /(?:\+55 ?)?(\d{0,2}) ?(\d{0,5})-?(\d{0,4})/,
    fillers: { 0: "+55 ", 1: " ", 2: "-" },
};

export const phoneBrazilCompactMaskParams: MaskParams = {
    pattern: /\+?(\d{0,2}) ?(\d{0,5})-?(\d{0,4})/,
    fillers: { 1: " ", 2: "-" },
};

export const phoneMasks: Record<PhoneFormats, (str: string) => string> = {
    brazil: createMask(phoneBrazilMaskParams),
    brazilCompact: createMask(phoneBrazilCompactMaskParams),
};

export const phoneMaskHandlers: Record<
    PhoneFormats,
    ChangeEventHandler<HTMLInputElement>
> = {
    brazil: createMaskHandler(phoneMasks.brazil),
    brazilCompact: createMaskHandler(phoneMasks.brazilCompact),
};
