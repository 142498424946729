export function getClearDate(value: Date): Date {
    return new Date(value.getFullYear(), value.getMonth(), value.getDate());
}

export function formatDateLabel(
    locale = "pt-br",
    value: Date,
    format: "full" | "year" | "month" = "full",
): string {
    const label = [];
    if (["full", "month"].includes(format)) {
        const month = value.toLocaleString(locale, {
            month: "long",
        });
        label.push(month.slice(0, 1).toLocaleUpperCase() + month.slice(1));
    }
    if (["full", "year"].includes(format)) {
        const year = value.toLocaleString(locale, {
            year: "numeric",
        });
        label.push(year);
    }
    return label.join(" ");
}
