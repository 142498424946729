import type { FC, ReactNode } from "react";

import {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
    loadComponent,
    formatURL,
    mapSearchToModule,
    compareModules,
    parseModuleSpecs,
} from "./helpers";
import { AppModuleManagerContext } from "./utils";
import { moduleRelation } from "./relations";
import type { ModuleNames } from "./relations";
import { currentVersion } from "./interface";
import type { ModuleInjectedProps, ModuleItem, ModuleSpecs } from "./interface";

import { LOCAL_STORAGE_NAMES } from "../constants/localStorage";

import useLocalStorage from "../hooks/storages/useLocalStorage";

import { applyParams } from "../router/helpers";
import { routeNames } from "../router/interface";

import deepEqual from "../utils/objects/deepEqual";

interface ModulesProps {
    tag: keyof typeof moduleRelation;
    mainKey: string;
    oldKey?: string;
    URLSearchParams?: string;
    oldParams?: {
        paramsI?: any;
        paramsII?: any;
        paramsIII?: any;
        paramsIV?: any;
        paramsV?: any;
    };
}

const Modules: FC<ModulesProps> = ({
    tag,
    mainKey,
    oldKey,
    URLSearchParams,
    oldParams,
}) => {
    const module = useMemo<ModuleSpecs>(
        () => moduleRelation[tag as keyof typeof moduleRelation],
        [tag],
    );

    const Component = useMemo(() => module && loadComponent(module), [module]);

    if (!Component) return null;

    const props: ModuleInjectedProps["_moduleManager"] = {
        tag,
        key: mainKey,
        oldKey,
    };

    const search = oldParams?.paramsI?.URLSearchParams || URLSearchParams;
    const params = module.oldParams?.(oldParams) || oldParams;

    return (
        <Suspense fallback={null}>
            <Component
                _moduleManager={props}
                URLSearchParams={search}
                {...params}
            />
        </Suspense>
    );
};

export default Modules;

const initModules: ModuleItem[] = [
    parseModuleSpecs(moduleRelation.dash, {
        key: moduleRelation.dash.tag,
    }),
];

interface AppModuleManagerProps {
    children?: ReactNode;
}

export const AppModuleManager: FC<AppModuleManagerProps> = ({ children }) => {
    const [modules, setModules] = useState<ModuleItem[]>([]);
    const [currentModule, setCurrentModule] = useState(moduleRelation.dash.tag);
    const [hydrated, setHydrated] = useState(false);

    const modulesRef = useRef(modules);
    const currentModuleRef = useRef(currentModule);

    const { getItem, setItem } = useLocalStorage();

    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = useParams();

    const oldPathName = useRef("");
    const oldSearch = useRef("");
    const urlParamsRef = useRef(urlParams);
    const urlSearchParamsRef = useRef(new URLSearchParams(location.search));

    useEffect(() => {
        urlParamsRef.current = urlParams;
    }, [urlParams]);
    useEffect(() => {
        urlSearchParamsRef.current = new URLSearchParams(location.search);
    }, [location.search]);

    const updateCurrentModule = useCallback((newKey?: string | null) => {
        const temp = newKey || moduleRelation.dash.tag;
        if (currentModuleRef.current === temp) return false;
        currentModuleRef.current = temp;
        setCurrentModule(temp);
        return true;
    }, []);

    const updateRoute = useCallback(() => {
        const md = modulesRef.current.find(
            ({ key }) => key === currentModuleRef.current,
        );
        if (md) {
            oldPathName.current = applyParams(
                md.route || routeNames.APP_HOME,
                md.params,
            );
            const newUrl = formatURL(md);
            const newSearch = newUrl.split("?")[1];
            oldSearch.current = newSearch ? `?${newSearch}` : "";
            navigate(newUrl);
        }
    }, [navigate]);

    const updateLocalStorage = useCallback(
        (newModules: ModuleItem[]) => {
            setItem(LOCAL_STORAGE_NAMES.MODULE_MANAGER, {
                data: newModules,
                _version: currentVersion,
            });
        },
        [setItem],
    );

    const updateModulesList = useCallback(
        (newModules: ModuleItem[]) => {
            if (deepEqual(newModules, modulesRef.current)) return false;
            modulesRef.current = newModules;
            setModules(newModules);
            updateLocalStorage(newModules);
            return true;
        },
        [updateLocalStorage],
    );

    const mapLocationToModule = useCallback(() => {
        if (
            location.pathname === routeNames.APP_HOME &&
            urlSearchParamsRef.current.has("component")
        ) {
            const component = urlSearchParamsRef.current.get("component") || "";

            const md = modulesRef.current.find(({ tag }) => tag === component);
            if (md) {
                mapSearchToModule(md, urlSearchParamsRef.current);
                updateLocalStorage(modulesRef.current);
                return md.key;
            }

            const specs = moduleRelation[component as ModuleNames];
            if (specs) {
                const newMd = parseModuleSpecs(specs);
                mapSearchToModule(newMd, urlSearchParamsRef.current);
                modulesRef.current.push(newMd);
                updateLocalStorage(modulesRef.current);
                return newMd.key;
            }
        }

        const md = modulesRef.current.find(
            ({ key, route, params }) =>
                key !== moduleRelation.dash.tag &&
                route &&
                applyParams(route, params) === location.pathname,
        );
        if (md) {
            mapSearchToModule(md, urlSearchParamsRef.current);
            updateLocalStorage(modulesRef.current);
            return md.key;
        }

        const specs = (Object.values(moduleRelation) as ModuleSpecs[]).find(
            ({ route, tag }) =>
                tag !== moduleRelation.dash.tag &&
                route &&
                applyParams(route, urlParamsRef.current) === location.pathname,
        );
        if (specs) {
            const newMd = parseModuleSpecs(specs, {
                params: urlParamsRef.current,
            });
            mapSearchToModule(newMd, urlSearchParamsRef.current);
            modulesRef.current.push(newMd);
            updateLocalStorage(modulesRef.current);
            return newMd.key;
        }
    }, [location.pathname, updateLocalStorage]);

    useEffect(() => {
        if (hydrated) return;
        const persisted = getItem(LOCAL_STORAGE_NAMES.MODULE_MANAGER);

        if (persisted) {
            if (persisted._version === currentVersion) {
                modulesRef.current = persisted.data;
            } else {
                modulesRef.current = [];
                persisted.data.forEach((md) => {
                    const specs = moduleRelation[md.tag as ModuleNames];
                    if (specs) {
                        const newMd = parseModuleSpecs(specs, {
                            key: md.key,
                            title: md.title,
                            search: md.search,
                        });
                        if (Object.keys(newMd.params).length) {
                            for (const key in newMd.params) {
                                if (!md.params[key]) {
                                    return;
                                }
                            }
                        }
                        newMd.params = md.params;
                        modulesRef.current.push(newMd);
                    }
                });
                updateLocalStorage(modulesRef.current);
            }
        } else {
            modulesRef.current = initModules;
            updateLocalStorage(initModules);
        }

        const currentKey = mapLocationToModule();

        updateCurrentModule(currentKey);
        updateRoute();
        setModules(modulesRef.current);
        setHydrated(true);
    }, [
        hydrated,
        getItem,
        updateLocalStorage,
        updateCurrentModule,
        updateRoute,
        mapLocationToModule,
    ]);

    useEffect(() => {
        if (
            !hydrated ||
            (oldPathName.current === location.pathname &&
                oldSearch.current === location.search)
        )
            return;
        const currentKey = mapLocationToModule();
        updateCurrentModule(currentKey);
        updateRoute();
    }, [
        hydrated,
        location.pathname,
        location.search,
        updateRoute,
        updateCurrentModule,
        mapLocationToModule,
    ]);

    const addModules = useCallback<AppModuleManagerContext["addModules"]>(
        (newMds) => {
            const temp = [...modulesRef.current];
            const entries = newMds.sort(
                (a, b) => (b.order ?? 0) - (a.order ?? 0),
            );
            const keys = [];
            for (const entry of entries) {
                const { order, relative, ...module } = entry;
                const idx = temp.findIndex((md) => compareModules(md, module));

                if (idx !== -1) {
                    temp[idx] = { ...temp[idx], ...module };
                    keys.push(temp[idx].key);
                    continue;
                }

                const data = moduleRelation[module.tag as ModuleNames];

                if (!data) continue;

                const md = parseModuleSpecs(data, module);

                if (typeof order !== "undefined") {
                    temp.splice(order, 0, md);
                } else if (relative) {
                    const currIdx = temp.findLastIndex(
                        ({ key, relativeTo }) =>
                            key === currentModuleRef.current ||
                            relativeTo === currentModuleRef.current,
                    );
                    if (currIdx !== -1) {
                        md.relativeTo = currentModuleRef.current;
                        temp.splice(currIdx + 1, 0, md);
                    } else {
                        temp.push(md);
                    }
                } else {
                    temp.push(md);
                }
                keys.push(md.key);
            }

            temp.sort((a, b) =>
                !a.fixed && b.fixed ? 1 : a.fixed && !b.fixed ? -1 : 0,
            );

            updateModulesList(temp);

            if (keys[0]) {
                updateCurrentModule(keys[0]);
                updateRoute();
            }
        },
        [updateRoute, updateModulesList, updateCurrentModule],
    );

    const removeModules = useCallback<AppModuleManagerContext["removeModules"]>(
        (mds) => {
            const mdsToRemove =
                typeof mds === "function" ? mds(modulesRef.current) : mds;
            const temp = [...modulesRef.current];
            const deleted: string[] = [];
            let target = "";
            for (const module of mdsToRemove) {
                const idx = temp.findIndex((md) => compareModules(md, module));
                if (idx !== -1) {
                    if (temp[idx].fixed) {
                        target = temp[idx].key;
                    } else {
                        deleted.push(temp[idx].key);
                        target = temp[idx - 1]?.key || "";
                        temp.splice(idx, 1);
                    }
                }
            }

            if (
                updateModulesList(temp) &&
                deleted.includes(currentModuleRef.current)
            ) {
                updateCurrentModule(target);
                updateRoute();
            }
        },
        [updateRoute, updateModulesList, updateCurrentModule],
    );

    const updateModules = useCallback<AppModuleManagerContext["updateModules"]>(
        (mds) => {
            const temp = [...modulesRef.current];
            for (const entry of mds) {
                const { moveTo, ...module } = entry;
                const idx = temp.findIndex((md) => compareModules(md, module));

                if (idx !== -1) {
                    temp[idx] = { ...temp[idx], ...module };
                    if (
                        typeof moveTo !== "undefined" &&
                        moveTo !== temp[idx].key
                    ) {
                        const target = temp.findIndex(
                            (md) => md.key === moveTo,
                        );
                        if (target !== -1) {
                            if (target > idx) {
                                temp.splice(target, 0, temp[idx]);
                                temp.splice(idx, 1);
                            } else {
                                const copy = temp[idx];
                                temp.splice(idx, 1);
                                temp.splice(target, 0, copy);
                            }
                        } else {
                            temp.push(temp[idx]);
                            temp.splice(idx, 1);
                        }
                    }
                }
            }

            updateModulesList(temp);
            updateRoute();
        },
        [updateModulesList, updateRoute],
    );

    const setModule = useCallback<AppModuleManagerContext["setModule"]>(
        (module) => {
            const md = modulesRef.current.find((md) =>
                compareModules(md, module),
            );
            if (updateCurrentModule(md?.key)) {
                updateRoute();
            }
        },
        [updateRoute, updateCurrentModule],
    );

    const state = useMemo<AppModuleManagerContext>(
        () => ({
            modules,
            currentModule,
            hydrated,
            addModules,
            removeModules,
            setModule,
            updateModules,
        }),
        [
            modules,
            currentModule,
            hydrated,
            addModules,
            removeModules,
            setModule,
            updateModules,
        ],
    );

    return (
        <AppModuleManagerContext.Provider value={state}>
            {children}
        </AppModuleManagerContext.Provider>
    );
};
