import { createContext } from "react";

import type { PostLoginPayload } from "../../services/login/postLogin";

export interface SessionData {
    accountId: number;
    accountName: string;
    branches: { value: number; label: string }[];
    company: {
        corporateName: string;
        documentType: "cpf" | "cnpj";
        logoUrl: string | null;
    };
    externalDashboard: boolean;
    isAdmin: boolean;
    parameters: Record<string, boolean>;
    privileges: Record<string, boolean>;
    userEmail: string;
    userName: string;
}

export interface SessionContext {
    data: Partial<SessionData>;
    error: string;
    hydrated: boolean;
    loading: boolean;
    logged: boolean;
    login: (payload: PostLoginPayload, callback?: () => void) => void;
    logout: (callback?: () => void) => void;
    processing: boolean;
}

export const SessionContext = createContext<SessionContext>({
    data: {},
    error: "",
    hydrated: false,
    loading: true,
    logged: false,
    login: () => null,
    logout: () => null,
    processing: false,
});

export const CURRENT_VERSION = 2;
