import { createMask, createMaskHandler } from ".";

type DateFormat =
    | `${"MM" | "DD/MM" | "MM/DD"}${"" | "/YY" | "/YYYY"}`
    | `${"YY" | "YYYY"}/MM/DD`;
type DateOptions = { format: DateFormat; separator?: string };

export function dateMask({
    format,
    separator = "/",
}: DateOptions): (str: string) => string {
    const pattern = format
        .split("/")
        .map((fm, idx) => `([0-9]{${+(idx === 0)},${fm.length}})`)
        .join(`\\${separator}?`);
    return createMask({ pattern: new RegExp(pattern), fillers: separator });
}

export function dateMaskHandler(opts: DateOptions) {
    return createMaskHandler(dateMask(opts));
}
