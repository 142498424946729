import type { ErrorData as OldErrorData } from "./interface";

import type { ErrorData } from "../new/services/interface";

export function mapErrorData(data?: OldErrorData): Pick<ErrorData, "message"> {
    return {
        message:
            data?.errorType === "string"
                ? data.errorMessage.replace("[BadRequest] - ", "")
                : data?.trace?.[0] || "Error",
    };
}
