import { useMemo } from "react";

import {
    LOCAL_STORAGE_DATA,
    LOCAL_STORAGE_NAMES,
} from "../../constants/localStorage";

import LocalStorage from "../../utils/storages/localStorage";
import type { ILocalStorage } from "../../utils/storages/localStorage";

export const LSInstance = new LocalStorage<LOCAL_STORAGE_DATA>();

export default function useLocalStorage(): ILocalStorage<
    LOCAL_STORAGE_DATA,
    LOCAL_STORAGE_NAMES
> {
    const actions = useMemo<
        ILocalStorage<LOCAL_STORAGE_DATA, LOCAL_STORAGE_NAMES>
    >(
        () => ({
            getItem: LSInstance.getItem.bind(LSInstance),
            getItems: LSInstance.getItems.bind(LSInstance),
            removeItems: LSInstance.removeItems.bind(LSInstance),
            setItem: LSInstance.setItem.bind(LSInstance),
            setItems: LSInstance.setItems.bind(LSInstance),
        }),
        [],
    );
    return actions;
}
