import type { FC, ReactNode } from "react";

import { alertIconMap } from "./helpers";
import {
    alertCommonStyle,
    alertContentCommonStyle,
    alertIconCommonStyle,
    alertIconVariants,
    alertVariants,
} from "./interface";
import type { AlertVariants } from "./interface";

import Icon from "../Icons";
import type { IconInfo, IconNames } from "../Icons/interface";

import { classes } from "../../../utils/styles/tailwind/v4";

export interface AlertProps {
    children?: ReactNode;
    containerStyle?: string;
    contentStyle?: string;
    hasIcon?: boolean;
    icon?: IconNames | IconInfo;
    iconStyle?: string;
    variant?: AlertVariants;
}

const Alert: FC<AlertProps> = ({
    variant = "info",
    hasIcon,
    icon,
    containerStyle,
    contentStyle,
    iconStyle,
    children,
}) => (
    <div
        role="alert"
        className={classes`${variant !== "unstyled" ? alertCommonStyle : ""} ${
            alertVariants[variant]
        } ${containerStyle}`}>
        {hasIcon && (
            <Icon
                icon={typeof icon === "string" ? icon : undefined}
                info={
                    typeof icon === "object"
                        ? icon
                        : typeof icon === "undefined" && variant !== "unstyled"
                        ? alertIconMap[variant]
                        : undefined
                }
                className={classes`${
                    variant !== "unstyled" && alertIconCommonStyle
                } ${alertIconVariants[variant]} ${iconStyle}`}
            />
        )}
        <div
            className={classes`${
                variant !== "unstyled" && alertContentCommonStyle
            } ${contentStyle}`}>
            {children}
        </div>
    </div>
);

export default Alert;

export interface AlertTitleProps {
    children?: ReactNode;
    className?: string;
}

export const AlertTitle: FC<AlertTitleProps> = ({ className, children }) => (
    <h4 className={classes`m-0 text-base font-semibold ${className}`}>
        {children}
    </h4>
);
