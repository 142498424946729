import type { MutableRefObject } from "react";

import type { ToastPositions, ToastStatus } from "./interface";

import { TickIconInfo, InfoIconInfo } from "../Icons/Mocks";
import type { IconInfo } from "../Icons/interface";

import createAnimation from "../../../utils/animations/animate";
import { easeInOutQuad } from "../../../utils/math/timingFunctions";

export const toastIconMap: Record<ToastStatus, IconInfo> = {
    error: InfoIconInfo,
    info: InfoIconInfo,
    success: TickIconInfo,
    warning: InfoIconInfo,
};

export interface AnimationStateData {
    currentPos: MutableRefObject<[number, number]>;
    parentHeight: MutableRefObject<number>;
    position?: ToastPositions;
}

interface PreparedState {
    start: [number, number];
    goal: [number, number];
}

export const animateToastConfig = createAnimation({
    container: {
        prepare: (
            element,
            { isFirst },
            { currentPos, parentHeight, position }: AnimationStateData,
        ) => {
            const {
                clientHeight,
                clientWidth,
                offsetLeft,
                offsetTop,
                parentElement,
            } = element;

            const prepared: PreparedState = {
                goal: [offsetLeft, offsetTop],
                start: [offsetLeft, offsetTop],
            };

            if (isFirst) {
                switch (position) {
                    case "bottom":
                        prepared.start[1] += clientHeight * 2;
                        break;
                    case "top":
                        prepared.start[1] -= clientHeight * 2;
                        break;
                    case "bottom-left":
                    case "top-left":
                        prepared.start[0] -= clientWidth * 2;
                        break;
                    case "bottom-right":
                    case "top-right":
                        prepared.start[0] += clientWidth * 2;
                        break;
                    default:
                        return;
                }
            } else {
                prepared.start = [...currentPos.current];
                if (position?.startsWith("bottom")) {
                    prepared.start[1] +=
                        (parentElement?.clientHeight ?? 0) -
                        parentHeight.current;
                }
            }

            parentHeight.current = parentElement?.clientHeight ?? 0;

            return prepared;
        },
        step: (
            element,
            p,
            { prepared },
            { currentPos }: AnimationStateData,
        ) => {
            const { start, goal } = prepared as PreparedState;

            const t = easeInOutQuad(p);

            currentPos.current[0] = t * goal[0] + (1 - t) * start[0];
            currentPos.current[1] = t * goal[1] + (1 - t) * start[1];

            const dx = currentPos.current[0] - goal[0];
            const dy = currentPos.current[1] - goal[1];

            element.style.transform = `translate(${dx}px, ${dy}px)`;
        },
        end: (element, { prepared }, { currentPos }: AnimationStateData) => {
            const { goal } = prepared as PreparedState;
            currentPos.current = [...goal];
            element.style.transform = "";
        },
    },
});

export const closeToastConfig = createAnimation({
    container: {
        step: (element, p) => {
            const t = easeInOutQuad(p);
            element.style.scale = `${1 - t}`;
        },
    },
});
