import type { CompanyParameter } from "../../services/companies/company/interface";

import type { PrivilegeGroup } from "../../../services/accounts/interface";

export function parsePrivileges(
    groups: PrivilegeGroup[],
): Record<string, boolean> {
    const privileges = groups.flatMap(({ Module }) =>
        Module.flatMap(({ Privilege }) => Privilege),
    );
    return privileges.reduce((rel, { UserPrivilege }) => {
        rel[UserPrivilege] = true;
        return rel;
    }, {} as Record<string, boolean>);
}

export function parseParameters(
    params: CompanyParameter[],
): Record<string, boolean> {
    return params.reduce((rel, { key, value }) => {
        rel[key] = value === "1";
        return rel;
    }, {} as Record<string, boolean>);
}
