import type { InputSizes, InputVariants } from "../utils";

export const dateInputVariants: Record<InputVariants, string> = {
    primary:
        "bg-white border border-grey-100 rounded-4 *:placeholder:text-grey-200 focus-within:border-doladoBlue-300",
    unstyled: "",
};

export const dateInputSizes: Record<InputSizes, string> = {
    lg: "min-h-48 px-24",
    md: "min-h-40 px-16",
    sm: "min-h-32 px-8",
    unset: "",
};

export const dateInputCommonStyle =
    "w-full *:text-sm *:placeholder:text-sm *:disabled:bg-grey-50 has-[:disabled]:bg-grey-50 has-[:disabled]:border-grey-100 has-[:disabled]:text-grey-400";

export const dateInputInvalidStyle =
    "border-red has-[:disabled]:border-red focus-within:border-red";

export const dateInputReadOnlyStyle =
    "bg-grey-50 *:bg-grey-50 border-grey-100 pointer-events-none touch-none";
