import type { ComponentType, FC, ReactNode } from "react";

import { Navigate } from "react-router-dom";

import { useSession } from "../../../contexts/session";

import { routeNames } from "../../../router/interface";

interface PublicRouteProps {
    children?: ReactNode;
    fallback?: string;
}

const PublicRoute: FC<PublicRouteProps> = ({ children, fallback }) => {
    const { logged, loading } = useSession();
    if (loading) return null;
    if (logged) return <Navigate to={fallback ?? routeNames.APP_HOME} />;
    return <>{children}</>;
};

export default PublicRoute;

export function withPublicRoute<Props extends JSX.IntrinsicAttributes>(
    Component: ComponentType<Props>,
): FC<Props> {
    const fc: FC<Props> = (props) => (
        <PublicRoute>
            <Component {...props} />
        </PublicRoute>
    );
    fc.displayName = `PublicRoute(${Component.displayName})`;
    return fc;
}
