import { createMask, createMaskHandler } from ".";
import type { MaskParams } from "./interface";

export const cpfMaskParams: MaskParams = {
    pattern: /\d/g,
    fillers: { 3: ".", 6: ".", 9: "-" },
    maxLength: 14,
};

export const cpfMask = createMask(cpfMaskParams);
export const cpfMaskHandler = createMaskHandler(cpfMask);

export const cnpjMaskParams: MaskParams = {
    pattern: /\d/g,
    fillers: { 2: ".", 5: ".", 8: "/", 12: "-" },
    maxLength: 18,
};

export const cnpjMask = createMask(cnpjMaskParams);
export const cnpjMaskHandler = createMaskHandler(cnpjMask);

export function documentMask(str: string): string {
    const match = str.match(/\d/g);
    let newValue = "";
    if (match) {
        const temp = match.join("");
        newValue = temp.length > 11 ? cnpjMask(temp) : cpfMask(temp);
    }
    return newValue;
}
export const documentMaskHandler = createMaskHandler(documentMask);
