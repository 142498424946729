import type { ComponentProps } from "react";

import { forwardRef, useMemo } from "react";

import {
    buttonIconCommonStyle,
    buttonIconSizes,
    buttonIconVariants,
} from "./interface";
import type { ButtonIconSizes, ButtonIconVariants } from "./interface";

import Icon from "../Icons";
import type { IconInfo, IconNames } from "../Icons/interface";
import { SpinnerIcon } from "../Icons/Mocks/spinner";

import { classes } from "../../../utils/styles/tailwind/v4";

export interface ButtonIconProps extends ComponentProps<"button"> {
    icon: IconNames | IconInfo;
    label: string;
    variant?: ButtonIconVariants;
    size?: ButtonIconSizes;
    isDisabled?: boolean;
    isLoading?: boolean;
    isRounded?: boolean;
    iconStyle?: string;
}

const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
    function ButtonIcon(
        {
            icon,
            label,
            variant = "solid",
            size = "lg",
            isRounded,
            isDisabled,
            isLoading,
            iconStyle,
            className,
            ...rest
        },
        ref,
    ) {
        const currentStyle = useMemo(
            () =>
                classes`${variant !== "unstyled" && buttonIconCommonStyle} ${
                    buttonIconSizes[size]
                } ${buttonIconVariants[variant]} ${
                    isRounded && "rounded-full"
                } ${className}`,
            [variant, size, isRounded, className],
        );

        return (
            <button
                ref={ref}
                aria-label={label}
                className={currentStyle}
                disabled={isDisabled}
                {...rest}>
                {isLoading ? (
                    <SpinnerIcon
                        title={rest.title}
                        className={`${
                            size === "lg" ? "w-36 h-36" : "w-24 h-24"
                        } aspect-square animate-spin`}
                    />
                ) : (
                    <Icon
                        title={rest.title}
                        icon={typeof icon === "string" ? icon : undefined}
                        info={typeof icon === "object" ? icon : undefined}
                        className={classes`w-full h-full ${iconStyle}`}
                    />
                )}
            </button>
        );
    },
);

export default ButtonIcon;

export * from "./interface";
