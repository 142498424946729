import type { FC, ReactNode } from "react";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { NotificationManagerContext } from "./interface";

import { hasSuccessed } from "../../services/helpers";

import deleteHubNotification from "../../../services/hub/notifications/delete";
import getHubNotifications from "../../../services/hub/notifications/get";
import type { HubNotification } from "../../../services/hub/notifications/interface";

import PromisePool from "../../utils/promises/pool";

interface NotificationManagerProps {
    children?: ReactNode;
}

const NotificationManager: FC<NotificationManagerProps> = ({ children }) => {
    const [notifications, setNotifications] = useState<HubNotification[]>([]);
    const notificationsRef = useRef(notifications);

    const [hydrated, setHydrated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);

    const refresh = useCallback(async () => {
        setLoading(true);
        const response = await getHubNotifications();
        notificationsRef.current = response;
        setNotifications(response);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (hydrated) return;
        (async () => {
            await refresh();
            setHydrated(true);
        })();
    }, [hydrated, refresh]);

    const update = useCallback<NotificationManagerContext["update"]>(
        (newData) => {
            const parsed =
                typeof newData === "function"
                    ? newData(notificationsRef.current)
                    : newData;
            notificationsRef.current = parsed;
            setNotifications(parsed);
        },
        [],
    );

    const deleteEntries = useCallback<
        NotificationManagerContext["deleteEntries"]
    >(async (ids) => {
        setProcessing(true);

        let entries = notificationsRef.current;
        if (ids) {
            entries = notificationsRef.current.filter(({ IDHubNotification }) =>
                ids.includes(IDHubNotification),
            );
        }

        const pool = new PromisePool(
            6,
            entries.map(({ AccountName, IDHubNotification }) => [
                IDHubNotification,
                async () =>
                    await deleteHubNotification(IDHubNotification, {
                        accountName: AccountName,
                    }),
            ]),
        );

        const responses = await pool.start();

        const successKeys = Object.entries(responses)
            .filter(([, res]) => res && hasSuccessed(res))
            .map(([key]) => key as string | number);

        if (successKeys.length) {
            const temp = [...notificationsRef.current];
            for (const key of successKeys) {
                const idx = temp.findIndex(
                    ({ IDHubNotification }) => IDHubNotification == key,
                );
                if (idx !== -1) temp.splice(idx, 1);
            }
            notificationsRef.current = temp;
            setNotifications(temp);
        }

        setProcessing(false);
    }, []);

    const state = useMemo<NotificationManagerContext>(
        () => ({
            deleteEntries,
            hydrated,
            loading,
            notifications,
            processing,
            refresh,
            update,
        }),
        [
            deleteEntries,
            hydrated,
            loading,
            notifications,
            processing,
            refresh,
            update,
        ],
    );

    return (
        <NotificationManagerContext.Provider value={state}>
            {children}
        </NotificationManagerContext.Provider>
    );
};

export default NotificationManager;
