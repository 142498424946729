import type { FC, ReactNode } from "react";

import { classes } from "../utils/styles/tailwind/v4";

interface DoladoLoginLayoutProps {
    children?: ReactNode;
    message?: ReactNode;
    styles?: {
        container?: string;
        infoArea?: string;
        contentArea?: string;
    };
}

const DoladoLoginLayout: FC<DoladoLoginLayoutProps> = ({
    message,
    styles,
    children,
}) => {
    return (
        <div className="w-full h-screen overflow-y-auto">
            <div className="flex flex-col md:justify-center items-center w-full min-h-full">
                <main
                    className={classes`flex flex-col md:flex-row bg-white w-full-minus-32 md:max-w-[680px] mx-auto my-24 rounded-12 shadow-md ${
                        styles?.container || ""
                    }`}>
                    <div
                        className={classes`relative z-0 flex-[40%] max-md:min-h-144 flex flex-col bg-doladoBlue max-md:rounded-tl-12 max-md:rounded-tr-12 md:rounded-tl-12 md:rounded-bl-12 p-36 pb-20 overflow-hidden before:absolute before:left-full before:-top-1/5 before:-translate-x-1/2 before:-translate-y-1/2 before:-z-1 before:rotate-135 before:w-1200 before:h-640 before:bg-flow before:bg-cover before:brightness-125 before:pointer-events-none before:touch-none ${
                            message
                                ? "items-center md:items-start"
                                : "justify-center"
                        } ${styles?.infoArea || ""}`}>
                        <img
                            alt="logo"
                            src="/images/logotipo-dolado-branco.svg"
                            className="h-30 max-md:mx-auto"
                        />

                        {message && (
                            <p className="mt-40 mb-0 text-base/5 text-white font-semibold max-md:text-center">
                                {message}
                            </p>
                        )}
                    </div>

                    <div
                        className={classes`flex-[60%] p-24 md:p-36 pb-20 ${
                            styles?.contentArea || ""
                        }`}>
                        {children}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default DoladoLoginLayout;
