import type { FC, ReactNode } from "react";

import { useContext, useEffect, useState } from "react";

import moment from "moment";

import { Modal as AntdModal } from "antd";

import Button from "../../../components/@basic/Button";
import ButtonGroup from "../../../components/@basic/Button/Group";
import ButtonIcon from "../../../components/@basic/ButtonIcon";
import Modal, {
    ModalBody,
    ModalHeader,
} from "../../../components/@basic/Modal";
import Popover, {
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
} from "../../../components/@basic/Popover";
import {
    TickIconInfo,
    BellIconInfo,
    EyeIconInfo,
} from "../../../components/@basic/Icons/Mocks";

import { useNotifications } from "../../../contexts/notification-manager/utils";
import { hasPrivileges, useSession } from "../../../contexts/session";

import useIsTablet from "../../../hooks/breakpoints/useIsTablet";

import { MainContext } from "../../../../contexts/MainContext";

import type { HubNotification } from "../../../../services/hub/notifications/interface";

interface NotificationItemProps {
    message: string | ReactNode;
    onMarkAsRead?: {
        canInteract?: boolean;
        disabled?: boolean;
        callback?: () => void;
    };
    onShowMore?: { disabled?: boolean; callback?: () => void };
    title: string;
}

const NotificationItem: FC<NotificationItemProps> = ({
    message,
    title,
    onMarkAsRead,
    onShowMore,
}) => (
    <li className="border border-gray-200 rounded-8 px-8 py-4 text-gray-800">
        <strong className="m-0 text-sm font-bold">{title}</strong>
        <div className="flex flex-col md:flex-row justify-between gap-16 mt-8">
            <p className="mb-0 text-xs">{message}</p>
            <ButtonGroup>
                <ButtonIcon
                    variant="outlined"
                    size="unset"
                    label="see-more"
                    icon={EyeIconInfo}
                    className="w-20 h-20 p-4"
                    title="Visualizar"
                    isDisabled={onShowMore?.disabled}
                    onClick={onShowMore?.callback}
                />
                <ButtonIcon
                    variant="outlined"
                    size="unset"
                    label="see-more"
                    icon={TickIconInfo}
                    className="w-20 h-20 p-4"
                    title={
                        onMarkAsRead?.canInteract
                            ? "Marcar como conferido"
                            : "Usuário não tem permissão para marcar como conferido"
                    }
                    isDisabled={onMarkAsRead?.disabled}
                    onClick={onMarkAsRead?.callback}
                />
            </ButtonGroup>
        </div>
    </li>
);

const NotificationButton = () => {
    const { AppendPrependTabs } = useContext(MainContext);

    const { notifications, processing, deleteEntries } = useNotifications();

    const {
        data: { isAdmin, privileges },
    } = useSession();
    const hasHubNotificationDelete = hasPrivileges(privileges, [
        "PrivilegeHubNotificationDelete",
    ]);

    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const onToggle = () => setIsOpen((prev) => !prev);

    const isTablet = useIsTablet();

    useEffect(onClose, [isTablet]);

    function AppendPrependTabsComponent(item: any) {
        let json;
        if (item) {
            json = {
                key: `${item.TypeHubIntegrationProblem} ${item.ID}`,
                component: `${item.Component}`,
                title: `${item.TypeHubIntegrationProblem} ${item.ID}`,
                URLSearchParams: `?${
                    item.Params ? `${item.Params}&` : ""
                }component=${item.Component}&title=${
                    item.TypeHubIntegrationProblem
                } ${item.ID}&key=${item.ID}&account=${item.AccountName}`,
                account: item.AccountName,
            };
        }

        if (json) {
            AppendPrependTabs(json, "append");
        }
    }

    async function Controller(action: string, row?: HubNotification) {
        if (action === "single" && row) {
            deleteEntries([row.IDHubNotification]);
        } else {
            deleteEntries();
        }
    }

    function ShowConfirm() {
        AntdModal.confirm({
            title: "Atenção",
            cancelText: "Não",
            okText: "Sim",
            okType: "default",
            centered: true,
            content: "Confirma marcar todos os registros como conferido?",
            closable: true,
            autoFocusButton: "cancel",
            onOk: () => Controller("mult"),
        });
    }

    const parseCount = () => {
        const count = notifications?.length;
        if (typeof count === "undefined") return "";

        if (count === 0) {
            return "Sem notificações";
        } else if (count === 1) {
            return `${count} Notificação`;
        } else {
            return `${count} Notificações`;
        }
    };

    const formatMessage = (
        message: string,
        isAdmin: boolean,
        accountName: string,
    ): JSX.Element => {
        const accountMessage = ` da conta ${accountName}`;
        const tokenNotFoundMessage = " (refresh token não encontrado)";

        return isAdmin ? (
            <>
                {message.includes(tokenNotFoundMessage) ? (
                    message.split(tokenNotFoundMessage).map((part, index) =>
                        index % 2 === 0 ? (
                            <>
                                {part} <strong>{accountMessage}</strong>
                            </>
                        ) : (
                            <>
                                {tokenNotFoundMessage}
                                {part}
                            </>
                        ),
                    )
                ) : (
                    <>
                        {message} <strong>{accountMessage}</strong>
                    </>
                )}
            </>
        ) : (
            <>{message}</>
        );
    };

    const renderButton = (className = "") => (
        <div className={`relative ${className}`}>
            <ButtonIcon
                variant="ghost"
                size="sm"
                label="notifications"
                title="Notificação"
                icon={BellIconInfo}
                className="text-royalBlue-900"
                onClick={onToggle}
            />
            {!!notifications?.length && (
                <div className="absolute -top-2 -right-2 pointer-events-none touch-none flex justify-center items-center bg-red-500 min-w-14 h-14 rounded-full px-4 text-[10px] leading-none text-white text-center font-medium tabular-nums">
                    {notifications?.length}
                </div>
            )}
        </div>
    );

    const renderHeader = () => (
        <div className="flex flex-col lg:flex-row justify-between gap-8">
            <p className="w-max mb-0">{parseCount()}</p>

            {notifications.length > 1 && (
                <Button
                    size="unset"
                    className="w-max h-24 rounded-4 px-6 text-xs"
                    iconStyle="w-10 h-10"
                    leftIcon={TickIconInfo}
                    disabled={!hasHubNotificationDelete || processing}
                    isLoading={processing}
                    title={
                        hasHubNotificationDelete
                            ? "Marcar como conferido"
                            : "Usuário não tem permissão para marcar como conferido"
                    }
                    onClick={() => ShowConfirm()}>
                    Limpar Notificações
                </Button>
            )}
        </div>
    );

    const renderContent = (className = "") =>
        !!notifications.length && (
            <ul className={`mb-0 mt-16 pl-0 space-y-8 ${className}`}>
                {notifications.map((item, idx) => (
                    <NotificationItem
                        key={idx}
                        title={`${moment(item.RecordTimestamp)
                            .utc()
                            .format(
                                "DD/MM/YYYY",
                            )} - ${item.TypeHubIntegrationProblem.toUpperCase()}`}
                        message={formatMessage(
                            item?.Message,
                            !!isAdmin,
                            item.AccountName,
                        )}
                        onShowMore={{
                            disabled: !item.Component || processing,
                            callback: () => AppendPrependTabsComponent(item),
                        }}
                        onMarkAsRead={{
                            canInteract: !hasHubNotificationDelete,
                            disabled: !hasHubNotificationDelete || processing,
                            callback: () => Controller("single", item),
                        }}
                    />
                ))}
            </ul>
        );

    return (
        <>
            {renderButton("block lg:hidden")}

            <Modal isOpen={isOpen && isTablet} onClose={onClose}>
                <ModalHeader>{renderHeader()}</ModalHeader>
                <ModalBody className="px-24 pb-40 scrollbar-floating">
                    {renderContent()}
                </ModalBody>
            </Modal>

            <Popover
                position="bottom-end"
                size="lg"
                className="hidden lg:block"
                isOpen={isOpen && !isTablet}
                onClose={onClose}>
                <PopoverTrigger>{renderButton()}</PopoverTrigger>
                <PopoverContent>
                    <PopoverBody>
                        {renderHeader()}
                        {renderContent(
                            "max-h-400 overflow-auto scrollbar-floating",
                        )}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    );
};

export default NotificationButton;
