import { createMask, createMaskHandler } from ".";
import type { MaskParams } from "./interface";

export const zipMaskParams: MaskParams = {
    pattern: /(\d{1,5})-?(\d{0,3})/,
    fillers: "-",
};

export const zipMask = createMask(zipMaskParams);
export const zipMaskHandler = createMaskHandler(zipMask);
