import supabase from "../../configs/supabase";

export interface ConsultantData {
    id: string;
    name: string;
    subscription_value: number;
}

export async function getConsultants(): Promise<ConsultantData[]> {
    const { data } = await supabase
        .from("consultants")
        .select<"id, name, subscription_value", ConsultantData>(
            "id, name, subscription_value",
        );
    return data || [];
}

export async function getConsultant(
    uuid: string,
): Promise<ConsultantData | null> {
    const { data } = await supabase
        .from("consultants")
        .select<"id, name, subscription_value", ConsultantData>(
            "id, name, subscription_value",
        )
        .eq("id", uuid);
    return data?.[0] || null;
}
