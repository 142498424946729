"use client";

import type { FC, MouseEventHandler, ReactNode } from "react";

import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { ModalContext } from "./helpers";
import {
    ariaDescription,
    ariaLabel,
    portalId,
    modalAnimations,
    modalCommonStyle,
    modalScrollBehaviors,
    modalSizes,
    modalVariants,
} from "./interface";
import type {
    AnimationRelation,
    ModalScrollBehaviors,
    ModalSizes,
    ModalVariants,
} from "./interface";

import { classes } from "../../../utils/styles/tailwind/v4";

interface ModalProps {
    animations?: Partial<AnimationRelation>;
    backgroundStyle?: string;
    children?: ReactNode;
    className?: string;
    closeOnOverlayClick?: boolean;
    containerStyle?: string;
    hasOverlay?: boolean;
    isOpen?: boolean;
    isCentered?: boolean;
    onClose?: () => void;
    onCloseEnd?: () => void;
    scrollBehavior?: ModalScrollBehaviors;
    size?: ModalSizes;
    variant?: ModalVariants;
}

const Modal: FC<ModalProps> = ({
    variant = "primary",
    size = "md",
    isOpen = false,
    isCentered,
    hasOverlay = true,
    closeOnOverlayClick = true,
    scrollBehavior = "inside",
    animations,
    className,
    backgroundStyle,
    containerStyle,
    children,
    onClose,
    onCloseEnd,
}) => {
    const overlayClickStart = useRef(false);

    const [expanded, setExpanded] = useState(isOpen);
    useEffect(() => {
        if (isOpen) setExpanded(true);
    }, [isOpen]);

    useEffect(() => {
        if (expanded) {
            const { clientHeight, scrollHeight } = document.body;
            document.body.style.height = "100vh";
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight =
                scrollHeight > clientHeight ? "16px" : "";
        } else {
            document.body.style.height = "";
            document.body.style.overflow = "";
            document.body.style.paddingRight = "";
        }

        return () => {
            document.body.style.height = "";
            document.body.style.overflow = "";
            document.body.style.paddingRight = "";
        };
    }, [expanded]);

    const handleClickStart: MouseEventHandler<HTMLDivElement> = (event) => {
        if (
            event.target instanceof HTMLDivElement &&
            event.target.id.includes(portalId) &&
            closeOnOverlayClick
        ) {
            overlayClickStart.current = true;
        }
    };
    const handleClickEnd: MouseEventHandler<HTMLDivElement> = (event) => {
        if (
            event.target instanceof HTMLDivElement &&
            event.target.id.includes(portalId) &&
            overlayClickStart.current &&
            closeOnOverlayClick
        ) {
            onClose?.();
        }
        overlayClickStart.current = false;
    };

    const handleAnimationEnd = () => {
        setExpanded(isOpen);
        if (!isOpen) onCloseEnd?.();
    };

    const [label, description] = useMemo(() => {
        const rand = Math.round(Math.random() * 10000);
        return [`${ariaLabel}-${rand}`, `${ariaDescription}-${rand}`];
    }, []);

    const currentStyle = useMemo(
        () =>
            classes`${modalCommonStyle} ${modalSizes[size]} ${
                modalVariants[variant]
            } ${modalScrollBehaviors[scrollBehavior]} ${
                isOpen
                    ? animations?.in ?? modalAnimations.in
                    : animations?.out ?? modalAnimations.out
            } ${className}`,
        [
            variant,
            size,
            animations?.in,
            animations?.out,
            isOpen,
            scrollBehavior,
            className,
        ],
    );

    const state = useMemo<ModalContext>(
        () => ({
            aria: { expanded, description, label },
            variant,
            onClose,
        }),
        [expanded, description, label, variant, onClose],
    );

    if (!expanded) return null;

    return (
        <>
            {createPortal(
                <ModalContext.Provider value={state}>
                    <div
                        id={portalId}
                        className={classes`fixed inset-0 z-overlay overflow-y-auto transition-colors duration-200 ${
                            hasOverlay && isOpen ? "bg-black/20" : "bg-black/0"
                        } ${
                            hasOverlay ? "" : "pointer-events-none touch-none"
                        } ${backgroundStyle}`}
                        onMouseDownCapture={handleClickStart}
                        onMouseUpCapture={handleClickEnd}>
                        <div
                            id={`${portalId}-container`}
                            className={classes`flex flex-col items-center ${
                                isCentered ? "justify-center" : ""
                            } w-full min-h-full p-24 md:p-64 ${containerStyle}`}>
                            <div
                                role="dialog"
                                aria-modal
                                aria-labelledby={label}
                                aria-describedby={description}
                                className={currentStyle}
                                onAnimationEnd={handleAnimationEnd}>
                                {children}
                            </div>
                        </div>
                    </div>
                </ModalContext.Provider>,
                document.body,
            )}
        </>
    );
};

export default Modal;

export * from "./interface";
export { default as ModalHeader } from "./Header";
export { default as ModalFooter } from "./Footer";
export { default as ModalBody } from "./Body";
