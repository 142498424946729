import { createContext } from "react";

export enum OnboardingChecks {
    NF = "NF",
    CERTIFICATE = "digital_certificate",
    INTEGRATIONS = "integrations",
    PRODUCTS = "products",
    OFFERS = "offers",
    OFFER_PUBLISHING = "offer_publishing",
    ACCEPT_TERMS = "accept_terms",
}

export interface OnboardingContext {
    checks: OnboardingChecks[] | null;
    isPF: boolean;
    isProcessing: boolean;
    updateChecks: (mustLoad?: boolean) => Promise<void>;
}

export const OnboardingContext = createContext<OnboardingContext>({
    checks: null,
    isPF: false,
    isProcessing: true,
    updateChecks: () => Promise.resolve(),
});

export interface OnboardingModalCommonProps {
    onCloseEnd: () => void;
}
