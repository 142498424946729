import type { ChangeEventHandler, FC, FormEventHandler } from "react";

import { useRef, useState, useTransition } from "react";

import { Footer, Section, SectionTitle } from "../styles";
import { useSignUpContext } from "../helpers";
import { DocumentTypes, SignUpFormNames, SignUpSteps } from "../interface";
import type { SignUpFormData } from "../interface";

import Alert, { AlertTitle } from "../../../components/@basic/Alert";
import Button from "../../../components/@basic/Button";
import DateInput from "../../../components/@basic/Forms/DateInput";
import FormControl, {
    FormLabel,
    FormErrorMessage,
} from "../../../components/@basic/Forms/FormControl";
import Input from "../../../components/@basic/Forms/Input";
import Radio from "../../../components/@basic/Forms/Radio";
import RadioGroup from "../../../components/@basic/Forms/Radio/Group";
import Select from "../../../components/@basic/Forms/Select";
import {
    Profile2usersBoldIconInfo,
    ProfileBoldIconInfo,
    WarningIconInfo,
} from "../../../components/@basic/Icons/Mocks";

import checkIfAccountExists from "../../../services/accounts/checkIfExists";
import { AccountPaymentModels } from "../../../services/accounts/payloads/interface";

import { isLeapYear } from "../../../utils/date";

import { parseFormData } from "../../../utils/forms/parsers";
import { validateForm } from "../../../utils/forms/validation";
import type { ErrorRelation } from "../../../utils/forms/validation";

import slugify from "../../../utils/formatters/slugify";
import {
    cnpjMask,
    cnpjMaskHandler,
    cpfMask,
    cpfMaskHandler,
} from "../../../utils/inputs/masks/document";
import {
    cnpjPattern,
    cpfPattern,
} from "../../../utils/inputs/patterns/document";
import { fullNamePattern } from "../../../utils/inputs/patterns/name";
import {
    phoneMaskHandlers,
    phoneMasks,
} from "../../../utils/inputs/masks/phone";
import { phoneBrazilCompactPattern } from "../../../utils/inputs/patterns/phone";

const fiscalModelOptions = [
    [AccountPaymentModels.SIMPLE, "Simples Nacional"],
    [AccountPaymentModels.PRESUMED_PROFIT, "Lucro Presumido"],
    [AccountPaymentModels.REAL_PROFIT, "Lucro Real"],
];

const AccountInfoStep: FC = () => {
    const { currentStep, data, processing, updateData, goToNextStep } =
        useSignUpContext();

    const [errors, setErrors] = useState<
        ErrorRelation<SignUpFormData[SignUpSteps.ACCOUNT]>
    >({});
    const [documentType, setDocumentType] = useState(
        data.current[SignUpSteps.ACCOUNT]?.documentType || DocumentTypes.CNPJ,
    );
    const documentRef = useRef(
        data.current[SignUpSteps.ACCOUNT]?.document || "",
    );
    const [endpointPreview, setEndpointPreview] = useState("");
    const [checkingAccount, setCheckingAccount] = useState(false);

    const [, startTransition] = useTransition();

    const handleChange: ChangeEventHandler<HTMLFormElement> = (event) => {
        if (event.target.name === SignUpFormNames.DOCUMENT_TYPE) {
            setDocumentType(event.target.value);
            const documentField = event.currentTarget.elements.namedItem(
                SignUpFormNames.DOCUMENT,
            ) as HTMLInputElement | null;
            if (documentField)
                documentField.value =
                    event.target.value === DocumentTypes.CNPJ
                        ? cnpjMask(documentRef.current)
                        : cpfMask(documentRef.current);
        }

        if (event.target.name === SignUpFormNames.DOCUMENT) {
            documentRef.current =
                event.target.value.match(/\d/g)?.join("") || "";
        }

        if (event.target.name === SignUpFormNames.ACCOUNT_NAME) {
            startTransition(() => {
                setEndpointPreview(slugify(event.target.value.trim()));
            });
        }
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        setErrors({});

        const errorRelation = await validateForm<
            SignUpFormData[SignUpSteps.ACCOUNT]
        >(event);
        if (errorRelation) {
            if (errorRelation.document) {
                errorRelation.document.splice(
                    0,
                    1,
                    "Documento inválido. Somente CNPJ",
                );
            }

            setErrors(errorRelation);
            return;
        }

        const data = parseFormData<SignUpFormData[SignUpSteps.ACCOUNT]>(event);

        updateData(SignUpSteps.ACCOUNT, {
            ...data,
            documentType: DocumentTypes.CNPJ,
        });

        setCheckingAccount(true);

        const response = await checkIfAccountExists(slugify(data.accountName));

        setCheckingAccount(false);

        if (response.account || response.accountPayload) {
            setErrors({ accountName: ["Esta conta já existe"] });
        } else {
            goToNextStep();
        }
    };

    const renderDocumentField = () => {
        const document = data.current[SignUpSteps.ACCOUNT]?.document || "";

        let defaultValue;
        let pattern;
        let mask;
        let placeholder;
        let validator;
        if (documentType === DocumentTypes.CNPJ) {
            defaultValue = cnpjMask(document);
            pattern = cnpjPattern;
            mask = cnpjMaskHandler;
            placeholder = "00.000.000/0000-00";
            validator = "cnpj";
        } else {
            defaultValue = cpfMask(document);
            pattern = cpfPattern;
            mask = cpfMaskHandler;
            placeholder = "000.000.000-00";
            validator = "cpf";
        }

        return (
            <FormControl
                isRequired
                isDisabled={processing || checkingAccount}
                isInvalid={!!errors.document}>
                <FormLabel>Documento</FormLabel>
                <Input
                    type="text"
                    name={SignUpFormNames.DOCUMENT}
                    data-label="Documento"
                    data-validate={validator}
                    data-parser="onlyNumbers"
                    placeholder={placeholder}
                    pattern={pattern}
                    onChange={mask}
                    defaultValue={defaultValue}
                />
                <FormErrorMessage>{errors.document?.[0]}</FormErrorMessage>
            </FormControl>
        );
    };

    const renderBirthDateField = () => {
        const now = new Date();
        const currY = now.getFullYear();
        const currM = now.getMonth();

        let ldMin = 0;
        let ldMax = 0;
        for (let i = 1; i < 100; i++) {
            if (isLeapYear(currY - i)) {
                ldMin++;
                if (i <= 18) ldMax++;
            }
        }

        if (isLeapYear(currY) && currM > 1) {
            ldMin++;
            ldMax++;
        }

        const min = now.getTime() - 3_153_600_000_000 - ldMin * 86_400_000;
        const max = now.getTime() - 567_648_000_000 - ldMax * 86_400_000;

        return (
            <DateInput
                required
                name={SignUpFormNames.BIRTHDATE}
                data-label="Data de nascimento"
                min={min}
                max={max}
                defaultValue={data.current[SignUpSteps.ACCOUNT]?.birthdate}
                isDisabled={processing || checkingAccount}
            />
        );
    };

    if (currentStep !== SignUpSteps.ACCOUNT) return null;

    const { accountName, email, financialModel, phone, username } =
        data.current[SignUpSteps.ACCOUNT] || {};

    return (
        <form
            noValidate
            className="flex flex-col gap-24 md:gap-32"
            onChange={handleChange}
            onSubmit={handleSubmit}>
            <Section>
                <SectionTitle
                    icon={Profile2usersBoldIconInfo}
                    title="Dados da empresa"
                />
                <div className="flex flex-col md:flex-row flex-wrap gap-24 lg:gap-32">
                    <div className="flex-1 md:min-w-272 space-y-16">
                        {/* <FormControl isDisabled={processing || checkingAccount}>
                            <RadioGroup
                                direction="row"
                                name={SignUpFormNames.DOCUMENT_TYPE}
                                defaultValue={documentType}>
                                <Radio value={DocumentTypes.CNPJ}>CNPJ</Radio>
                                <Radio value={DocumentTypes.CPF}>CPF</Radio>
                            </RadioGroup>
                            <FormErrorMessage>
                                {errors.documentType?.[0]}
                            </FormErrorMessage>
                        </FormControl> */}

                        {renderDocumentField()}

                        {documentType === DocumentTypes.CPF && (
                            <FormControl
                                isRequired
                                isDisabled={processing || checkingAccount}
                                isInvalid={!!errors.birthdate}>
                                <FormLabel>Data de nascimento</FormLabel>
                                {renderBirthDateField()}
                                <FormErrorMessage>
                                    {errors.birthdate?.[0]}
                                </FormErrorMessage>
                            </FormControl>
                        )}

                        {documentType === DocumentTypes.CNPJ && (
                            <FormControl
                                isRequired
                                isDisabled={processing || checkingAccount}
                                isInvalid={!!errors.financialModel}>
                                <FormLabel>Modelo fiscal da empresa</FormLabel>
                                <Select
                                    name={SignUpFormNames.FINANCIAL_MODEL}
                                    data-label="Modelo fiscal"
                                    defaultValue={financialModel}>
                                    {fiscalModelOptions.map(
                                        ([value, label], idx) => (
                                            <option key={idx} value={value}>
                                                {label}
                                            </option>
                                        ),
                                    )}
                                </Select>
                                <FormErrorMessage>
                                    {errors.financialModel?.[0]}
                                </FormErrorMessage>
                            </FormControl>
                        )}
                    </div>
                    <div className="flex-[9999] md:min-w-272">
                        {documentType === DocumentTypes.CPF && (
                            <Alert
                                variant="info"
                                hasIcon
                                icon={WarningIconInfo}
                                iconStyle="bg-translate text-yellow-500"
                                containerStyle="bg-doladoBlue-50">
                                <AlertTitle>Importante:</AlertTitle>
                                <p>
                                    A dolado e seus fornecedores operam com
                                    regularidade fiscal, portanto o uso da
                                    plataforma de vendas é exclusivo para
                                    cliente Pessoa Jurídica.
                                </p>
                                <p className="mb-0">
                                    Caso você ainda não tenha CNPJ, a dolado vai
                                    te auxiliar no processo de abertura.
                                </p>
                            </Alert>
                        )}
                    </div>
                </div>
            </Section>
            <hr className="m-0 border-gray-200" />
            <Section>
                <SectionTitle
                    icon={ProfileBoldIconInfo}
                    title="Dados da conta"
                />
                <div className="flex flex-col md:flex-row flex-wrap gap-16 lg:gap-32 mb-16 lg:mb-32">
                    <FormControl
                        isRequired
                        isDisabled={processing || checkingAccount}
                        isInvalid={!!errors.accountName}
                        className="flex-1 md:min-w-272">
                        <FormLabel>Nome da conta</FormLabel>
                        <Input
                            type="text"
                            name={SignUpFormNames.ACCOUNT_NAME}
                            data-label="Nome da conta"
                            placeholder="Minha loja"
                            maxLength={80}
                            defaultValue={accountName}
                        />
                        <FormErrorMessage>
                            {errors.accountName?.[0]}
                        </FormErrorMessage>
                        {endpointPreview ? (
                            <p className="bg-doladoBlue-50 w-max max-w-full mb-0 mt-16 rounded-4 px-8 py-1 text-xs/5 text-grey break-words whitespace-normal">
                                {`https://${endpointPreview}.dolado.com.br`}
                            </p>
                        ) : (
                            <div className="h-22 mt-16" />
                        )}
                    </FormControl>
                    <FormControl
                        isRequired
                        isDisabled={processing || checkingAccount}
                        isInvalid={!!errors.username}
                        className="flex-[9999] md:min-w-272">
                        <FormLabel>Nome completo</FormLabel>
                        <Input
                            type="text"
                            name={SignUpFormNames.USERNAME}
                            data-label="Nome completo"
                            placeholder="John Doe"
                            pattern={fullNamePattern}
                            defaultValue={username}
                        />
                        <FormErrorMessage>
                            {errors.username?.[0]}
                        </FormErrorMessage>
                    </FormControl>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap gap-16 lg:gap-32 mb-16 lg:mb-32">
                    <FormControl
                        isRequired
                        isDisabled={processing || checkingAccount}
                        isInvalid={!!errors.email}
                        className="flex-1 md:min-w-272">
                        <FormLabel>E-mail</FormLabel>
                        <Input
                            type="email"
                            name={SignUpFormNames.EMAIL}
                            data-label="E-mail"
                            placeholder="Meu e-mail"
                            pattern="^[^@]+@[^@]+\.[^@]+$"
                            defaultValue={email}
                        />
                        <FormErrorMessage>{errors.email?.[0]}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                        isRequired
                        isDisabled={processing || checkingAccount}
                        isInvalid={!!errors.phone}
                        className="flex-[9999] md:min-w-272">
                        <FormLabel>Telefone pessoal</FormLabel>
                        <Input
                            type="tel"
                            name={SignUpFormNames.PHONE}
                            data-label="Telefone"
                            placeholder="00 00000-0000"
                            data-parser="onlyNumbers"
                            pattern={phoneBrazilCompactPattern}
                            onChange={phoneMaskHandlers.brazilCompact}
                            defaultValue={phoneMasks.brazilCompact(phone || "")}
                        />
                        <FormErrorMessage>{errors.phone?.[0]}</FormErrorMessage>
                    </FormControl>
                </div>
            </Section>
            <Footer>
                <Button
                    type="submit"
                    isFullWidth
                    className="md:whitespace-nowrap"
                    isDisabled={processing || checkingAccount}
                    isLoading={processing || checkingAccount}>
                    Confirmar
                </Button>
            </Footer>
        </form>
    );
};

export default AccountInfoStep;
