import React from "react";
import { createRoot } from "react-dom/client";

import App from "./new/App";

import "./new/configs/sentry";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// import "./styles/tailwind.css";
import "./new/styles/global.css";
import "./assets/vendors/style";
import "./styles/wieldy.less";
import "./styles/pro-table.css";
import "./styles/geral.css";

declare global {
    let BlipChat: any;
}

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
