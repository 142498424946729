import type { AlertVariants } from "./interface";

import { InfoIconInfo, TickIconInfo, WarningIconInfo } from "../Icons/Mocks";
import type { IconInfo } from "../Icons/interface";

export const alertIconMap: Record<
    Exclude<AlertVariants, "unstyled">,
    IconInfo
> = {
    error: InfoIconInfo,
    info: InfoIconInfo,
    success: TickIconInfo,
    warning: WarningIconInfo,
};
