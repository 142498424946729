export const shortYearPattern = "[0-9]{2}";
export const fullYearPattern = "[1-2]([0-2]|[9])[0-9]{2}";
export const monthPattern = "([0][0-9]|[1][0-2])";
export const dayPattern = "([0-2][0-9]|[3][0-1])";

type DateStrings = "DD" | "MM" | "YY" | "YYYY";
type DateFormat =
    | `${"MM" | "DD/MM" | "MM/DD"}${"" | "/YY" | "/YYYY"}`
    | `${"YY" | "YYYY"}/MM/DD`;
type DateOptions = { format: DateFormat; separator?: string };

const datePatternRelation: Record<DateStrings, string> = {
    DD: dayPattern,
    MM: monthPattern,
    YY: shortYearPattern,
    YYYY: fullYearPattern,
};

export function datePattern({ format, separator = "/" }: DateOptions): string {
    const template = format.split("/") as DateStrings[];
    return template
        .map((temp) => datePatternRelation[temp])
        .join(`${separator}`);
}
