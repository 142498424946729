import type { FC, ReactNode } from "react";

import { classes } from "../../../utils/styles/tailwind/v4";

export interface DrawerFooterProps {
    className?: string;
    children?: ReactNode;
}

const DrawerFooter: FC<DrawerFooterProps> = ({ className, children }) => {
    return (
        <footer
            className={classes`flex-1 flex flex-col p-16 pb-24 before:flex-1 ${className}`}>
            {children}
        </footer>
    );
};

export default DrawerFooter;
