import type { HubNotification } from "./interface";

import type { RequestOptions } from "../../interface";

import lambdas from "../../../configs/rest/lambdas";

export default async function getHubNotifications({
    signal,
}: RequestOptions = {}): Promise<HubNotification[]> {
    try {
        const { data } = await lambdas.get<HubNotification[]>(
            "/hub/notification",
            { signal },
        );
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
