export default function createInterval(
    callback: () => true | void,
    delay?: number,
) {
    const interval = delay ?? 1000;
    let expected = Date.now() + interval;
    const handle: { current: NodeJS.Timeout | null } = { current: null };
    function step() {
        const stop = callback();
        if (stop) {
            if (handle.current) clearTimeout(handle.current);
            return;
        }

        let dt = Date.now() - expected;
        if (dt > interval) {
            dt = dt % interval;
        }

        expected += interval;
        handle.current = setTimeout(step, Math.max(0, interval - dt));
    }
    handle.current = setTimeout(step, interval);
    return handle;
}
