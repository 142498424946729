import { DEFAULT_STATUS } from "../../../../utils/helpers";

import type { TypeCompanyIntegration } from "../../../../../../services/companies/integrations/interface";
import type { SkuSpecificationDefinition } from "../../../../../../services/skus/specifications/interface";
import type { SkuPlainSpecification } from "../../../../../services/skus/sku/specifications/interface";

export interface IntegrationError {
    errorCode:
        | "categories"
        | "resources"
        | `update -> ${string}`
        | `specification -> ${string}`;
    message: string;
    context?: any;
}

export interface SkuState {
    id: string | number;
    errors: IntegrationError[];
    specs: SkuSpecificationDefinition[];
    records: SkuPlainSpecification[];
    status: DEFAULT_STATUS;
}

export interface IntegrationState {
    data: TypeCompanyIntegration;
    skus: SkuState[];
}

export interface State {
    integrations: IntegrationState[];
    status: DEFAULT_STATUS;
}

export const moduleId = "skusSkuSpecifications" as const;
