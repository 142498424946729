"use client";

import { forwardRef, useCallback, useMemo, useState } from "react";

import ButtonIcon from "../../ButtonIcon";
import { ChevronLeftIconInfo, ChevronRightIconInfo } from "../../Icons/Mocks";

import { getMonthNames } from "../../../../utils/date";

interface MonthSelectorProps {
    current?: Date;
    locale?: string;
    onCancel?: () => void;
    onConfirm?: (year: number, month: number) => void;
}

const MonthSelector = forwardRef<HTMLDivElement, MonthSelectorProps>(
    function MonthSelector(
        { current, locale = "pt-br", onCancel, onConfirm },
        ref,
    ) {
        const [year, setYear] = useState<number>();
        const [yearOffset, setYearOffset] = useState(0);

        const monthNames = useMemo(
            () =>
                getMonthNames(locale).map((nm) =>
                    `${nm.slice(0, 1).toLocaleUpperCase()}${nm.slice(
                        1,
                    )}`.replace(".", ""),
                ),
            [locale],
        );

        const now = new Date();
        const ny = now.getFullYear();
        const nm = now.getMonth();

        const cy = current?.getFullYear();
        const cm = current?.getMonth();

        const handleConfirm = useCallback(
            (month: number) => {
                onConfirm?.(year as number, month);
            },
            [onConfirm, year],
        );

        const renderYears = () => {
            if (typeof year !== "undefined") return null;

            const py = cy ?? ny;
            const start = py - (py % 10) + yearOffset * 10;

            return (
                <>
                    <div className="flex justify-between items-center mb-16">
                        <ButtonIcon
                            type="button"
                            label="previous-decade"
                            icon={ChevronLeftIconInfo}
                            variant="ghost"
                            size="sm"
                            className="text-doladoBlue"
                            onClick={() => setYearOffset((prev) => prev - 1)}
                        />
                        <p
                            id="selector"
                            className="cursor-pointer mb-0 rounded-4 px-2 py-1 hover:bg-black/10"
                            onClick={onCancel}>
                            {`${start}-${start + 9}`}
                        </p>
                        <ButtonIcon
                            type="button"
                            label="next-decade"
                            icon={ChevronRightIconInfo}
                            variant="ghost"
                            size="sm"
                            className="text-doladoBlue"
                            onClick={() => setYearOffset((prev) => prev + 1)}
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-8 px-24">
                        {new Array(12).fill(null).map((_, idx) => {
                            const y = start + idx - 1;
                            return (
                                <p
                                    key={`year-${idx}`}
                                    className={`relative  h-28 rounded-4 px-4 text-sm/7 ${
                                        idx === 0 || idx === 11
                                            ? "bg-white text-grey-100"
                                            : y === cy
                                            ? "bg-doladoBlue-500 text-white cursor-pointer hover:invert-15"
                                            : "bg-white text-grey-500 cursor-pointer hover:invert-15"
                                    } ${
                                        y === ny
                                            ? "before:absolute before:inset-0 before:border before:border-grey-500 before:rounded-4 before:pointer-events-none before:touch-none"
                                            : ""
                                    }`}
                                    onClick={() => setYear(y)}>
                                    {y}
                                </p>
                            );
                        })}
                    </div>
                </>
            );
        };

        const renderMonths = () => {
            if (typeof year === "undefined") return null;

            return (
                <>
                    <div className="flex justify-center mb-16">
                        <p
                            className="cursor-pointer mb-0 rounded-4 px-2 py-1 hover:bg-black/10"
                            onClick={onCancel}>
                            {year}
                        </p>
                    </div>
                    <div className="grid grid-cols-3 gap-16 px-24">
                        {monthNames.map((name, idx) => {
                            return (
                                <p
                                    key={`month-${idx}`}
                                    className={`relative h-28 rounded-4 px-4 text-sm/7 ${
                                        year === cy && idx === cm
                                            ? "bg-doladoBlue-500 text-white cursor-pointer hover:invert-15"
                                            : "bg-white text-grey-500 cursor-pointer hover:invert-15"
                                    } ${
                                        year === ny && idx === nm
                                            ? "before:absolute before:inset-0 before:border before:border-grey-500 before:rounded-4 before:pointer-events-none before:touch-none"
                                            : ""
                                    }`}
                                    onClick={() => handleConfirm(idx)}>
                                    {name}
                                </p>
                            );
                        })}
                    </div>
                </>
            );
        };

        return (
            <div ref={ref} className="bg-white w-max shadow-sm p-8">
                {renderYears()}
                {renderMonths()}
            </div>
        );
    },
);

export default MonthSelector;
