const ENCRYPT_KEY = `${process.env.REACT_APP_ENCRYPT_PUBLIC_KEY || ""}`;

export default async function encrypt(data: string): Promise<string> {
    const publicKeyBuffer = Buffer.from(ENCRYPT_KEY, "base64");
    const publicKey = await window.crypto.subtle.importKey(
        "spki",
        publicKeyBuffer,
        { name: "RSA-OAEP", hash: { name: "SHA-1" } },
        true,
        ["encrypt"],
    );

    const encryptedData = await window.crypto.subtle.encrypt(
        { name: "RSA-OAEP" },
        publicKey,
        Buffer.from(data),
    );
    return Buffer.from(encryptedData).toString("base64");
}
