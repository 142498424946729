import reducers from "./reducers";
import { selectorsSetup } from "./selectors";

import { moduleId } from "./interface";
import type { State } from "./interface";

import { createModule } from "../../../../utils";
import type { ModuleConfigs } from "../../../../utils/interface";

import { DEFAULT_STATUS } from "../../../../utils/helpers";

const initialState: State = {
    accounts: {
        list: [],
        status: DEFAULT_STATUS.PENDING,
    },
    integrations: {
        instanceData: {},
        list: [],
        status: DEFAULT_STATUS.PENDING,
        typeData: {},
    },
    sku: {
        data: null,
        images: [],
        lastUpdate: null,
        relatedKits: [],
        status: DEFAULT_STATUS.PENDING,
    },
    offerInfo: null,
    status: DEFAULT_STATUS.IDLE,
    userErrors: [],
};

export const configs: ModuleConfigs<State, typeof moduleId> = {
    fixed: true,
    id: moduleId,
};

const module = createModule(
    {
        initialState,
        reducers,
        selectors: {},
        extraSelectors: selectorsSetup,
    },
    configs,
);

export default module;
export const { actions, selectors } = module;
