import type { SessionData } from "../contexts/session/interface";

import type { ModuleItem } from "../modules/interface";

export enum LOCAL_STORAGE_NAMES {
    ACCESS_TOKEN = "43a748fdbb9ec4aedd8d3466e1a2be6f2cb2d1d3",
    ACCOUNT_PAYLOAD_INFO = "9e42e593ca14d4e0893a8735a7677a390a8cb50b",
    MODULE_MANAGER = "306aea8667c1114af9f892d91bb5d5eca8f21e99",
    NOTIFICATION = "Notification",
    // PARAMETERS = "f1f1425b65e5770c87be308dd6bfee4b3fa1b113",
    // PRIVILEGES = "7ec0e2c28f6f5858b0cc3432c3b612dee9712992",
    SESSION_DATA = "9a3373fd9582e2897f2923214890f6672a0ddf44",
    TERMS_CONFIRMATION = "9d4572b89b828b585408464de6bc8a955defe7ca",
}

export type LOCAL_STORAGE_DATA = {
    [LOCAL_STORAGE_NAMES.ACCESS_TOKEN]: string;
    [LOCAL_STORAGE_NAMES.ACCOUNT_PAYLOAD_INFO]: {
        data: string;
        created_at: string;
    };
    [LOCAL_STORAGE_NAMES.MODULE_MANAGER]: {
        data: ModuleItem[];
        _version: number;
    };
    [LOCAL_STORAGE_NAMES.SESSION_DATA]: {
        data: Partial<SessionData>;
        updated_at: string;
        _version: number;
    };
    [LOCAL_STORAGE_NAMES.NOTIFICATION]: string;
    [LOCAL_STORAGE_NAMES.TERMS_CONFIRMATION]: boolean;
};
