import type { InputSizes, InputVariants } from "../utils";

export const selectVariants: Record<InputVariants, string> = {
    primary:
        "bg-white border border-grey-100 rounded-4 focus:border-doladoBlue-300",
    unstyled: "",
};

export const selectSizes: Record<InputSizes, string> = {
    lg: "h-48 pl-24 pr-32 bg-pos-x-[right_14px] bg-size-w-14",
    md: "h-40 pl-16 pr-28 bg-pos-x-[right_12px] bg-size-w-12",
    sm: "h-32 pl-8 pr-24 bg-pos-x-[right_10px] bg-size-w-10",
    unset: "",
};

export const selectCommonStyle =
    "appearance-none bg-no-repeat bg-size bg-pos bg-pos-y-[top_50%] w-full focus:outline focus:outline-0 disabled:bg-grey-50 disabled:border-grey-100 disabled:outline-0 disabled:text-grey-400";

export const selectInvalidStyle =
    "border-red disabled:border-red focus:border-red";

export const selectReadOnlyStyle =
    "bg-grey-50 border-grey-100 outline-0 pointer-events-none touch-none";
