export type AlertVariants =
    | "success"
    | "warning"
    | "error"
    | "info"
    | "unstyled";

export const alertVariants: Record<AlertVariants, string> = {
    error: "bg-red-50 text-red-400",
    info: "bg-gray-100 text-gray-500",
    success: "bg-green-50 text-green-600",
    warning: "bg-yellow-50 text-yellow-600",
    unstyled: "",
};

export const alertIconVariants: Record<AlertVariants, string> = {
    error: "bg-red-200",
    info: "bg-gray-300",
    success: "bg-green-300 p-4",
    warning: "bg-yellow-300",
    unstyled: "",
};

export const alertCommonStyle = "flex gap-16 w-full rounded-4 px-24 py-16";
export const alertIconCommonStyle = "min-w-24 rounded-full";
export const alertContentCommonStyle = "flex-0";
