import type { ChangeEventHandler, FC } from "react";

import { Fragment, startTransition, useContext, useState } from "react";

import { parseMenuItemAction, groupFilter, itemFilter } from "../helpers";

import Input from "../../../components/@basic/Forms/Input";
import InputGroup, {
    InputElement,
} from "../../../components/@basic/Forms/Input/Group";

import Icon from "../../../components/@basic/Icons";
import type {
    IconNames,
    IconVariants,
} from "../../../components/@basic/Icons/interface";
import {
    LocationIcon,
    SearchNormalIcon,
} from "../../../components/@basic/Icons/Mocks";

import OnboardingResume from "../../../components/Onboarding/Resume";

import { useMenu } from "../../../contexts/menu/utils";
import type { MenuRelationItem } from "../../../contexts/menu/interface";
import { useModalManager } from "../../../contexts/modal-manager-v2";
import { ModalCommonTags } from "../../../contexts/modal-manager-v2/relations";

import { useAppModuleManager } from "../../../modules/utils";
import { moduleRelation } from "../../../modules/relations";
import type { ModuleNames } from "../../../modules/relations";

import { classes } from "../../../utils/styles/tailwind/v3";

import { MainContext } from "../../../../contexts/MainContext";

interface MainPageSideBarProps {
    className?: string;
    onClick?: () => void;
}

const MainPageSideBar: FC<MainPageSideBarProps> = ({
    className = "",
    onClick,
}) => {
    const [search, setSearch] = useState("");

    const { push } = useModalManager({});
    const { addModules } = useAppModuleManager();

    const { OnChangeTabActive } = useContext(MainContext);
    const { groups, loading } = useMenu();

    const openPickUpAddressModal = () =>
        push(ModalCommonTags.PICKUP_ADDRESS_INFO);

    const isExternal = (link: string): boolean => link.startsWith("https://");

    const openExternalLink = (link: string) => window.open(link, "_blank");

    const handleClickMenu = ({ label, link }: MenuRelationItem) => {
        const isExternalLink = isExternal(link);
        if (isExternalLink) {
            openExternalLink(link);
            return;
        }

        const component = link.replace(/^\/(app)?\/?/, "");
        const URLSearchParams = `/app/#/${component}?component=${component}&title=${label}`;

        const title = moduleRelation[component as ModuleNames].title || label;

        addModules([
            {
                tag: component as ModuleNames,
                oldKey: label,
                search: URLSearchParams,
                title,
                order: 0,
            },
        ]);
    };

    const handleChangeSearch: ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        startTransition(() => {
            setSearch(event.target.value);
        });
    };

    const renderMenu = () => {
        if (loading) {
            return new Array(3)
                .fill(null)
                .map((_, idx) => (
                    <div
                        key={idx}
                        className="bg-grey-100 w-full min-h-24 mt-16 rounded-8 animation-count-1 animation-1-fade-in animation-1-duration-1000 animation-1-timing-ease-out animation-1-iter-infinite animation-1-dir-alt"
                    />
                ));
        }
        return groups
            .filter(groupFilter(search))
            .map(({ key, label, children }) => (
                <Fragment key={key}>
                    <h4 className="mb-16 text-[15px] mt-20 text-doladoBlue uppercase font-medium font-sans">
                        {label}
                    </h4>
                    <ul className="flex flex-col gap-16 mb-0 pl-0">
                        {children.filter(itemFilter(search)).map((item) => (
                            <li
                                key={item.key}
                                className="flex items-center cursor-pointer text-grey hover:text-doladoBlue transition-colors"
                                onClick={parseMenuItemAction(item, {
                                    append: handleClickMenu,
                                    switch: OnChangeTabActive,
                                    callback: onClick,
                                })}>
                                <Icon
                                    icon={
                                        (item.icon[0] as IconNames) ||
                                        "component"
                                    }
                                    iconFallback="component"
                                    variant={item.icon[1] as IconVariants}
                                    title={item.label}
                                    className="w-20 min-w-20 h-20"
                                />
                                <span className="ml-16 text-sm font-sans">
                                    {item.label}
                                </span>
                            </li>
                        ))}
                    </ul>
                </Fragment>
            ));
    };

    return (
        <div className={classes`flex flex-col bg-white h-full ${className}`}>
            <div className="px-32 pt-24">
                <InputGroup size="sm">
                    <InputElement>
                        <SearchNormalIcon className="w-16 h-16 ml-4 text-black/20" />
                    </InputElement>
                    <Input
                        className="bg-black/5"
                        type="text"
                        onChange={handleChangeSearch}
                    />
                </InputGroup>
            </div>

            <hr className="mx-32 my-20 border-gray-200" />

            <div className="relative flex-[9999] mx-32">
                <nav className="absolute inset-0 -right-18 pb-40 text-grey overflow-y-auto scrollbar-floating">
                    <OnboardingResume />
                    {renderMenu()}
                </nav>
            </div>

            <div
                className="cursor-pointer border-t border-t-gray-300 px-24 py-16 text-gray-700"
                onClick={openPickUpAddressModal}>
                <div className="flex gap-8">
                    <div className="relative after:absolute after:-top-1 after:-right-1 after:bg-red-400 after:w-8 after:h-8 after:rounded-full">
                        <LocationIcon
                            title="Localização"
                            className="w-20 h-20"
                        />
                    </div>
                    <h4 className="m-0 text-sm font-medium">
                        Ver endereço de coleta
                    </h4>
                </div>
                <p className="mt-16 mb-0 text-xs">
                    Adicione esse endereço como{" "}
                    <span className="font-medium">ponto de coleta</span> em seus
                    canais de venda.
                </p>
            </div>
        </div>
    );
};

export default MainPageSideBar;
