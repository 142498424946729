import type { ChangeEventHandler, FC } from "react";

import { useRef, useState } from "react";

import { SignUpFormNames, SignUpSteps } from "../../interface";
import type { SignUpFormData } from "../../interface";

import FormControl, {
    FormLabel,
    FormErrorMessage,
} from "../../../../components/@basic/Forms/FormControl";
import Input from "../../../../components/@basic/Forms/Input";
import Select from "../../../../components/@basic/Forms/Select";

import searchZip from "../../../../services/viacep";

// import { provinces } from "../../../../utils/data/provinces";

import type { ErrorRelation } from "../../../../utils/forms/validation";

import { chainEventHandlers } from "../../../../utils/inputs/events";
import { zipMask, zipMaskHandler } from "../../../../utils/inputs/masks/zip";
import { zipPattern } from "../../../../utils/inputs/patterns/zip";

interface BillingAddressFormProps {
    data?: SignUpFormData[SignUpSteps.PAYMENT];
    errors: ErrorRelation<SignUpFormData[SignUpSteps.PAYMENT]>;
    isDisabled?: boolean;
}

const BillingAddressForm: FC<BillingAddressFormProps> = ({
    data,
    errors,
    isDisabled,
}) => {
    const [disabledFields, setDisabledFields] = useState<SignUpFormNames[]>([]);
    const [fetchingZip, setFetchingZip] = useState(false);
    const zipRef = useRef("");

    const handleChange: ChangeEventHandler<HTMLInputElement> = async (
        event,
    ) => {
        const form = event.target.form as HTMLFormElement;

        const zip = event.target.value.replace("-", "") as string;
        if (zip.length === 8 && zip !== zipRef.current) {
            setFetchingZip(true);

            const result = await searchZip(zip);

            const fields: [SignUpFormNames, string | null | undefined][] = [
                [SignUpFormNames.ADDRESS, result.data?.logradouro],
                [SignUpFormNames.CITY, result.data?.localidade],
                [SignUpFormNames.NEIGHBORHOOD, result.data?.bairro],
                // [SignUpFormNames.PROVINCE, result.data?.uf],
            ];

            const fieldsToDisable = [];

            for (const field of fields) {
                const element = form.elements.namedItem(field[0]) as
                    | HTMLInputElement
                    | HTMLSelectElement
                    | null;
                if (element) {
                    element.value = field[1] || "";
                }
                if (field[1]) fieldsToDisable.push(field[0]);
            }

            setDisabledFields(fieldsToDisable);
            setFetchingZip(false);
        }
        zipRef.current = zip;
    };

    const {
        address,
        addressNumber,
        city,
        complement,
        neighborhood,
        // province,
        zip,
    } = data || {};

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 md:gap-x-32 gap-y-16 md:gap-y-24 mt-24 md:mt-32">
            <FormControl
                isRequired
                isDisabled={isDisabled || fetchingZip}
                isInvalid={!!errors.zip}>
                <FormLabel>CEP</FormLabel>
                <Input
                    type="text"
                    name={SignUpFormNames.ZIP}
                    data-label="CEP"
                    data-parser="onlyNumbers"
                    pattern={zipPattern}
                    onChange={chainEventHandlers(zipMaskHandler, handleChange)}
                    defaultValue={zipMask(zip || "")}
                />
                <FormErrorMessage>{errors.zip?.[0]}</FormErrorMessage>
            </FormControl>

            <div className="max-md:hidden" />

            <FormControl
                isRequired
                isDisabled={
                    isDisabled ||
                    fetchingZip ||
                    disabledFields.includes(SignUpFormNames.ADDRESS)
                }
                isInvalid={!!errors.address}>
                <FormLabel>Endereço</FormLabel>
                <Input
                    type="text"
                    name={SignUpFormNames.ADDRESS}
                    data-label="Endereço"
                    data-persist
                    defaultValue={address}
                />
                <FormErrorMessage>{errors.address?.[0]}</FormErrorMessage>
            </FormControl>

            <FormControl
                isRequired
                isDisabled={
                    isDisabled ||
                    fetchingZip ||
                    disabledFields.includes(SignUpFormNames.CITY)
                }
                isInvalid={!!errors.city}>
                <FormLabel>Cidade</FormLabel>
                <Input
                    type="text"
                    name={SignUpFormNames.CITY}
                    data-label="Cidade"
                    data-persist
                    defaultValue={city}
                />
                <FormErrorMessage>{errors.city?.[0]}</FormErrorMessage>
            </FormControl>

            <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row gap-16">
                <FormControl
                    isRequired
                    isDisabled={isDisabled || fetchingZip}
                    isInvalid={!!errors.addressNumber}>
                    <FormLabel>Número</FormLabel>
                    <Input
                        type="number"
                        name={SignUpFormNames.ADDRESS_NUMBER}
                        data-label="Número do endereço"
                        defaultValue={addressNumber}
                    />
                    <FormErrorMessage>
                        {errors.addressNumber?.[0]}
                    </FormErrorMessage>
                </FormControl>
                <FormControl
                    isDisabled={isDisabled || fetchingZip}
                    isInvalid={!!errors.complement}>
                    <FormLabel>Complemento</FormLabel>
                    <Input
                        type="text"
                        name={SignUpFormNames.COMPLEMENT}
                        data-label="Complemento"
                        defaultValue={complement}
                    />
                    <FormErrorMessage>
                        {errors.complement?.[0]}
                    </FormErrorMessage>
                </FormControl>
            </div>

            <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row gap-16">
                <FormControl
                    isRequired
                    isDisabled={
                        isDisabled ||
                        fetchingZip ||
                        disabledFields.includes(SignUpFormNames.NEIGHBORHOOD)
                    }
                    isInvalid={!!errors.neighborhood}>
                    <FormLabel>Bairro</FormLabel>
                    <Input
                        type="text"
                        name={SignUpFormNames.NEIGHBORHOOD}
                        data-label="Bairro"
                        data-persist
                        defaultValue={neighborhood}
                    />
                    <FormErrorMessage>
                        {errors.neighborhood?.[0]}
                    </FormErrorMessage>
                </FormControl>
                {/* <FormControl
                    isRequired
                    isDisabled={
                        isDisabled ||
                        disabledFields.includes(SignUpFormNames.PROVINCE)
                    }
                    isInvalid={!!errors.province}>
                    <FormLabel>Estado</FormLabel>
                    <Select
                        name={SignUpFormNames.PROVINCE}
                        data-persist
                        defaultValue={
                            provinces.find(({ code }) => code === province)?.id
                        }>
                        {provinces.map(({ id, code, name }) => (
                            <option key={id} value={code}>
                                {name}
                            </option>
                        ))}
                    </Select>
                    <FormErrorMessage>{errors.province?.[0]}</FormErrorMessage>
                </FormControl> */}
            </div>
        </div>
    );
};

export default BillingAddressForm;
